<template>
    <div class="url-property">
        <label for="url">{{ $t('form_builder.url') }}</label>
        <input type="url" v-model="urlValue" />
    </div>
</template>

<script>
export default {
    name: 'UrlProperty',
    props: ["property", "value"],
    data() {
        return {
            urlValue: this.value
        }
    },
    watch: {
        urlValue(newValue) {
            this.$emit('updateContent', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
