<template>
    <div class="extensions-property">
        <label>{{ $t('form_builder.phone_extensions') }}</label>
        <div class="extensions-holder">
            <div v-for="(item, index) in phoneExtensions" :key="index" class="extensions-holder__column">
                <span v-for="extension in item" :key="extension" class="extensions-holder__extension">
                    <input type="checkbox" :value="extension" v-model="extensionsValue" /> {{ extension }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExtensionsProperty',
    emits: ['updateProperty'],
    props: ["property", "value"],
    data() {
        return {
            extensionsValue: JSON.parse(JSON.stringify(this.value)),
            phoneExtensions: [
                [
                    "050",
                    "051",
                    "052",
                    "053",
                    "054",
                    "055",
                    "056",
                    "058",
                    "059"
                ],
                [
                    "02",
                    "03",
                    "04",
                    "05",
                    "07",
                    "08",
                    "09"
                ]
            ]
        }
    },
    computed: {
        extensionsValueStr() {
            return JSON.stringify(this.extensionsValue);
        }
    },
    watch: {
        extensionsValueStr(newValue) {
            this.$emit('updateProperty', this.property, JSON.parse(newValue));
        }
    },
};
</script>

<style lang="scss" scoped>
.extensions-holder {
    display: flex;
    flex-flow: row;
    gap: 50px;

    &__column {
        display: flex;
        flex-flow: column;
        gap: 2px;
    }
}
</style>
