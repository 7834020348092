import widgetsUtility from '@/widgetsUtility'

const CheckboxWidget = {
    name: "CheckboxWidget",
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Checkbox')
};

const CheckboxWidgetPdf = {
    name: "CheckboxWidgetPdf",
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Checkbox')
};

export { CheckboxWidget, CheckboxWidgetPdf };
