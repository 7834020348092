<template>
    <div class="font-weight-property">
        <label for="font_weight">{{ $t('form_builder.font_weight') }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <select name="font_weight" id="font_weight" v-model="weightValue" :style="{'font-weight': weightValue }">
            <option value="">{{ $t('form_builder.default') }}</option>
            <option value="normal" style="font-weight: normal;">{{ $t('form_builder.font_weight_normal') }}</option>
            <option value="bold" style="font-weight: bold;">{{ $t('form_builder.font_weight_bold') }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'FontWeightProperty',
    props: ["property", "value"],
    data: function() {
        return {
            weightValue: this.value
        }
    },
    mounted() {
        //console.log(this.value)
    },
    watch: {
        weightValue(newValue) {
            this.$emit('updateStyle', this.property, newValue);
        }
    }
}
</script>

<style lang="scss" scoped>
select {
    text-transform: capitalize;

    option {
        text-transform: capitalize;
    }
}
</style>
