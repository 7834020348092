<template>
    <div class="editor-sidebar">
        <TabsHeader v-if="formStore.activeSidebarView == 'WIDGETS_VIEW' ||
                formStore.activeSidebarView == 'EDITOR_VIEW'" />

        <WidgetsView v-if="formStore.activeSidebarView == 'WIDGETS_VIEW'"
                @addWidget="addWidget" />

        <StyleView v-if="formStore.activeSidebarView == 'STYLE_VIEW'" />

        <SettingsView v-if="formStore.activeSidebarView == 'SETTINGS_VIEW'" />

        <WidgetEditor v-if="formStore.activeSidebarView == 'EDITOR_VIEW'" />
    </div>
</template>

<script>
import TabsHeader from "@/components/form-builder/sidebar/TabsHeader.vue";
import WidgetsView from "@/components/form-builder/sidebar/WidgetsView.vue";
import WidgetEditor from "@/components/form-builder/sidebar/WidgetEditor.vue";
import SettingsView from "@/components/form-builder/sidebar/SettingsView.vue";
import StyleView from "@/components/form-builder/sidebar/StyleView.vue";

import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'TheSidebar',
    emits: ['addWidget'],
    components: {
        TabsHeader,
        WidgetsView,
        SettingsView,
        StyleView,
        WidgetEditor
    },
    computed: {
        ...mapStores(useFormStore)
    },
    methods: {
        addWidget(widget) {
            this.$emit('addWidget', widget)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
