<template>
    <div class="reset-password-component auth-component">
        <div class="auth-container">
            <div class="auth-container__logo">
                <figure><img :src="require('@/assets/images/site-logo.png')" /></figure>
            </div>

            <div class="auth-container__title">
                <h3>{{ $t("auth.forgot_password_title") }}</h3>
            </div>

            <div class="auth-container__content">

                <p v-if="emailSent" style="font-size: 18px;">
                    {{ $t("auth.forgot_password_submitted_text") }}
                </p>

                <form v-else v-on:submit="resetPassword">
                    <div class="form-group">
                        <label>{{ $t("auth.email_address") }}</label>
                        <i class="input-icon email-icon"></i>
                        <input
                        type="email"
                        name="email"
                        v-model="email"
                        class="form-control"
                        required="required"
                        />
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary form-control" :disabled="this.formSubmitted"
                            :class="this.formSubmitted && 'disabled'">
                            {{ $t("auth.reset_password_button") }}
                        </button>
                    </div>
                </form>

                <div class="auth-bottom-links">
                    <router-link to="/auth/login" class="auth-navigation-link">{{
                    $t("auth.login_link_from_forgot_password")
                    }}</router-link>
                </div>
            </div>
        </div>

        <OverlayAlert v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="this.modalOpen = false">
        </OverlayAlert>

    </div>
</template>

<script>
const authEndpoint = process.env.VUE_APP_API_BASE + '/auth';

import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

import OverlayAlert from '@/components/global/OverlayAlert';

export default {
    name: 'AuthPassword',
    components: {
        OverlayAlert
    },
    data: function() {
        return {
            modalOpen: false,
            alertMsg:'',
            email: '',
            formSubmitted: false,
            emailSent: false
        }
    },
    computed: {
        ...mapStores(useAuthStore)
    },
    methods: {
        async resetPassword(e) {
            e.preventDefault()
            this.formSubmitted = true
            const result = await this.authStore.resetPassword(this.email)

            if (result === true) {
                this.emailSent = true
            }
            else if (result === false) {
                this.alertMsg = this.$t('auth.email_not_registered')
                this.modalOpen = true

                this.formSubmitted = false
                this.emailSent = false
            }
            else if (result === 0) {
                this.alertMsg = this.$t('auth.error_occurred')
                this.modalOpen = true

                this.formSubmitted = false
                this.emailSent = false
            }
        }
    },
    unmounted() {
        this.emailSent = false
    }
}
</script>

<style lang="scss">
button:disabled {
    cursor: not-allowed;
}
</style>
