import widgetsUtility from '@/widgetsUtility'

const PdfUploadWidget = {
    name: 'PdfUploadWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('PdfUpload')
};

const PdfUploadWidgetPdf = {
    name: 'PdfUploadWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('PdfUpload')
};

export { PdfUploadWidget, PdfUploadWidgetPdf };
