<template>
    <div class="multiple-property">
        <input type="checkbox" name="multiple" v-model="multipleValue" />&nbsp;
        <label for="multiple">{{ $t('form_builder.allow_multiple') }}</label>
    </div>
</template>

<script>
export default {
    name: 'MultipleProperty',
    props: ["property", "value"],
    data() {
        return {
            multipleValue: this.value
        }
    },
    watch: {
        multipleValue(newValue) {
            this.$emit('updateProperty', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
