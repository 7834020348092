<template>
    <div class="search-box">
        <div class="search-box__input-holder">
            <input type="text"
                    :placeholder="placeholder"
                    @input="handleSearch($event)">

            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_950_258)">
                <path d="M14.8167 14.4336L11.0861 10.703C12.1028 9.4596 12.6026 7.87303 12.4823 6.27145C12.3619 4.66987 11.6306 3.17581 10.4396 2.0983C9.24859 1.02079 7.68898 0.442282 6.08339 0.482427C4.4778 0.522572 2.94905 1.1783 1.81337 2.31398C0.677691 3.44966 0.0219612 4.97841 -0.0181837 6.584C-0.0583286 8.18959 0.520183 9.7492 1.59769 10.9402C2.6752 12.1312 4.16926 12.8625 5.77084 12.9829C7.37242 13.1032 8.95899 12.6034 10.2024 11.5867L13.933 15.3174C14.0509 15.4312 14.2087 15.4942 14.3726 15.4928C14.5365 15.4913 14.6932 15.4256 14.8091 15.3097C14.925 15.1939 14.9907 15.0371 14.9922 14.8732C14.9936 14.7094 14.9306 14.5515 14.8167 14.4336ZM6.24986 11.7505C5.26096 11.7505 4.29426 11.4572 3.47201 10.9078C2.64977 10.3584 2.0089 9.57752 1.63047 8.66389C1.25203 7.75026 1.15301 6.74493 1.34594 5.77502C1.53886 4.80512 2.01507 3.9142 2.71433 3.21494C3.41359 2.51568 4.30451 2.03947 5.27441 1.84655C6.24432 1.65362 7.24965 1.75264 8.16328 2.13108C9.07691 2.50951 9.85781 3.15038 10.4072 3.97262C10.9566 4.79487 11.2499 5.76157 11.2499 6.75047C11.2484 8.0761 10.7211 9.347 9.78375 10.2844C8.84639 11.2217 7.57549 11.749 6.24986 11.7505Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_950_258">
                <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: ['placeholder'],
    emits: ['search'],
    data() {
        return {

        }
    },
    methods: {
        handleSearch(e) {
            const value = e.target.value
            this.valueChangedAt = Date.now() //timestamp in milliseconds
            setTimeout(() => {
                let currTime = Date.now()
                if (currTime - this.valueChangedAt < 400) {
                    return
                }
                this.$emit('search', value)
            }, 400)
        }
    }
}
</script>

<style lang="scss">
.search-box {
    &__input-holder {
        position: relative;

        input[type="text"] {
            width: 266px;
            height: 34px;
            border-width: 1px;
            border-style: solid;
            border-color: $grey-color-fields;
            border-radius: 10px;
            background-color: transparent;
            color: $text-color;
            outline: none;
            padding: 0px 10px 0px 30px;
        }

        svg {
            position: absolute;
            left: 10px;
            top: 10px;
            pointer-events: none;
            opacity: 0.9;
        }
    }
}
</style>
