import widgetsUtility from '@/widgetsUtility'

const UrlWidget = {
    name: 'UrlWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Url')
};

const UrlWidgetPdf = {
    name: 'UrlWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Url')
};

export { UrlWidget, UrlWidgetPdf };
