<template>
    <div class="form-builder-view">
        <NavigationBar></NavigationBar>
        <div class="editor">
            <TheSidenav></TheSidenav>
            <TheSidebar
                    :activeWidget="activeWidget"
                    :activeView="activeView"
                    @updateWidgetProperty="updateWidgetProperty"
                    @updateConfiguration="updateConfiguration"
                    @cancelEditWidget="cancelEditWidget"
                    @cancelEditConfiguration="cancelEditConfiguration"
                    @resetStyle="resetStyle"
                    @addPdfStyleToWidget="addPdfStyleToWidget"
                    @removePdfStyleFromWidget="removePdfStyleFromWidget"
                    @setActiveView="setActiveView"
                    @publishForm="publishForm"
                    @closeForm="closeForm"
                    @addWidget="addWidget"

                    />
            <ThePreview />
        </div>
    </div>
</template>

<script>
const formsEndpoint = process.env.VUE_APP_API_BASE + '/forms';
const uploadsPublicUrl = process.env.VUE_APP_API_BASE + '/images';

import widgetsUtility from '@/widgetsUtility.js';

import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

//Child Component Imports
//import WidgetDropZone from "@/components/form-builder/WidgetDropZone.vue";
import NavigationBar from "@/components/form-builder/NavigationBar";
import TheSidenav from "@/components/form-builder/TheSidenav.vue";
import TheSidebar from "@/components/form-builder/TheSidebar.vue";
import ThePreview from "@/components/form-builder/ThePreview.vue";

export default {
    name: 'FormBuilder',
    components: {
        NavigationBar,
        TheSidenav,
        TheSidebar,
        ThePreview
    },
    data: () => ({
        previewWidth: '880px',
        initialPreviewWidth: 880,
        initialPreviewLeft: 0,
        previewMinWidth: '380',

        activeWidget: null,
        activeView: 'WEB_VIEW', //PDF_VIEW
        downloadPdfUrl: '',
        formData: {}
    }),
    computed: {
        webPreviewStyle() {
            return 'font-family: ' + this.formData.font +'; \
                font-size: ' + this.formData.font_size +'; \
                direction: ' + this.formData.direction + '; \
                width: ' + this.previewWidth + ';'
        },
        ...mapStores(useFormStore)
    },
    async created() {
        //this.loadFormData()
        const formId = this.$route.params.id
        await this.formStore.loadForm(formId)
        if (this.formStore.formData.status === 'PUBLISHED') {
            alert('Form is already published. Redirecting...')
            this.$router.push({
                name: 'FormsShare',
                params: this.$route.params
            })
        }
        this.downloadPdfUrl = formsEndpoint + '/' + formId + '/generateDemoPdf'
    },
    mounted() {
        document.addEventListener('keyup', this.cancelEditIfEsc)
        this.setInitialPreviewWidthParams()
        window.addEventListener("resize", this.setInitialPreviewWidthParams)
    },
    destroyed() {
        document.removeEventListener('keyup', this.cancelEditIfEsc)
        document.removeEventListener('resize', this.setInitialPreviewWidthParams)
    },
    methods: {
        /*
        loadFormData() {
            this.axios.get(formsEndpoint + '/' + this.$route.params.id)
                .then(response => {
                    this.formData = response.data
                    if (this.formData.status === 'PUBLISHED') {
                        alert('Form is already published. Redirecting...')
                        this.$router.push({
                            name: 'FormsShare',
                            params: this.$route.params
                        })
                    }
                    //console.log(this.formData.form_schema)
                }).catch(error => {
                    console.log(error)
                })

            this.downloadPdfUrl = formsEndpoint + '/' + this.$route.params.id + '/generateDemoPdf'
        },*/

        //ACTIONS (Add/Move/Edit/Remove/Clone/Cancel Edit /SaveForm)
        addWidget(widgetName, index = null) {
            const newWidget = widgetsUtility.getNewWidget(widgetName);

            if (newWidget.properties.hasOwnProperty('content')) {
                this.formatWidgetContent(newWidget.properties.content, widgetName)
            }
            if (newWidget.properties.hasOwnProperty('style')) {
                this.formatWidgetStyle(newWidget.properties.style)
            }

            if (index == null) {
                index = this.formData.form_schema.length;
            }
            this.formData.form_schema.splice(index, 0, newWidget);
            this.editWidget(index);

            if (widgetName == 'Pagebreak') {
                this.activeView = 'PDF_VIEW'
            }

        },
        moveWidget(fromIndex, toIndex = null) {

            if (toIndex == null) {
                toIndex = this.formData.form_schema.length;
            }

            if (fromIndex == toIndex) {
                return;
            }

            const widgetData = JSON.parse(JSON.stringify(this.formData.form_schema[fromIndex]));
            this.removeWidget(fromIndex);

            if (fromIndex < toIndex) {
                this.formData.form_schema.splice(toIndex-1, 0, widgetData);
            }
            else if (fromIndex > toIndex) {
                this.formData.form_schema.splice(toIndex, 0, widgetData);
            }
        },
        editWidget(index) {
            if (index >= this.formData.form_schema.length) {
                console.log('Invalid index at edit widget: '+index);
                return;
            }
            this.activeWidget = this.formData.form_schema[index];

            if (this.activeWidget.properties && !this.activeWidget.properties.settings)  {
                this.activeWidget.properties['settings'] = {}
            }
        },
        removeWidget(index) {
            this.formData.form_schema.splice(index, 1);
            this.cancelEditWidget();
        },
        cloneWidget(index) {
            const widgetData = JSON.parse(JSON.stringify(this.formData.form_schema[index]));
            this.formData.form_schema.splice(index+1, 0, widgetData);
            //@TODO replace to ID generator
            this.formData.form_schema[index+1]['id'] = Math.floor(Math.random() * 10000);
            this.editWidget(index+1);
        },
        cancelEditWidget() {
            //@TODO
            //Check if formData obj changed to avoid un-necessary requests
            if (this.activeWidget != null) {
                this.activeWidget = null
                this.saveForm()
            }
        },
        cancelEditConfiguration() {
            //console.log(this.formData)
            this.saveForm()
        },

        //Save form
        /*
        saveForm() {
            //console.log(this.formData)
            this.axios.put(formsEndpoint + '/' + this.$route.params.id, this.formData)
                .then(response => {
                    console.log('Saved successfully');
                }).catch(error => {
                    //console.log('Error saving form');
                    //console.log(error)
                    if (error.response?.status == 402) {
                        if (error.response?.data?.message) {
                            alert(this.$t('form_builder.' + error.response.data.message))
                            this.loadFormData()
                        }
                    }
                })
        },
        */

        //drag widget event methods
        widgetDragStart(e, index) {
            var draggedWidget = this.formData.form_schema[index];
            this.activeWidget = draggedWidget;
            e.dataTransfer.setData('widget_position', index);
        },
        widgetDragOver(e, index) {
            if (this.activeWidget && this.activeWidget == this.formData.form_schema[index]) {
                //dragging over same widget
                return;
            }
            var myElement = e.target;
            //console.log(myElement);
            myElement = this.findWidgetComponentInNodeTree(myElement);
            if (myElement !== null) {
                if (this.isEventOnTopHalfOfElement(e, myElement)) {
                    myElement.classList.remove('drop-zone-bottom');
                    myElement.classList.add('drop-zone-top');
                }
                else {
                    myElement.classList.remove('drop-zone-top');
                    myElement.classList.add('drop-zone-bottom');
                }
            }
            else {
                console.log('Error #11002')
            }
        },
        widgetDragLeave(e, index) {
            var myElement = e.target;
            myElement = this.findWidgetComponentInNodeTree(myElement);
            if (myElement !== null) {
                myElement.classList.remove('drop-zone-top');
                myElement.classList.remove('drop-zone-bottom');
                //console.log('left?');
            }
            else {
                //console.log('whops');
            }
        },
        widgetDropOn(e, index) {

            const widgetPosition = e.dataTransfer.getData('widget_position')
            const widget = e.dataTransfer.getData('widget');

            var myElement = e.target;
            myElement = this.findWidgetComponentInNodeTree(myElement);
            if (myElement !== null) {
                if (!this.isEventOnTopHalfOfElement(e, myElement)) {
                    index++;
                }

                if (widgetPosition) { //movewidget
                    this.moveWidget(widgetPosition, index);
                }
                else {
                    this.addWidget(widget, index);
                }
            }

            myElement.classList.remove('drop-zone-top');
            myElement.classList.remove('drop-zone-bottom');
        },

        //Preview RESIZE events
        /*
        resizeHandle (e) {
            //const previewLeftPosition =
            const diffLeft = e.pageX - this.initialPreviewLeft
            const newWidth = this.initialPreviewWidth - diffLeft*2

            if (newWidth >= this.previewMinWidth) {
                this.previewWidth = newWidth
            }
        },
        resizeDragStart () {
            document.addEventListener('mousemove', this.resizeHandle)
        },
        resizeDragEnd () {
            document.removeEventListener('mousemove', this.resizeHandle)
            //this.setInitialPreviewWidthParams()
        },
        */

        //helper methods
        findWidgetComponentInNodeTree(el) {
            while (el) {
                if (el.classList.contains('widget-component') ||
                        el.classList.contains('pdf-widget-component')) {
                    return el;
                }
                el = el.parentElement;
            }
            return null
        },
        isEventOnTopHalfOfElement(e, el) {
            const rect = el.getBoundingClientRect();
            //const relativeX = e.clientX - rect.left;
            const relativeY = e.clientY - rect.top;
            const midPoint = rect.height / 2;

            if (relativeY < midPoint) {
                return true;
            }
            return false;
        },
        setInitialPreviewWidthParams() {
            const previewEl = document.getElementById('preview-container');
            const resizeEl = document.getElementById('resizer');

            if (previewEl && resizeEl) {
                this.initialPreviewWidth = previewEl.clientWidth
                this.initialPreviewLeft = previewEl.offsetLeft - resizeEl.clientWidth
            }
        },
        cancelEditIfEsc(keyupEvent) {
            if (keyupEvent.keyCode === 27) this.cancelEditWidget();
        },

        //formatting methods
        /*
        formatWidgetFields(fields) {
            for (var field of fields) {
                field.label = this.$t('form_builder.' + field.reference_key);

                if (field.hasOwnProperty('options')) {
                    const translatedOptions = this.$t('form_builder.' + field.reference_key + '_options');
                    const optionsArray = translatedOptions.split('^');
                    field.options = optionsArray;
                }
            }
        },
        formatWidgetContent(contentObj, widgetName) {

            if (contentObj.hasOwnProperty('fields')) {
                this.formatWidgetFields(contentObj.fields)
            }

            if (contentObj.hasOwnProperty('label')) {
                contentObj.label = this.$t('form_builder.' + widgetName);
            }
            else if (contentObj.hasOwnProperty('text')) {
                contentObj.text = this.$t('form_builder.' + widgetName);
            }
            else if (contentObj.hasOwnProperty('html')) {
                contentObj.html = '<p>' + this.$t('form_builder.' + widgetName) + '</p>';
            }

            if (contentObj.hasOwnProperty('options') && contentObj.options.length === 0) {
                contentObj.options.push(this.$t('form_builder.option_default_text') + ' 1');
                contentObj.options.push(this.$t('form_builder.option_default_text') + ' 2');
            }

            if (contentObj.hasOwnProperty('image_source')) {
                contentObj.image_source = uploadsPublicUrl + '/placeholder.jpg';
            }

        },
        formatWidgetStyle(styleObj) {

        },
        */

        //child component event handlers
        updateWidgetProperty(property, value) {
            this.activeWidget['properties'][property] = value
        },
        updateConfiguration(property, value) {
            this.formData[property] = value
        },
        resetStyle() {
            if (this.activeWidget == null) {
                return;
            }
            const widgetName = this.activeWidget.widget
            const newWidget = widgetsUtility.getNewWidget(widgetName)
            this.formatWidgetStyle(newWidget.properties.style)
            this.activeWidget.properties.style = JSON.parse(JSON.stringify(newWidget.properties.style))
            delete this.activeWidget.properties.pdf_style
        },
        setActiveView(viewName) {
            this.activeView = viewName
        },
        addPdfStyleToWidget(widget) {
            widget.properties['pdf_style'] = JSON.parse(JSON.stringify(widget.properties.style))
            //console.log(widget)
            //console.log(this.formData)
        },
        removePdfStyleFromWidget(widget) {
            delete widget.properties.pdf_style
        },

        publishForm() {
            if (confirm(this.$t('form_builder.publish_form_confirmation_message'))) {
                this.axios.post(formsEndpoint + '/' + this.$route.params.id + '/publish')
                    .then(response => {
                        if (response.data.status === 'PUBLISHED') {
                            this.$router.push({
                                name: 'FormsShare',
                                params: this.$route.params
                            })
                        }
                        else {
                            alert('Error occurred - could not publish form');
                            console.log('Error #11003')
                        }
                    }).catch(error => {
                        console.log(error)
                    })
            }
        },
        closeForm() {
            this.saveForm()
            this.$router.push({ name: 'FormsIndex' })
        }

    }
}
</script>

<style>
</style>
