<template>
    <nav>
        <div class="container">
            <div class="row">
                <div class="col-12 inner-menu">
                    <ul class="nav-items">
                        <li><router-link to="/admin/users">{{ $t('admin.navigation_users') }}</router-link></li>
                    </ul>
                    <div class="profile-section">
                        <a class="dropdown-item" href="javascript:;" v-on:click="logoutUser">{{ $t('profile.profile_dropdown_logout') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'NavigationMenu',
    computed: {
        ...mapStores(useAuthStore)
    },
    methods: {
        logoutUser() {
            this.authStore.logout()
        }
    }
}
</script>

<style lang="scss" scoped>
nav {
    background: $brand-color-darker;
    width: 100%;
    position:relative;
    height: 60px;

    ul.nav-items {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: auto;
        line-height: 60px;
        display: inline-block;

        li {
            display: inline-block;
            margin: 0;
            padding: 0;

            a {
                text-decoration: none;
                color: #fff;
            }

            & + li {
                margin-right: 10px;
            }
        }
    }

    .profile-section {
        float: left;
        padding: 20px;
        color: #fff;
    }
}
</style>
