<template>
    <div>
        <div class="share-block-heading">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_738_10637)">
                <path d="M7.00175 0H6.99825C3.13863 0 0 3.1395 0 7C0 8.53125 0.4935 9.9505 1.33263 11.1029L0.46025 13.7034L3.15088 12.8433C4.25775 13.5765 5.57812 14 7.00175 14C10.8614 14 14 10.8596 14 7C14 3.14038 10.8614 0 7.00175 0Z" fill="#4CAF50"/>
                <path d="M11.075 9.88491C10.9061 10.3618 10.2359 10.7573 9.70123 10.8728C9.33548 10.9507 8.85773 11.0128 7.24948 10.346C5.19235 9.49379 3.86761 7.40341 3.76436 7.26779C3.66548 7.13216 2.93311 6.16091 2.93311 5.15641C2.93311 4.15191 3.44323 3.66279 3.64886 3.45279C3.81773 3.28041 4.09686 3.20166 4.36461 3.20166C4.45123 3.20166 4.52911 3.20604 4.59911 3.20954C4.80473 3.21829 4.90798 3.23054 5.04361 3.55516C5.21248 3.96204 5.62373 4.96654 5.67273 5.06979C5.7226 5.17304 5.77248 5.31304 5.70248 5.44866C5.63686 5.58866 5.57911 5.65079 5.47586 5.76979C5.37261 5.88879 5.27461 5.97979 5.17136 6.10754C5.07686 6.21866 4.97011 6.33766 5.08911 6.54329C5.20811 6.74454 5.61936 7.41566 6.22486 7.95466C7.00623 8.65028 7.63973 8.87254 7.86635 8.96704C8.03523 9.03704 8.23648 9.02041 8.35985 8.88916C8.51648 8.72029 8.70985 8.44029 8.90673 8.16466C9.04673 7.96691 9.22348 7.94241 9.40898 8.01241C9.59798 8.07803 10.5981 8.57241 10.8037 8.67478C11.0094 8.77803 11.145 8.82704 11.1949 8.91366C11.2439 9.00029 11.2439 9.40716 11.075 9.88491Z" fill="#FAFAFA"/>
                </g>
                <defs>
                <clipPath id="clip0_738_10637">
                <rect width="14" height="14" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <h3>{{ $t('forms_share.share_by_whatsapp') }}</h3>
        </div>
        <div class="share-block-content">
            בקרוב.
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShareByWhatsapp',
    props: ['shareUrl'],
    data: function() {
        return {

        }
    },
    computed: {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.share-block-heading {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    gap: 5px;

    h3 {
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: $line-height;
        margin: 0;
        padding: 0;
    }
    margin-bottom: 13px;
}
</style>
