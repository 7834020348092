<template>
    <div class="input-property-container">
        <component v-for="(item, key) in value" :key="key" :is="translateToCamelCase(key)+'Property'"
            :widget="widget" :property="key"
            :value="item" @updateProperty="updateProperty" class="property-component form-group" />
    </div>
</template>

<script>
import LabelProperty from "./input/LabelProperty.vue"
import OptionsProperty from "./input/OptionsProperty.vue"
import ExtensionsProperty from "./input/ExtensionsProperty.vue"
import MultipleProperty from "./input/MultipleProperty.vue";

//import FontWeightProperty from "./style/FontWeightProperty.vue";

export default {
    name: 'InputProperty',
    props: ["property", "value", "widget"],

    emits: ['updateWidgetProperty'],
    components: {
        LabelProperty,
        OptionsProperty,
        ExtensionsProperty,
        MultipleProperty
    },
    data: function() {
        return {

        }
    },
    methods: {
        updateProperty(property, value) {
            this.$emit('updateWidgetProperty', 'input', property, value)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
