<template>
    <div class="options-property">
        <label><strong>{{ $t('form_builder.options') }}</strong></label>

        <!--
        <div class="option-drop-zone"
                :class="this.activeOptionIndex !== null && this.activeOptionIndex !== 0 && 'bordered'"
                @drop.prevent.self ="dropOver($event, 0)"
                @dragover.prevent
                @dragenter.prevent = "dragOver($event, 0)"
                @dragleave.prevent = "dragLeave($event, 0)">
        </div>
        -->

        <div v-for="(item, index) in optionsValue" :key="index" class="option-property__option">

            <div class="option-property__option__row">

                <!--div class="drag-handler">

                    <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    	 width="20px" height="20px" viewBox="0 0 32 32" fill="#222222" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                    <title>draggable</title>
                    <rect x="10" y="9" width="4" height="4"/>
                    <rect x="18" y="9" width="4" height="4"/>
                    <rect x="10" y="18" width="4" height="4"/>
                    <rect x="18" y="18" width="4" height="4"/>
                    <rect x="10" y="27" width="4" height="4"/>
                    <rect x="18" y="27" width="4" height="4"/>
                    <rect id="_Transparent_Rectangle_" class="st0" width="32" height="32" style="fill:none;" />
                    </svg>
                </div-->

                <input type="text" v-model="optionsValue[index]" class="input-large" />
                <a v-on:click="removeOption(index)" href="javascript:;">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_615_6790)">
                            <path d="M20.9999 4H17.8999C17.6678 2.87141 17.0538 1.85735 16.1612 1.12872C15.2686 0.40009 14.1521 0.00145452 12.9999 0L10.9999 0C9.84773 0.00145452 8.73126 0.40009 7.83869 1.12872C6.94612 1.85735 6.33204 2.87141 6.09994 4H2.99994C2.73472 4 2.48037 4.10536 2.29283 4.29289C2.1053 4.48043 1.99994 4.73478 1.99994 5C1.99994 5.26522 2.1053 5.51957 2.29283 5.70711C2.48037 5.89464 2.73472 6 2.99994 6H3.99994V19C4.00153 20.3256 4.52882 21.5964 5.46616 22.5338C6.4035 23.4711 7.67434 23.9984 8.99994 24H14.9999C16.3255 23.9984 17.5964 23.4711 18.5337 22.5338C19.4711 21.5964 19.9984 20.3256 19.9999 19V6H20.9999C21.2652 6 21.5195 5.89464 21.707 5.70711C21.8946 5.51957 21.9999 5.26522 21.9999 5C21.9999 4.73478 21.8946 4.48043 21.707 4.29289C21.5195 4.10536 21.2652 4 20.9999 4ZM10.9999 2H12.9999C13.6202 2.00076 14.2251 2.19338 14.7316 2.55144C15.238 2.90951 15.6214 3.41549 15.8289 4H8.17094C8.37852 3.41549 8.76184 2.90951 9.26833 2.55144C9.77481 2.19338 10.3797 2.00076 10.9999 2ZM17.9999 19C17.9999 19.7956 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7956 22 14.9999 22H8.99994C8.20429 22 7.44123 21.6839 6.87862 21.1213C6.31601 20.5587 5.99994 19.7956 5.99994 19V6H17.9999V19Z" fill="#313131"/>
                            <path d="M10 17.9994C10.2652 17.9994 10.5196 17.894 10.7071 17.7065C10.8946 17.5189 11 17.2646 11 16.9994V10.9994C11 10.7342 10.8946 10.4798 10.7071 10.2923C10.5196 10.1047 10.2652 9.99939 10 9.99939C9.73478 9.99939 9.48043 10.1047 9.29289 10.2923C9.10536 10.4798 9 10.7342 9 10.9994V16.9994C9 17.2646 9.10536 17.5189 9.29289 17.7065C9.48043 17.894 9.73478 17.9994 10 17.9994Z" fill="#313131"/>
                            <path d="M13.9999 17.9994C14.2651 17.9994 14.5195 17.894 14.707 17.7065C14.8945 17.5189 14.9999 17.2646 14.9999 16.9994V10.9994C14.9999 10.7342 14.8945 10.4798 14.707 10.2923C14.5195 10.1047 14.2651 9.99939 13.9999 9.99939C13.7347 9.99939 13.4803 10.1047 13.2928 10.2923C13.1052 10.4798 12.9999 10.7342 12.9999 10.9994V16.9994C12.9999 17.2646 13.1052 17.5189 13.2928 17.7065C13.4803 17.894 13.7347 17.9994 13.9999 17.9994Z" fill="#313131"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_615_6790">
                                <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>

            <!--
            <div class="option-drop-zone"
                    :class="this.activeOptionIndex !== null && this.activeOptionIndex !== index && this.activeOptionIndex !== index+1 && 'bordered'"
                    @drop.prevent.self ="dropOver($event, index+1)"
                    @dragover.prevent
                    @dragenter.prevent = "dragOver($event, index+1)"
                    @dragleave.prevent = "dragLeave($event, index+1)">
            </div>
            -->

        </div>

        <a v-on:click="addOption()" class="add-option-btn" href="javascript:;">{{ $t('form_builder.add_option') }}</a>
        
    </div>
</template>

<script>
export default {
    name: 'OptionsProperty',
    props: ["property", "value"],
    emits: ['updateProperty'],
    data() {
        return {
            optionsValue: JSON.parse(JSON.stringify(this.value)),
            activeOptionIndex: null
        }
    },
    computed: {
        optionsValueStr() {
            return JSON.stringify(this.optionsValue);
        }
    },
    watch: {
        optionsValueStr(newValue) {
            this.$emit('updateProperty', this.property, JSON.parse(newValue));
        }
    },
    methods: {
        addOption() {
            this.optionsValue.push('');
        },
        removeOption(index) {
            this.optionsValue.splice(index, 1);
        },

        //drag sort functionality
        dragStart(e, index) {
            //var draggedWidget = this.formData.form_schema[index];
            this.activeOptionIndex = index;
            //console.log(index);
            //e.dataTransfer.setData('option_index', index);
        },
        dragOver(e, index) {

            if (this.activeOptionIndex !== null &&
                    (this.activeOptionIndex == index ||
                    this.activeOptionIndex == index-1)) {

                console.log('drag over same position - cancelling');
                return;
            }

            let dropZoneEl = e.target;
            dropZoneEl.classList.add('active');
            e.preventDefault()
            //console.log(targetIndex);
        },
        dragLeave(e, index) {
            let myElement = e.target;
            myElement.classList.remove('active');

            e.preventDefault()
        },
        dropOver(e, index) {

            var fromIndex = this.activeOptionIndex;
            var targetIndex = fromIndex < index ? index-1 : index;

            //this.moveOption(this.activeOptionIndex, targetIndex);
            let optionData = JSON.parse(JSON.stringify(this.optionsValue[fromIndex]));

            this.removeOption(fromIndex);
            this.optionsValue.splice(targetIndex, 0, optionData);

            let myElement = e.target;
            myElement.classList.remove('active');
            //this.resetClasses();
        },
        dragEnd(e) {
            this.activeOptionIndex = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.option-property {
    &__option + &__option {
        margin-top: 5px;
    }

    &__option {
        &__row {
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: 8px;
        }
    }
}

.add-option-btn, .add-option-btn:hover, .add-option-btn:active {
    color: $text-color;
    text-decoration: none;
    font-size: $font-size-small;
    display: inline-block;
    margin-top: 8px;

    &::before {
        vertical-align: middle;
        display: inline-block;
        content: '';
        width: 13px;
        height: 13px;
        padding: 0px;
        box-sizing: border-box;
        background: url('~@/assets/images/plus-circle-icon.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-inline-end: 4px;
    }
}

.option-drop-zone {
    height: 5px;

    &.bordered {
        border: 2px dotted $hover-effects-color;
        height:15px;
    }
    &.active {
        background-color: $hover-effects-color;
    }
}
.drag-handler {
    display: inline-block;
    padding: 5px 0;
    cursor: move;
}
</style>
