<template>
    <div id="forms-create-page" class="form-builder-view">

        <NavigationMenu />

        <div class="container">
            <section class="side-content">
                <h1>{{ $t('forms_create.new_form_title') }}</h1>
                <p>{{ $t('forms_create.new_form_title_description') }}</p>

                <div class="boxed-content">
                    <a class="boxed-content__link-wrapper" href="javascript:;" v-on:click="createOneStepForm()">
                        <h2>{{ $t('forms_create.one_step_form_title') }}</h2>
                        <p>{{ $t('forms_create.one_step_form_description') }}</p>
                    </a>
                </div>

                <div class="boxed-content">
                    <a class="boxed-content__link-wrapper" href="javascript:;" v-on:click="createTwoStepForm()">
                        <h2>{{ $t('forms_create.two_step_form_title') }}</h2>
                        <p>{{ $t('forms_create.two_step_form_description') }}</p>
                        <a href="javascript:;" class="details-link" v-on:click="twoStepShowDetails($event)">{{ $t('forms_create.for_more_details') }}</a>
                    </a>
                </div>

                <div class="back-link-wrapper">
                    <router-link to="/forms" class="back-link">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.19541 4.47119L9.72408 7.99986L6.19541 11.5285L7.13808 12.4712L10.6667 8.94252C10.9167 8.69249 11.0571 8.35341 11.0571 7.99986C11.0571 7.64631 10.9167 7.30723 10.6667 7.05719L7.13808 3.52852L6.19541 4.47119Z" fill="#4E2053"/>
                        </svg>
                        <span>{{ $t("forms_share.back") }}</span>
                    </router-link>
                </div>
            </section>

            <section class="main-content">
                <div class="form-preview-holder">
                    <i class="form-icon"></i>
                    <p>{{ $t('forms_create.choose_form_type') }}</p>
                </div>
            </section>


            <!--
            <h1 class="page-title mb-4 mt-3">{{ $t('forms_create.new_form_title') }}</h1>
            <form method="get" action="/form-builder" @submit.prevent="submitForm">
                <div class="mb-3">
                    <label for="name" class="form-label">{{ $t('forms_create.form_name') }} *</label>
                    <input type="text" class="form-control" required="required" autocomplete="off" v-model="name" />
                </div>
                <div class="mb-3">
                    <label for="language" class="form-label">{{ $t('forms_create.form_language') }} *</label>
                    <select class="form-control" required="required" v-model="language">
                        <option value="en">{{ $t('forms_create.language_en') }}</option>
                        <option value="he">{{ $t('forms_create.language_he') }}</option>
                        <option value="ar">{{ $t('forms_create.language_ar') }}</option>
                        <option value="ru">{{ $t('forms_create.language_ru') }}</option>
                        <option value="other">{{ $t('forms_create.language_other') }}</option>
                    </select>
                </div>
                <div class="mb-4">
                    <label for="type" class="form-label">{{ $t('forms_create.form_flow_type') }} *</label><br />
                    <input type="radio" value="ONE_STEP" required="required" v-model="type"> {{ $t('forms_create.form_flow_one_step') }}<br />
                    <input type="radio" value="TWO_STEP" required="required" v-model="type"> {{ $t('forms_create.form_flow_two_step') }}
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary">{{ $t('forms_create.form_create_submit_text') }}</button>
                    <router-link to="/forms" class="back-button">{{ $t('forms_create.back_button_text') }}</router-link>
                </div>
            </form>
            -->
        </div>


        <OverlaySidebar v-show="showOverlaySidebar"
                :title="$t('forms_create.two_step_details_title')"
                @closeClicked="closeOverlaySidebar">

            <div class="two-step-content-wrapper" v-html="$t('forms_create.two_step_details_content')">
            </div>

        </OverlaySidebar>

        <OverlayAlert v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="redirectToSubscriptionPage">
        </OverlayAlert>
    </div>
</template>

<script>
const formsEndpoint = process.env.VUE_APP_API_BASE + '/forms'

import widgetsUtility from "@/widgetsUtility.js"

import NavigationMenu from "@/components/dashboard/NavigationMenu"
import OverlaySidebar from '@/components/global/OverlaySidebar.vue'
import OverlayAlert from '@/components/global/OverlayAlert';

export default {
    name: 'FormsCreate',
    components: {
        NavigationMenu,
        OverlaySidebar,
        OverlayAlert
    },
    data() {
        return {
            fixedData: {
                name : 'טופס חדש',
                language: 'he',
                font: 'arial, helvetica, sans-serif',
                pdf_margins: '55px 55px 55px 55px'
            },
            showOverlaySidebar: false,

            modalOpen: false,
            alertMsg: ''
        }
    },
    computed: {
        direction() {
            return widgetsUtility.getLanguageDirection(this.language)
        }
    },
    methods: {
        createOneStepForm() {
            this.fixedData['name'] =  'טופס כללי חדש'
            const myData = { 'type': 'ONE_STEP' , ...this.fixedData }
            this.submitCreateForm(myData)
        },
        createTwoStepForm() {
            this.fixedData['name'] = 'טופס דו שלבי חדש'
            const myData = { 'type': 'TWO_STEP' , ...this.fixedData }
            this.submitCreateForm(myData)
        },

        submitCreateForm(data) {
            this.axios.post(formsEndpoint + '/create', data)
                .then(response => {

                    this.$router.push({
                        name: 'FormBuilder',
                        params: {
                            id : response.data._id.toString()
                        }
                    });

                }).catch(error => {
                    if (error.response.status == 402) {
                        const errorMsgKey = 'forms_create.forms_limit_reached'
                        this.alertMsg = this.$t(errorMsgKey)
                        this.modalOpen = true
                    }
                    else {
                        console.log(error)
                    }
                })
        },
        redirectToSubscriptionPage() {
            this.$router.push({
                path: '/profile',
                hash: '#subscription_plan'
            })
        },

        twoStepShowDetails(e) {
            e.preventDefault()
            e.stopPropagation()
            this.showOverlaySidebar = true
        },
        closeOverlaySidebar() {
            this.showOverlaySidebar = false
        }
        /*
        submitForm() {
            const formData = {
                'name': this.name,
                'language': this.language,
                'type': this.type,
                'direction': this.direction,
                'font': this.font,
                'pdf_margins': this.pdf_margins
            }
            this.axios.post(formsEndpoint + '/create', formData)
                .then(response => {

                    this.$router.push({
                        name: 'FormBuilder',
                        params: {
                            id : response.data._id.toString()
                        }
                    });

                }).catch(error => {
                    if (error.response.status == 402) {
                        const errorMsgKey = 'forms_create.forms_limit_reached'
                        alert(this.$t(errorMsgKey))
                    }
                    else {
                        console.log(error)
                    }
                })
        }
        */
    }
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    height: auto;
    min-height: calc(100vh - 75px)
}

.back-link-wrapper {
    text-align: right;

    a.back-link {
        font-size: $font-size-small;
        color: $secondary-color;
        line-height: 24px;
        text-decoration: none;
        display: inline-flex;
        flex-flow: row;
        align-items: center;
    }
}

.side-content {
    width: 500px;
    background: $white-color;
    text-align: center;
    padding: 30px 20px;
    box-sizing: border-box;
    flex: 0 0 auto;

    & > h1 {
        font-size: $font-size-huge;
        margin: 0;
    }
    & > p {
        font-size: $font-size;
        margin: 7px 0 20px;
    }

    .boxed-content {
        border: 1px solid $brand-color;
        border-radius: $default-border-radius;
        margin: 15px 0;
        padding: 20px;

        a {
            text-decoration: none;
            color: $text-color;
        }
        a.details-link {
            display: inline-block;
            padding: 3px 0 2px;
    		border-bottom: 1px solid $brand-color;
            margin-top: 10px;
        }
        h2 {
            font-size: $font-size-big;
            margin: 0 0 12px;
            font-weight: $font-weight-bold;
        }
        p {
            display: block;
            padding: 0 60px;
            margin: 0;
        }
    }
}

.main-content {
    flex: 1;
    width: auto;
    background: $page-background-color;
    padding: 0 40px;

    .form-preview-holder {
        background: $white-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 800px;
        margin: 0 auto;
        text-align: center;
        height: 70vh;
        margin-top: 100px;
        border-radius: $default-border-radius;
        font-size: $font-size-huge;

        p {
            margin: 10px 0 0;
        }

        .form-icon {
            display: block;
            width: 40px;
            height: 50px;
            background-image: url('~@/assets/images/icon-document-paper.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
</style>
