<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AuthLayout'
}
</script>

<style lang="scss">
@import '@/assets/style/auth.scss';
</style>
