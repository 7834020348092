import widgetsUtility from '@/widgetsUtility'

const BankinfoWidget = {
    name: 'BankinfoWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Bankinfo')
};

const BankinfoWidgetPdf = {
    name: 'BankinfoWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Bankinfo')
};

export { BankinfoWidget, BankinfoWidgetPdf };
