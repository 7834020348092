<template>
    <div class="change-password">
        <!--h2 class="tab-heading">{{ $t('profile.change_password_title') }}</h2-->
        <form v-on:submit="updatePassword">
            <div class="row">
                <div class="col-12">
                    <div class="form-group mb-4">
                        <label>{{ $t('profile.current_password') }}</label>
                        <input type="password" name="password" class="form-control" required="required" v-model="currentPassword" />
                    </div>
                    <hr />
                    <div class="form-group mb-4">
                        <label>{{ $t('profile.new_password') }}</label>
                        <input type="password" name="new_password" class="form-control" required="required" v-model="newPassword" />
                    </div>
                    <div class="form-group mb-4">
                        <label>{{ $t('profile.new_password_confirmation') }}</label>
                        <input type="password" name="new_password_confirmation" class="form-control" required="required" v-model="newPasswordConfirmation" />
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary">{{ $t('profile.password_update_button') }}</button>
                    </div>
                </div>
            </div>
        </form>

        <OverlayAlert class="files-modal" v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="modalOpen = false">
        </OverlayAlert>
    </div>
</template>

<script>
import OverlayAlert from '@/components/global/OverlayAlert.vue'

const profileEndpoint = process.env.VUE_APP_API_BASE + '/profile';

export default {
    name: 'ChangePassword',
    props: ['user'],
    components: {
        OverlayAlert
    },
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            modalOpen: false,
            alertMsg: ''
        }
    },
    computed: {
        formData() {
            return {
                password: this.currentPassword,
                new_password: this.newPassword
            }
        }
    },
    methods: {
        updatePassword(e) {
            e.preventDefault()
            if (this.newPassword != this.newPasswordConfirmation) {
                //alert(this.$t('profile.password_confirmation_does_not_match'))
                this.alertMsg = this.$t(this.$t('profile.password_confirmation_does_not_match'))
                this.modalOpen = true
                return
            }
            this.axios.post(profileEndpoint + '/update-password', this.formData)
                .then(response => {
                    console.log(response.data)
                    if (response.data['result'] == 'success') {
                        //alert(this.$t('profile.password_changed_successfully'))
                        this.alertMsg = this.$t('profile.password_changed_successfully')
                        this.modalOpen = true
                        this.currentPassword = ''
                        this.newPassword = ''
                        this.newPasswordConfirmation = ''
                    }
                    else if (response.data['result'] == 'error') {
                        this.alertMsg = this.$t('profile.' + response.data['message'])
                        this.modalOpen = true
                        //alert(response.data['message'])
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.form-control {
    max-width: 400px;
}
</style>
