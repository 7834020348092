<template>
    <div class="widget-box" :class="viewType == 'GRID' ? 'grid' : 'list'"
            draggable="true"
            @dragstart="dragStart"
            @click="addWidget">

        <div class="widget-box__icon">
            <i :style="{ 'background-image': 'url(/img/widgets/' + widget + '.svg)' }"></i>
            <!--img src="" class="widget-icon" /-->
        </div>
        <div class="widget-box__content">
            <span class="widget-name">{{ $t('form_builder.' + widget) }}</span>
            <span class="widget-description">{{ $t('form_builder.' + widget+'_description') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WidgetBox',
    props: ["widget", "viewType"],
    emits: ['addWidget'],
    methods: {
        dragStart(e) {
            e.dataTransfer.setData('widget', this.widget);
            //alert('drag started - ' + this.widget)
        },
        addWidget(e) {
            this.$emit('addWidget', this.widget)
        }
    }
};
</script>

<style lang="scss" scoped>
.widget-box {
    background: $page-background-color;
    border-radius: $default-border-radius;
    cursor: pointer;
    overflow: hidden;
}

i {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.widget-box.list {
    display: flex;
    padding: 15px 0px;
    height: 72px;
    box-sizing: border-box;

    .widget-box__icon {
        flex: 0 0 68px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            display: block;
            width: 22px;
            height: 22px;
            vertical-align:middle;
        }
    }
    .widget-box__content {
        max-width: 100%;
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3px;

        span.widget-name {
            font-size: $font-size;
            font-weight: $font-weight-bold;
        }
        span.widget-description {
            font-size: $font-size;
            font-weight: $font-weight;
        }
    }
}
.widget-box.grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 145px;
    padding: 12px;
    box-sizing: border-box;
    height: 108px;
    gap: 10px;

    .widget-box__icon {

        i {
            display: block;
            width: 30px;
            height: 30px;
        }
    }

    .widget-box__content {
        position:relative;
        max-width: 100%;
        box-sizing: border-box;
        text-align: center;

        span {
            display: block;
            width: 100%;
        }
        span.widget-name {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        span.widget-description {
            display: none;
        }
    }
}
</style>
