import widgetsUtility from '@/widgetsUtility'

const ColorWidget = {
    name: 'ColorWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Color')
};

const ColorWidgetPdf = {
    name: 'ColorWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Color')
};

export { ColorWidget, ColorWidgetPdf };
