<template>
    <div class="editor-sidenav">
        <a href="javascript:;" class="back-btn" v-on:click="backToForms()">
            <span>{{ $t('form_builder.sidenav_back_btn') }}</span>
        </a>
        <div class="icons-nav">
            <a href="javascript:;" class="icons-nav__widgets"
                    :class="formStore.activeSidebarView == 'WIDGETS_VIEW' && 'active'"
                    @click="linkClicked('WIDGETS_VIEW')">
                <i></i>
                <span>{{ $t('form_builder.sidenav_widgets_link') }}</span>
            </a>
            <a href="javascript:;" class="icons-nav__style"
                    :class="formStore.activeSidebarView == 'STYLE_VIEW' && 'active'"
                    @click="linkClicked('STYLE_VIEW')">
                <i></i>
                <span>{{ $t('form_builder.sidenav_style_link') }}</span>
            </a>
            <a href="javascript:;" class="icons-nav__settings"
                    :class="formStore.activeSidebarView == 'SETTINGS_VIEW' && 'active'"
                    @click="linkClicked('SETTINGS_VIEW')">
                <i></i>
                <span>{{ $t('form_builder.sidenav_settings_link') }}</span>
            </a>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'TheSidenav',
    computed: {
        ...mapStores(useFormStore)
    },
    methods: {
        backToForms() {
            this.formStore.saveChanges()
            this.$router.push('/forms')
        },
        linkClicked(view) {
            this.formStore.setActiveSidebarView(view)
        }
    }
}
</script>

<style lang="scss" scoped>
.back-btn {
    display: block;
    text-decoration:none;
    color: $text-color;
    text-align: center;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    height: 36px;
    box-sizing: border-box;
    line-height: 24px;
    padding: 6px;
    border-bottom: 1px solid $brand-color;

    &:before {
        content: '\2039';
        font-size: 20px;
        margin-inline-end: 2px;
    }
}
.icons-nav {
    display: flex;
    flex-direction: column;

    & > a {
        text-decoration: none;
        color: $text-color;
        height: 105px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &.active {
            background-color: $brand-color;
        }
    }

    i {
        width: 24px;
        height: 24px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__widgets i {
        background-image: url('~@/assets/images/nav-widgets-icon.svg');
    }
    &__style i {
        background-image: url('~@/assets/images/nav-style-icon.svg');
    }
    &__settings i {
        background-image: url('~@/assets/images/nav-settings-icon.svg');
    }
}
</style>
