<template>
    <div class="subscription-plan">
        <!--
        <div class="active-plan">
            <div class="form-group">
                <label>Active plan: </label>
                <span><strong>&nbsp;{{ activeSubscription ? activeSubscription.code : 'FREE' }}</strong></span>
            </div>
        </div>
        -->

        <div class="plans">
            <div class="card" v-for="plan in plans" :key="plan._id"
                    :class="((activeSubscription == null && plan.code == 'FREE') || (activeSubscription != null && plan.code == activeSubscription.code)) && 'active'">

                <span class="card__title">{{ plan.code }}</span>
                <span class="card__price">{{ parseFloat(plan.monthly_subscription_price).toFixed(2) }}₪</span>


                <span class="card__features" v-if="plan.code == 'FREE'" v-html="$t('profile.plan_features_free')"></span>
                <span class="card__features" v-if="plan.code == 'BASIC'" v-html="$t('profile.plan_features_basic')"></span>
                <span class="card__features" v-if="plan.code == 'PRO'" v-html="$t('profile.plan_features_pro')"></span>


                <template v-if="(activeSubscription == null && plan.code == 'FREE') || (activeSubscription != null && plan.code == activeSubscription.code)">
                    <a href="javascript:;" class="card__button btn btn-primary disabled" disabled>{{ $t('profile.plan_is_selected') }}</a>
                </template>
                <template v-else>
                    <a href="javascript:;" class="card__button btn btn-primary" v-on:click="choosePlan(plan.code)">{{ $t('profile.choose_plan') }}</a>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
const plansEndpoint = process.env.VUE_APP_API_BASE + '/plans';
const profileEndpoint = process.env.VUE_APP_API_BASE + '/profile';

export default {
    name: 'SubscriptionPlan',
    props: ['user'],
    data() {
        return {
            plans: [],
            activeSubscription: null
        }
    },
    created() {
        this.loadPlans()
        this.loadActiveSubscription()
    },
    methods: {
        loadPlans() {
            this.axios.get(plansEndpoint)
                .then(response => {
                    this.plans = response.data
                    //this.formData = response.data
                }).catch(error => {
                    console.log(error)
                })
        },
        loadActiveSubscription() {
            //get active subscription?
            this.axios.get(profileEndpoint + '/active-subscription')
                .then(response => {
                    this.activeSubscription = response.data
                }).catch(error => {
                    console.log(error)
                })
        },
        choosePlan(planCode) {

            if (this.activeSubscription === null) {
                this.redirectToPaymentPage(planCode)
                return
            }

            if (planCode == this.activeSubscription.code) {
                return
            }

            if (planCode == 'FREE') {
                if (confirm('Are you sure you want to cancel your subscription?')) {
                    this.updateSubscription(planCode)
                }
            }
            else {
                this.updateSubscription(planCode)
            }
        },
        updateSubscription(planCode) {
            this.axios.post(profileEndpoint + '/update-subscription', {
                    'code': planCode
                }).then(response => {
                    if (response.data.result == 'error') {
                        alert(response.data['error_message'])
                    }
                    else {
                        alert(this.$t('subscription_updated_successfully'))
                        this.loadActiveSubscription()
                    }
                }).catch(error => {
                    console.log(error)
                })
        },
        redirectToPaymentPage(planCode) {

            this.axios.post(profileEndpoint + '/get-payment-page-url', {
                    'code': planCode
                }).then(response => {
                    if (response.data.url) {
                        window.location.href = response.data.url
                    }
                    else {
                        alert('error occurred')
                        console.log(response.data)
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.plans {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    width: 100%;
    max-width: 990px;

    @media (max-width: 930px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 630px) {
        grid-template-columns: 1fr;
    }

    .card {
        background: $page-background-color;
        border-radius: $default-border-radius;
        display: flex;
        flex-flow: column;
        gap: 10px;
        padding: 30px;
        line-height: $line-height;
        box-sizing: border-box;

        &.active {
            border: 1px solid $brand-color-darker;
        }

        &__title {
            font-size: $font-size-small;
            font-weight: $font-weight-bold;
            margin-bottom: 0px;
        }

        &__price {
            font-size: $font-size-big;
            font-weight: $font-weight-bold;
        }

        &__features {
            margin: 0px 0;
            flex-grow: 1;
            text-align: right;
            margin: 20px 0;
            padding: 0 10px;
        }

        &__button {
            background: $brand-color;
            width: 100%;
            max-width: 180px;
            margin: 0 auto;
            display: block;
            border-radius: $default-border-radius;
            line-height: 24px;
            color: $text-color;
            text-decoration: none;
            font-weight: $font-weight-bold;
            padding: 6px;

            &.disabled {
                /*color: #888;*/
                background: #c8c8c8;
                cursor: default;
            }
        }
    }
}
</style>
