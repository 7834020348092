<template>
    <div class="widget-editor">
        <div class="widget-editor__wrapper">
            <h3 class="sidebar-title">{{ $t('form_builder.widget_editor_view_title', { widgetName: translatedWidgetName }) }}</h3>
            <p class="sidebar-subtitle">
                {{ $t('form_builder.widget_editor_view_subtitle') }}
            </p>

            <template v-for="property of this.propertiesOrder">

                <template v-if="this.formStore.activeWidget?.properties?.hasOwnProperty(property)">
                    <div class="widget-editor__section-wrapper" :key="this.formStore.activeWidget.id + '_' + keyHash + '_' + property">

                        <div v-if="property == 'style'" class="style-section">

                            <h3 class="style-section__title">{{ $t('form_builder.widget_editor_style_section_title') }}</h3>
                            <p class="style-section__subtitle">{{ $t('form_builder.widget_editor_style_section_subtitle') }}</p>
                            <div class="use-separate-style-section">
                                <div class="form-group">
                                    <div>
                                        <input type="checkbox" v-model="useSeparateStyles" /> {{ $t('form_builder.use_separate_styles_in_widget') }}
                                    </div>
                                </div>
                            </div>


                            <div class="default-style-section">
                                <StyleProperty :key="this.formStore.activeWidget.id + '_style_' + keyHash"
                                        :property="property"
                                        :value="this.formStore.activeWidget.properties[property]"
                                        :widget="this.formStore.activeWidget"
                                        :isOpen="activeStyleSection == 'web_form'"
                                        @updateWidgetProperty="updateWidgetProperty"
                                        @collapseOpen="this.activeStyleSection = 'web_form'"
                                        @collapseClose="this.activeStyleSection = 'pdf'" />
                            </div>

                            <hr v-if="useSeparateStyles" class="separate-styles-separator"  />

                            <div v-if="useSeparateStyles" class="pdf-style-section">
                                <PdfStyleProperty :key="this.formStore.activeWidget.id + '_pdf_style_' + keyHash"
                                        property="pdf_style"
                                        :value="widget.properties.pdf_style"
                                        :widget="widget"
                                        :isOpen="activeStyleSection == 'pdf'"
                                        @updateWidgetProperty="updateWidgetProperty"
                                        @collapseOpen="pdfStyleOpened"
                                        @collapseClose="pdfStyleClosed" />
                            </div>
                        </div>

                        <div v-else>
                            <h3 class="form-wrapper-title">{{ $t('form_builder.widget_editor_' + property + '_section_title') }}</h3>
                            <div class="form-wrapper">
                                <component :is="translateToCamelCase(property) + 'Property'"
                                    :key="this.formStore.activeWidget.id + property + keyHash"
                                    :property="property"
                                    :value="this.formStore.activeWidget.properties[property]"
                                    :widget="this.formStore.activeWidget"
                                    @updateWidgetProperty="updateWidgetProperty"
                                    @collapseOpen="webStyleOpened"
                                    @collapseClose="webStyleClosed" />
                            </div>
                        </div>

                    </div>

                </template>
            </template>

        </div>
    </div>
</template>

<script>
import ContentProperty from "@/components/form-builder/properties/ContentProperty.vue";
import ImageProperty from "@/components/form-builder/properties/ImageProperty.vue";
import InputProperty from "@/components/form-builder/properties/InputProperty.vue";
import FillProperty from "@/components/form-builder/properties/FillProperty.vue";
import DisplayProperty from "@/components/form-builder/properties/DisplayProperty.vue";
import ValidationsProperty from "@/components/form-builder/properties/ValidationsProperty.vue";
import StyleProperty from "@/components/form-builder/properties/StyleProperty.vue";

import PdfStyleProperty from "@/components/form-builder/properties/PdfStyleProperty.vue";

import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'WidgetEditor',
    components: {
        ContentProperty,
        ImageProperty,
        InputProperty,
        FillProperty,
        DisplayProperty,
        ValidationsProperty,

        StyleProperty,
        PdfStyleProperty
    },
    data() {
        return {
            //translatedWidgetName: ''
            activeTab: 'content',
            activeStyleSection: '',
            keyHash: '',
            useSeparateStyles: false,

            propertiesOrder: [
                'content',
                'image',
                'input',
                'fill',
                'display',
                'validations',
                'style'
            ]
        }
    },
    mounted() {
        this.widget = this.formStore.activeWidget
        //console.log('x')
        //this.activeStyleSection = this.widget.properties?.hasOwnProperty('pdf_style') && this.activeView == 'PDF_VIEW' ? 'pdf' : 'web_form'
        this.useSeparateStyles = this.widget.properties?.hasOwnProperty('pdf_style')

        if (this.useSeparateStyles) {
            this.activeStyleSection = this.activePreviewView == 'PDF_VIEW' ? 'pdf' : 'web_form'
        }
        else {
            this.activeStyleSection = 'web_form'
        }
    },
    computed: {
        translatedWidgetName() {
            return this.$t('form_builder.' + this.formStore.activeWidget.widget)
        },
        activePreviewView() {
            return this.formStore.$state.activePreviewView
        },
        widget() {
            return this.formStore.$state.activeWidget
        },
        ...mapStores(useFormStore)
    },
    watch: {
        useSeparateStyles(newValue) {
            if (newValue === true && !this.widget.properties.hasOwnProperty('pdf_style')) {
                this.formStore.addPdfStyleToWidget(this.widget)
                //this.activeStyleSection = this.activePreviewView == 'PDF_VIEW' ? 'pdf' : 'web_form'
                //this.$emit('addPdfStyleToWidget', this.widget)
                this.formStore.setActivePreviewView('PDF_VIEW')
                this.activeStyleSection = 'pdf'
            }
            else if (newValue === false && this.widget.properties.hasOwnProperty('pdf_style')) {
                //this.$emit('removePdfStyleFromWidget', this.widget)
                this.formStore.removePdfStyleFromWidget(this.widget)
                this.activeStyleSection = 'web_form'
            }
        },
        activeStyleSection(newValue) {
            if (this.useSeparateStyles) {
                if (newValue === 'pdf') {
                    this.formStore.setActivePreviewView('PDF_VIEW')
                }
                else if (newValue === 'web_form') {
                    this.formStore.setActivePreviewView('WEB_VIEW')
                }
            }
        },
        activePreviewView(newValue) {
            if (this.useSeparateStyles) {
                if (newValue === 'PDF_VIEW') {
                    this.activeStyleSection = 'pdf'
                }
                else if (newValue === 'WEB_VIEW') {
                    this.activeStyleSection = 'web_form'
                }
            }
        },
        widget() {
            if (this.widget.properties?.hasOwnProperty('style')) {
                this.useSeparateStyles = this.widget.properties?.hasOwnProperty('pdf_style')
                if (this.useSeparateStyles) {
                    this.activeStyleSection = this.activeView == 'PDF_VIEW' ? 'pdf' : 'web_form'
                }
                else {
                    this.activeStyleSection = 'web_form'
                }
            }
            this.keyHash = Date.now().toString()
        }
    },
    methods: {
        updateWidgetProperty(propertySection, propertyKey, propertyValue) {
            //console.log(propertyKey);
            //alert(propertySection + ':' + propertyKey + ':' + propertyValue)
            this.formStore.updateWidgetProperty(propertySection, propertyKey, propertyValue)
            //this.$emit('updateWidgetProperty', propertyKey, propertyValue);
        },
        pdfStyleOpened() {
            this.activeStyleSection = this.activeStyleSection == 'pdf' ? '' : 'pdf'
        },
        pdfStyleClosed() {
            this.activeStyleSection = ''
        },
        webStyleOpened() {
            this.activeStyleSection = this.activeStyleSection == 'web_form' ? '' : 'web_form'
        },
        webStyleClosed() {
            this.activeStyleSection = ''
        }
    }
};
</script>

<style lang="scss" scoped>
hr.separate-styles-separator {
    border: 0;
    background: transparent;
    height: 1px;
    border-bottom: 1px solid #f3f3f3;
    margin: 15px 0 15px;
}

.widget-editor {
    padding: 20px 10px;
    box-sizing: border-box;
    height: auto;
    overflow: hidden;
    display: flex;

    &__wrapper {
        flex-grow: 1;
        overflow-y: auto;
        direction: ltr;
        padding: 0 10px;

        & > * {
            direction: rtl;
        }
    }
}

.use-separate-style-section {
    margin-bottom: 15px;
}

.style-section {

    &__title {
        font-size: $font-size;
        margin: 5px 0 5px;
    }
    &__subtitle {
        font-size: $font-size;
        margin: 0 0 20px;
    }
}
</style>
