import widgetsUtility from '@/widgetsUtility'

const TextareaWidget = {
    name: 'TextareaWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Textarea')
};

const TextareaWidgetPdf = {
    name: 'TextareaWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Textarea')
};

export { TextareaWidget, TextareaWidgetPdf };
