import widgetsUtility from '@/widgetsUtility'

const SignatureWidget = {
    name: 'SignatureWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Signature')
};

const SignatureWidgetPdf = {
    name: 'SignatureWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Signature')
};

export { SignatureWidget, SignatureWidgetPdf };
