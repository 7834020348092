import { useAuthStore } from '@/stores/auth.js';

export const addAuthorizationHeader = function(request) {
    const auth = useAuthStore();
    //console.log(request)
	if (auth.user && auth.token) {
		request.headers.Authorization = `Bearer ` + auth.token
    }
    return request;
}
