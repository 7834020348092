import widgetsUtility from '@/widgetsUtility'

const DaterangeWidget = {
    name: 'DaterangeWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Daterange')
};

const DaterangeWidgetPdf = {
    name: 'DaterangeWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Daterange')
};

export { DaterangeWidget, DaterangeWidgetPdf };
