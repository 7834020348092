<template>
    <div id="users-index-page">
        <div class="container">
            <div class="row">
                <div class="page-heading">
                    <h1 class="page-title">{{ $t('admin.users_index_page_title') }}</h1>
                </div>
            </div>
            <div class="row">
                <section class="data">
                    <DataTable
                        :dataUrl="dataUrl"
                        actionColumnName="Login as User"
                        actionColumnValue="Login"
                        @actionTriggered="loginAsUser"
                    />
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from '@/components/global/DataTable.vue'

const apiBase = process.env.VUE_APP_API_BASE;

import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'UsersIndex',
    components: {
        DataTable
    },
    data: function() {
        return {
            dataUrl: apiBase + '/users'
        }
    },
    computed: {
        ...mapStores(useAuthStore)
    },
    methods: {
        loginAsUser(userId) {
            this.axios.post(apiBase + '/users/' + userId + '/login', this.formData)
                .then(response => {
                    console.log(response.data)
                    if (response.data['result'] == 'success') {
                        this.authStore.setAuthData(response.data['user'], response.data['token'])
                        this.$router.push(this.authStore.returnUrl || '/')
                    }
                    else if (response.data['result'] == 'error') {
                        alert(this.$t('admin.' + response.data['message']))
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style>
</style>
