<template>
    <div class="change-password-component auth-component">
        <div class="auth-container">
            <div class="auth-container__logo">
                <figure><img :src="require('@/assets/images/site-logo.png')" /></figure>
            </div>

            <div class="auth-container__title">
                <h3>{{ $t("auth.reset_password_title") }}</h3>
            </div>

            <div class="auth-container__content">

                <p v-if="tokenIsValid === 0">Error occurred. #8771</p>
                <p v-if="tokenIsValid === null">Validating request...</p>
                <p v-else-if="tokenIsValid === false">Token (URL) is invalid or expired.</p>
                <form v-else-if="tokenIsValid === true && !resetSuccessfully" v-on:submit="submitForm($event)">
                    <div class="form-group">
                        <label>{{ $t('auth.new_password') }}</label>
                        <i class="input-icon password-icon"></i>
                        <input type="password" name="password" v-model="password" required="required" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>{{ $t('auth.new_password_confirmation') }}</label>
                        <i class="input-icon password-icon"></i>
                        <input type="password" name="password_confirmation" v-model="passwordConfirmation" required="required" class="form-control" />
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary form-control"
                                :disabled="this.formSubmitted"
                                :class="this.formSubmitted && 'disabled'">
                            {{ $t('auth.reset_password_button') }}
                        </button>
                    </div>
                </form>

                <p v-else-if="tokenIsValid === true && resetSuccessfully" style="font-size: 18px;">
                    Password has been reset successfully.
                    <br /><br />
                    <router-link to="/auth/login" class="auth-navigation-link">{{
                        $t("auth.login_link_from_register")
                    }}</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'AuthPasswordChange',
    data: function() {
        return {
            userId: '',
            token: '',

            password: '',
            passwordConfirmation: '',

            tokenIsValid: null, //can be true, false, 0 (error status) - or null initial value / not loaded
            formSubmitted: false,
            resetSuccessfully: false
        }
    },
    computed: {
        ...mapStores(useAuthStore)
    },
    async created() {
        this.userId = this.$route.params.userId
        this.token = this.$route.params.token

        this.tokenIsValid = await this.authStore.verifyPasswordToken(this.userId,
                this.token)
    },
    methods: {
        async submitForm(e) {
            e.preventDefault()
            this.formSubmitted = true

            if (this.password !== this.passwordConfirmation) {
                alert('Passwords do not match')
                this.formSubmitted = false
                return
            }

            const result = await this.authStore.setPassword(this.userId,
                    this.token,
                    this.password)

            if (result === true) {
                this.resetSuccessfully = true
                return
            }

            if (result === 0) {
                alert('Error occurred -- #991')
            }
            else if (result === false) {
                alert('Invalid request -- #992')
            }
            this.formSubmitted = false
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
