<template>
    <div class="label-property">
        <label for="label">{{ $t('form_builder.label') }}</label>
        <input type="text" name="label" v-model="contentValue" />
    </div>
</template>

<script>
export default {
    name: 'LabelProperty',
    props: ["property", "value"],
    emits: ['updateProperty'],
    data() {
        return {
            contentValue: this.value
        }
    },
    watch: {
        contentValue(newValue) {
            this.$emit('updateProperty', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
