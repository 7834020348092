<template>
    <Teleport to="body">
        <div class="alert-overlay">
            <div class="alert-container">
                <div class="alert-container__close-btn">
                    <a href="javascript:;" @click="alertClosed"><img :src="require('../../assets/images/icon-close-black.svg')" alt="Close" /></a>
                </div>
                <div class="alert-container__exclamation-sign">
                    <img :src="require('../../assets/images/icon-exclamation.svg')" alt="Alert" />
                </div>
                <div class="alert-container__content">
                    {{ content }}
                </div>
                <div class="alert-container__submit-btn">
                    <button type="submit" @click="alertClosed">{{ finalSubmitText }}</button>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
export default {
    name: 'OverlayAlert',
    props: ['content', 'submitText'],
    emits: ['alertClosed'],
    computed: {
        finalSubmitText() {
            return this.submitText ? this.submitText : this.$t('alert.default_submit_text');
        }
    },
    methods: {
        alertClosed() {
            this.$emit('alertClosed')
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 999;
    background-color: $fade-background-color;

    .alert-container {
        position: fixed;
        width: 475px;
        height: auto;
        background-color: $white-color;
        left: 50%;
        top: 50%;
        margin-left: -245px;
        margin-top: -220px;
        border-radius: 15px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, .10);
        box-sizing: border-box;
        padding: 25px 25px 100px;

        &__close-btn {
            text-align: left;
        }

        &__exclamation-sign {
            text-align: center;
            margin-top: 70px;
        }

        &__content {
            text-align: center;
            padding: 20px 10% 25px;
            font-size: $font-size-huge;
        }

        &__submit-btn {
            text-align: center;

            button {
                width: 175px;
                height: 35px;
                line-height:34px;
                padding:0;
                text-align:center;
                font-weight: $font-weight-bold;
                text-transform: capitalize;

                background-color: $brand-color;
                border-radius: 10px;
                border: 1px solid $brand-color;
            }
        }
    }
}

@media (max-width: 550px) {
    .alert-overlay {
        .alert-container {
            width: 320px;
            margin-left: -160px;
            margin-top: -200px;
            padding: 25px 25px 75px;


            &__content {
                padding: 20px 5% 25px;
                font-size: $font-size-big;
            }
        }

    }
}
</style>
