import ProfileIndex from '@/views/ProfileIndex.vue';

const routes = [
    {
        path: '/profile',
		name: 'ProfileIndex',
		component: ProfileIndex,
        meta: { layout: 'DashboardLayout' }
    }
];

export default routes
