<template>
    <div class="font-styling-property no-grow">

        <label>{{ $t('form_builder.font_styling') }}</label>
        <!--
        <input type="number" name="font-size" v-model="fontsizeValue" class="input-small" min="9" /> <span class="input-tip">px</span>
        -->
        <div class="tabs-control" style="width: 102px;">

            <div class="tabs-control__option" :class="fontStyling['font-style'] === 'italic' && 'active'"
                    v-on:click="toggleItalic">

                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8333 0H3.16667C2.98986 0 2.82029 0.0702379 2.69526 0.195262C2.57024 0.320286 2.5 0.489856 2.5 0.666667C2.5 0.843478 2.57024 1.01305 2.69526 1.13807C2.82029 1.2631 2.98986 1.33333 3.16667 1.33333H6.736L4.918 14.6667H1.16667C0.989856 14.6667 0.820286 14.7369 0.695262 14.8619C0.570238 14.987 0.5 15.1565 0.5 15.3333C0.5 15.5101 0.570238 15.6797 0.695262 15.8047C0.820286 15.9298 0.989856 16 1.16667 16H9.83333C10.0101 16 10.1797 15.9298 10.3047 15.8047C10.4298 15.6797 10.5 15.5101 10.5 15.3333C10.5 15.1565 10.4298 14.987 10.3047 14.8619C10.1797 14.7369 10.0101 14.6667 9.83333 14.6667H6.264L8.082 1.33333H11.8333C12.0101 1.33333 12.1797 1.2631 12.3047 1.13807C12.4298 1.01305 12.5 0.843478 12.5 0.666667C12.5 0.489856 12.4298 0.320286 12.3047 0.195262C12.1797 0.0702379 12.0101 0 11.8333 0V0Z" fill="#313131"/>
                </svg>
            </div>

            <div class="tabs-control__option" :class="fontStyling['text-decoration'] === 'line-through' && 'active'"
                    v-on:click="toggleLinethrough">

                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1412_938)">
                <path d="M14.3 8.5999H2.69985C2.36865 8.5999 2.09985 8.3311 2.09985 7.9999C2.09985 7.6687 2.36865 7.3999 2.69985 7.3999H14.3C14.6312 7.3999 14.9 7.6687 14.9 7.9999C14.9 8.3311 14.6312 8.5999 14.3 8.5999Z" fill="#313131"/>
                <path d="M8.49995 16C5.85269 16 3.69995 14.0711 3.69995 11.7C3.69995 11.3688 3.96875 11.1 4.29995 11.1C4.63115 11.1 4.89995 11.3688 4.89995 11.7C4.89995 13.4096 6.51509 14.8 8.49995 14.8C10.4848 14.8 12.1 13.4096 12.1 11.7C12.1 9.99038 10.4848 8.59995 8.49995 8.59995C5.85269 8.59995 3.69995 6.67119 3.69995 4.30005C3.69995 1.92876 5.85269 0 8.49995 0C11.1472 0 13.3 1.92876 13.3 4.30005C13.3 4.63125 13.0312 4.90005 12.7 4.90005C12.3687 4.90005 12.1 4.63125 12.1 4.30005C12.1 2.59043 10.4848 1.2 8.49995 1.2C6.51509 1.2 4.89995 2.59043 4.89995 4.30005C4.89995 6.00967 6.51509 7.39995 8.49995 7.39995C11.1472 7.39995 13.3 9.32886 13.3 11.7C13.3 14.0711 11.1472 16 8.49995 16Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_1412_938">
                <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
                </svg>

            </div>

            <div class="tabs-control__option" :class="fontStyling['text-decoration'] === 'underline' && 'active'"
                    v-on:click="toggleUnderline">

                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1412_944)">
                <path d="M8.50008 13.3333C9.91408 13.3317 11.2697 12.7693 12.2696 11.7695C13.2694 10.7696 13.8318 9.414 13.8334 8V0.666667C13.8334 0.489856 13.7632 0.320286 13.6382 0.195262C13.5131 0.0702379 13.3436 0 13.1667 0C12.9899 0 12.8204 0.0702379 12.6953 0.195262C12.5703 0.320286 12.5001 0.489856 12.5001 0.666667V8C12.5001 9.06087 12.0787 10.0783 11.3285 10.8284C10.5784 11.5786 9.56095 12 8.50008 12C7.43922 12 6.4218 11.5786 5.67165 10.8284C4.92151 10.0783 4.50008 9.06087 4.50008 8V0.666667C4.50008 0.489856 4.42984 0.320286 4.30482 0.195262C4.17979 0.0702379 4.01023 0 3.83341 0C3.6566 0 3.48703 0.0702379 3.36201 0.195262C3.23699 0.320286 3.16675 0.489856 3.16675 0.666667V8C3.16834 9.414 3.73075 10.7696 4.7306 11.7695C5.73045 12.7693 7.08608 13.3317 8.50008 13.3333Z" fill="#313131"/>
                <path d="M15.8333 14.6663H1.16667C0.989856 14.6663 0.820286 14.7365 0.695262 14.8615C0.570238 14.9865 0.5 15.1561 0.5 15.3329C0.5 15.5097 0.570238 15.6793 0.695262 15.8043C0.820286 15.9293 0.989856 15.9996 1.16667 15.9996H15.8333C16.0101 15.9996 16.1797 15.9293 16.3047 15.8043C16.4298 15.6793 16.5 15.5097 16.5 15.3329C16.5 15.1561 16.4298 14.9865 16.3047 14.8615C16.1797 14.7365 16.0101 14.6663 15.8333 14.6663Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_1412_944">
                <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
                </svg>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'FontStylingProperty',
    props: ["property", "value"],
    data() {
        return {
            fontStyling: JSON.parse(JSON.stringify(this.value))
        }
    },
    watch: {
        fontsizeValue(newValue) {
            var myValue = newValue;
            if (isNaN(newValue) || newValue == 0) {
                myValue = 'inherit'
            }
            else {
                myValue = newValue + 'px'
            }
            this.$emit('updateStyle', 'font-size', myValue)
        }
    },
    methods: {
        toggleItalic() {
            this.fontStyling['font-style'] =
                    this.fontStyling['font-style'] == 'italic' ? 'normal' : 'italic';
            this.$emit('updateStyle', 'font-style', this.fontStyling['font-style']);
        },
        toggleUnderline() {
            this.fontStyling['text-decoration'] =
                    this.fontStyling['text-decoration'] == 'underline' ? 'none' : 'underline';
            this.$emit('updateStyle', 'text-decoration', this.fontStyling['text-decoration']);
        },
        toggleLinethrough() {
            this.fontStyling['text-decoration'] =
                    this.fontStyling['text-decoration'] == 'line-through' ? 'none' : 'line-through';
            this.$emit('updateStyle', 'text-decoration', this.fontStyling['text-decoration']);
        }
    }
};
</script>

<style>
</style>
