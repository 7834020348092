<template>
    <div class="drag-placeholder">

        <div class="inner-flex"
                @dragover.prevent="dragOver"
                @dragleave.prevent="dragLeave"
                @drop.prevent="drop"
                :class="formStore.formData?.form_schema?.length == 0 && 'empty'">

            <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1492_399)">
                <path d="M7.20833 0H4.33333C3.31667 0 2.34165 0.403868 1.62276 1.12276C0.903868 1.84165 0.5 2.81667 0.5 3.83333L0.5 6.70833C0.5 7.725 0.903868 8.70002 1.62276 9.41891C2.34165 10.1378 3.31667 10.5417 4.33333 10.5417H7.20833C8.225 10.5417 9.20002 10.1378 9.91891 9.41891C10.6378 8.70002 11.0417 7.725 11.0417 6.70833V3.83333C11.0417 2.81667 10.6378 1.84165 9.91891 1.12276C9.20002 0.403868 8.225 0 7.20833 0V0ZM9.125 6.70833C9.125 7.21667 8.92307 7.70418 8.56362 8.06362C8.20418 8.42307 7.71667 8.625 7.20833 8.625H4.33333C3.825 8.625 3.33749 8.42307 2.97805 8.06362C2.6186 7.70418 2.41667 7.21667 2.41667 6.70833V3.83333C2.41667 3.325 2.6186 2.83749 2.97805 2.47805C3.33749 2.1186 3.825 1.91667 4.33333 1.91667H7.20833C7.71667 1.91667 8.20418 2.1186 8.56362 2.47805C8.92307 2.83749 9.125 3.325 9.125 3.83333V6.70833Z" fill="#4E2053"/>
                <path d="M7.20833 12.4583H4.33333C3.31667 12.4583 2.34165 12.8621 1.62276 13.581C0.903868 14.2999 0.5 15.2749 0.5 16.2916L0.5 19.1666C0.5 20.1833 0.903868 21.1583 1.62276 21.8772C2.34165 22.5961 3.31667 22.9999 4.33333 22.9999H7.20833C8.225 22.9999 9.20002 22.5961 9.91891 21.8772C10.6378 21.1583 11.0417 20.1833 11.0417 19.1666V16.2916C11.0417 15.2749 10.6378 14.2999 9.91891 13.581C9.20002 12.8621 8.225 12.4583 7.20833 12.4583ZM9.125 19.1666C9.125 19.6749 8.92307 20.1624 8.56362 20.5219C8.20418 20.8813 7.71667 21.0833 7.20833 21.0833H4.33333C3.825 21.0833 3.33749 20.8813 2.97805 20.5219C2.6186 20.1624 2.41667 19.6749 2.41667 19.1666V16.2916C2.41667 15.7833 2.6186 15.2957 2.97805 14.9363C3.33749 14.5769 3.825 14.3749 4.33333 14.3749H7.20833C7.71667 14.3749 8.20418 14.5769 8.56362 14.9363C8.92307 15.2957 9.125 15.7833 9.125 16.2916V19.1666Z" fill="#4E2053"/>
                <path d="M19.6668 12.4583H16.7918C15.7751 12.4583 14.8001 12.8621 14.0812 13.581C13.3623 14.2999 12.9584 15.2749 12.9584 16.2916V19.1666C12.9584 20.1833 13.3623 21.1583 14.0812 21.8772C14.8001 22.5961 15.7751 22.9999 16.7918 22.9999H19.6668C20.6834 22.9999 21.6584 22.5961 22.3773 21.8772C23.0962 21.1583 23.5001 20.1833 23.5001 19.1666V16.2916C23.5001 15.2749 23.0962 14.2999 22.3773 13.581C21.6584 12.8621 20.6834 12.4583 19.6668 12.4583ZM21.5834 19.1666C21.5834 19.6749 21.3815 20.1624 21.022 20.5219C20.6626 20.8813 20.1751 21.0833 19.6668 21.0833H16.7918C16.2834 21.0833 15.7959 20.8813 15.4365 20.5219C15.077 20.1624 14.8751 19.6749 14.8751 19.1666V16.2916C14.8751 15.7833 15.077 15.2957 15.4365 14.9363C15.7959 14.5769 16.2834 14.3749 16.7918 14.3749H19.6668C20.1751 14.3749 20.6626 14.5769 21.022 14.9363C21.3815 15.2957 21.5834 15.7833 21.5834 16.2916V19.1666Z" fill="#4E2053"/>
                <path d="M13.9168 6.70825H16.7918V9.58325C16.7918 9.83742 16.8927 10.0812 17.0725 10.2609C17.2522 10.4406 17.4959 10.5416 17.7501 10.5416C18.0043 10.5416 18.248 10.4406 18.4277 10.2609C18.6075 10.0812 18.7084 9.83742 18.7084 9.58325V6.70825H21.5834C21.8376 6.70825 22.0814 6.60729 22.2611 6.42756C22.4408 6.24784 22.5418 6.00408 22.5418 5.74992C22.5418 5.49575 22.4408 5.252 22.2611 5.07228C22.0814 4.89255 21.8376 4.79159 21.5834 4.79159H18.7084V1.91659C18.7084 1.66242 18.6075 1.41866 18.4277 1.23894C18.248 1.05922 18.0043 0.958252 17.7501 0.958252C17.4959 0.958252 17.2522 1.05922 17.0725 1.23894C16.8927 1.41866 16.7918 1.66242 16.7918 1.91659V4.79159H13.9168C13.6626 4.79159 13.4188 4.89255 13.2391 5.07228C13.0594 5.252 12.9584 5.49575 12.9584 5.74992C12.9584 6.00408 13.0594 6.24784 13.2391 6.42756C13.4188 6.60729 13.6626 6.70825 13.9168 6.70825Z" fill="#4E2053"/>
                </g>
                <defs>
                <clipPath id="clip0_1492_399">
                <rect width="23" height="23" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
            </svg>
            <h4>{{ $t('form_builder.drag_first_element_title') }}</h4>
            <p>{{ $t('form_builder.drag_first_element_description') }}</p>

        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'DragPlaceholder',
    computed: {
        ...mapStores(useFormStore)
    },
    methods: {
        dragOver(e) {
            e.target.classList.add('active');
        },
        dragLeave(e) {
            e.target.classList.remove('active');
        },
        drop(e) {
            const widgetPosition = e.dataTransfer.getData('widget_position')
            const widget = e.dataTransfer.getData('widget')
            const lastIndex = this.formStore.$state.formData.length

            if (widgetPosition) {
                this.formStore.moveWidget(widgetPosition, lastIndex);
            }
            else if (widget) {
                this.formStore.addWidget(widget, lastIndex);
            }
            e.target.classList.remove('active');
        }
    }
}
</script>

<style lang="scss" scoped>
.drag-placeholder {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: $font-family;

    .inner-flex {
        border: 1px dashed #B2B2B2;
        width: 100%;
        height: 215px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &.active {
            border: 2px dashed $brand-color;
        }

        & > * {
            max-width: 450px;
            text-align: center;
            pointer-events: none;
        }

        svg {
            margin: 0;
            opacity: 0.4;
        }
        h4 {
            margin: 0;
            font-weight: $font-weight;
            font-size: $font-size-huge;
            line-height: $line-height;
        }
        p {
            margin: 0;
            line-height: $line-height;
            font-size: $font-size;
        }

        &.empty {
            margin-top: calc((100vh - $nav-height - 160px - 215px - 70px) / 2)
            /* 100vh - navHeight - ControlsHeight - ElementHeight - ParentPadding, divided by 2 to space from one side only */
        }

        &:not(.empty) {
            height: 100px;
            margin-top: 10px;

            h4, p {
                display: none;
            }
        }
    }
}
</style>
