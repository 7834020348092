<template>
    <div id="forms-index-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <h1 class="page-title">{{ $t('forms_index.my_forms_title_text') }}</h1>

                <div class="table-header">
                    <div class="table-header__tabs">
                        <TabsNavigation class="tabs-navigation" :linksData="tabsData"
                                :activeLink="activeTab"
                                @linkClicked="tabClicked"/>
                    </div>

                    <div class="table-header__controls">
                        <div class="table-header__controls__search">
                            <SearchBox :placeholder="$t('forms_index.search_placeholder')"
                                    @search="updateFilterData" />
                        </div>
                        <router-link to="/forms/create" class="table-header__controls__btn">+ {{ $t('forms_index.create_new_form') }}</router-link>
                    </div>
                </div>

                <NewDataTable class="forms-data-table"
                        :dataUrl="dataUrl"
                        :displayId="false"
                        :dataFormatter="formatData"
                        :filterData="filterData"
                        @actionTriggered="actionTriggered"
                        :hiddenIds="hiddenIds">

                    <template v-slot:empty>
                        {{ $t('forms_index.empty_table_text') }}
                    </template>
                </NewDataTable>
            </section>
        </div>
    </div>
</template>

<script>
import NewDataTable from '@/components/global/NewDataTable.vue'
import TabsNavigation from '@/components/global/TabsNavigation.vue'
import SearchBox from '@/components/global/SearchBox.vue'

const formsEndpoint = process.env.VUE_APP_API_BASE + '/forms';

import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'FormsIndex',
    components: {
        NewDataTable,
        TabsNavigation,
        SearchBox
    },
    data() {
        return {
            formData: [],
            dataUrl: formsEndpoint,
            hiddenIds: [],
            filterData: {},
            hashKey: Date.now(),

            formsCounter: 0,
            draftFormsCounter: 0,
            publishedFormsCounter: 0,

            activeTab: 'all'
        }
    },
    computed: {
        tabsData() {
            return {
                'all': this.$t("forms_index.forms_all_tab_text") + ' (' + this.formsCounter + ')',
                'PUBLISHED': this.$t("forms_index.forms_published_tab_text") + ' (' + this.publishedFormsCounter + ')',
                'DRAFT': this.$t("forms_index.forms_draft_tab_text") + ' (' + this.draftFormsCounter + ')'
            }
        },
        ...mapStores(useFormStore)
    },
    watch: {
        filterData(newValue) {
            //this.hashKey = Date.now()
        }
    },
    methods: {
        formatData(formsData) {
            this.formsCounter = 0
            this.publishedFormsCounter = 0
            this.draftFormsCounter = 0

            for (var formKey in formsData) {
                //set actions data
                var record = formsData[formKey]

                var actions = {}
                if (record.status.value == 'PUBLISHED') {
                    actions['share'] = '<i class="share-action"></i>'
                    actions['view'] = '<i class="view-action"></i>'
                }
                else {
                    actions['edit'] = '<i class="edit-action"></i>'
                }
                actions['delete'] = '<i class="delete-action"></i>'

                record['actions'] = {
                    'label': '',
                    'value': actions,
                    'display_type': 'actions'
                }

                //add icon
                formsData[formKey] = { 'icon': {
                    'label': '',
                    'value': '<i class="form-icon"></i>',
                    'display_type': 'html'
                }, ...record }

                //form counters
                this.formsCounter++
                if (record.status.value == 'PUBLISHED') {
                    this.publishedFormsCounter++
                }
                else {
                    this.draftFormsCounter++
                }

                //translate labels
                for (var k in record) {
                    if (record[k]['label']) {
                        record[k]['label'] = this.$t('forms_index.' + record[k]['label'])
                    }

                    if (k == 'created_at' || k == 'published_at') {
                        record[k]['value'] = this.formatDate(record[k]['value'])
                    }
                    else if (k == 'type' || k == 'status') {
                        record[k]['value'] = this.$t('forms_index.' + record[k]['value'])
                    }
                    else if (k == 'records_num') {
                        record[k]['display_type'] = 'html'

                        if (record[k]['value']) {
                            record[k]['value'] = `
                                <span>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1724_751)">
                                    <path d="M6 6C6.59334 6 7.17336 5.82405 7.66671 5.49441C8.16006 5.16477 8.54458 4.69623 8.77164 4.14805C8.9987 3.59987 9.05811 2.99667 8.94236 2.41473C8.8266 1.83279 8.54088 1.29824 8.12132 0.878681C7.70176 0.459123 7.16721 0.173401 6.58527 0.0576455C6.00333 -0.0581102 5.40013 0.00129986 4.85195 0.228363C4.30377 0.455426 3.83524 0.839943 3.50559 1.33329C3.17595 1.82664 3 2.40666 3 3C3.00079 3.79541 3.31712 4.55801 3.87956 5.12044C4.44199 5.68288 5.20459 5.99921 6 6ZM6 1C6.39556 1 6.78224 1.1173 7.11114 1.33706C7.44004 1.55682 7.69638 1.86918 7.84776 2.23463C7.99914 2.60009 8.03874 3.00222 7.96157 3.39018C7.8844 3.77814 7.69392 4.13451 7.41421 4.41422C7.13451 4.69392 6.77814 4.8844 6.39018 4.96157C6.00222 5.03874 5.60009 4.99914 5.23463 4.84776C4.86918 4.69639 4.55682 4.44004 4.33706 4.11114C4.1173 3.78224 4 3.39556 4 3C4 2.46957 4.21071 1.96086 4.58579 1.58579C4.96086 1.21072 5.46957 1 6 1Z" fill="#313131"/>
                                    <path d="M6 7.00049C4.80693 7.00181 3.66311 7.47634 2.81948 8.31997C1.97585 9.1636 1.50132 10.3074 1.5 11.5005C1.5 11.6331 1.55268 11.7603 1.64645 11.854C1.74021 11.9478 1.86739 12.0005 2 12.0005C2.13261 12.0005 2.25979 11.9478 2.35355 11.854C2.44732 11.7603 2.5 11.6331 2.5 11.5005C2.5 10.5722 2.86875 9.68199 3.52513 9.02561C4.1815 8.36924 5.07174 8.00049 6 8.00049C6.92826 8.00049 7.8185 8.36924 8.47487 9.02561C9.13125 9.68199 9.5 10.5722 9.5 11.5005C9.5 11.6331 9.55268 11.7603 9.64645 11.854C9.74021 11.9478 9.86739 12.0005 10 12.0005C10.1326 12.0005 10.2598 11.9478 10.3536 11.854C10.4473 11.7603 10.5 11.6331 10.5 11.5005C10.4987 10.3074 10.0241 9.1636 9.18052 8.31997C8.33689 7.47634 7.19307 7.00181 6 7.00049Z" fill="#313131"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_1724_751">
                                    <rect width="12" height="12" fill="white"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                </span>
                                <span>${record[k]['value']}</span>
                            `
                        }
                        else {
                            record[k]['value'] = ''
                        }
                    }
                }
            }

            return formsData
        },
        actionTriggered(actionKey, identifier) {
            if (!identifier) {
                alert('Error occurred #88')
            }
            switch (actionKey) {
                case 'edit':
                    this.$router.push('/form-builder/' + identifier)
                    break
                case 'share':
                    this.$router.push('/forms/' + identifier + '/share')
                    break
                case 'view':
                    this.$router.push('/forms/' + identifier + '/records')
                    break
                case 'delete':
                    this.deleteForm(identifier)
                    break
            }
        },
        deleteForm(identifier) {
            if (confirm(this.$t('forms_index.delete_form_confirmation_message'))) {
                this.formStore.deleteForm(identifier)
                    .then(response => {
                        if (response.data?.result !== 'success') {
                            alert('Error deleting form. Invalid response')
                        }
                        this.hiddenIds.push(identifier)
                        this.formsCounter--
                        
                    }).catch(error => {
                        if (error.response?.status == 402) {
                            if (error.response?.data?.message) {
                                alert(this.$t('form_builder.' + error.response.data.message))
                            }
                        }
                        else {
                            alert('Error deleting form')
                            console.log(error)
                        }
                    })
            }
        },
        updateFilterData(query) {
            if (query == '') {
                this.filterData['name'] = null
            }
            else {
                this.filterData[name] = query
            }
        },
        tabClicked(a) {
            this.activeTab = a
            if (a == 'all') {
                this.filterData['status'] = null
            }
            else {
                this.filterData['status'] = this.$t('forms_index.' + a)
            }
        }
    }
}
</script>

<style lang="scss">
.forms-data-table {
    height: auto;

    max-height: calc(100vh - 300px);

    @media (min-width: 991px) {
        max-height: calc(100vh - 255px);
    }

    .dt-td-icon {
        padding-right:25px;
    }
    .form-icon {
        display: block;
        width: 25px;
        height: 25px;
        background-image: url('~@/assets/images/icon-document-paper.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .dt-span-name {
        font-weight: $font-weight-bold;
    }
    .dt-span-type {
        font-size: $font-size-small;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        padding: 0px 12px;
        border: 1px solid $secondary-color;
        color: $secondary-color;
        border-radius: 5px;
    }

    .dt-action {
        padding: 3px;
        margin: 0 5px;

        i {
            display: inline-block;
            background-position: center center;
            background-repeat: no-repeat;
            width: 19px;
            height: 19px;
            vertical-align: middle;
        }
        .view-action {
            background-image: url('~@/assets/images/icon-graph.svg');
        }
        .share-action {
            background-image: url('~@/assets/images/icon-share.svg');
        }
        .edit-action {
            background-image: url('~@/assets/images/icon-edit.svg');
        }
        .delete-action {
            background-image: url('~@/assets/images/icon-delete.svg');
        }
    }
}
.table-header {
    margin-bottom: 20px;
}
</style>
