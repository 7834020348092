<template>
    <div>
        <slot />
    </div>
</template>

<script>

export default {
    name: 'FormBuilderLayout',
    data() {
        return {
            cssAdded: false,
            headEl: null,
            linkEl: null,
            linkEl2: null
        }
    },
    mounted() {
        if (this.cssAdded) {
            return;
        }
        var head  = document.getElementsByTagName('head')[0];
        var link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = process.env.VUE_APP_STATIC_RESOURCES_ROOT + 'css/webform.css';
        link.media = 'all';

        var link2  = document.createElement('link');
        link2.rel  = 'stylesheet';
        link2.type = 'text/css';
        link2.href = process.env.VUE_APP_STATIC_RESOURCES_ROOT + 'css/pdfform.css';
        link2.media = 'all';

        this.headEl = head
        this.linkEl = link
        this.linkEl2 = link2
        this.cssAdded = true

        head.appendChild(link);
        head.appendChild(link2);
    },
    unmounted() {
        //console.log('unmounted')
        this.headEl.removeChild(this.linkEl)
        this.headEl.removeChild(this.linkEl2)
        this.cssAdded = false;
    }
}
</script>

<style lang="scss">
@import '@/assets/style/form-builder.scss';
</style>
