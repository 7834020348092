<template>
    <div class="display-property-container">
        <VisibilityProperty :widget="widget" :property="property" :value="value"
                class="property-component"
                @updateVisibility="updateVisibility" />
    </div>
</template>

<script>
import VisibilityProperty from "./display/VisibilityProperty.vue";

export default {
    name: 'DisplayProperty',
    props: ["property", "value", "widget"],
    components: {
        VisibilityProperty
    },
    data() {
        return {

        }
    },
    methods: {
        updateVisibility(visibilityProperty, visibilityValue) {
            var showInWebView, showInPdfView;

            showInWebView = visibilityValue == 'both' || visibilityValue == 'web'
            showInPdfView = visibilityValue == 'both' || visibilityValue == 'pdf'

            if (visibilityValue == 'web') {
                this.$emit('setActiveView', 'WEB_VIEW')
            }
            else if (visibilityValue == 'pdf') {
                this.$emit('setActiveView', 'PDF_VIEW')
            }

            if (this.widget.type === 'content') {
                this.$emit('updateWidgetProperty', 'display', 'showInWebView', showInWebView);
                this.$emit('updateWidgetProperty', 'display', 'showInPdfView', showInPdfView);
            }
            else if (this.widget.type === 'input' || this.widget.type === 'fields_group') {
                this.$emit('updateWidgetProperty', 'display', 'showInPdfView', showInPdfView);
            }
            //this.$emit('updateWidgetProperty', 'display', styleProperty, styleValue);
        }
    }
}
</script>
