<template>
    <div class="fill-property" v-if="showFillerProperty">
        <label for="filler_value">{{ $t('form_builder.filler_label') }}</label>
        <select v-model="fillerValue" name="filler_value">
            <option value="0" v-if="widget.widget !== 'Signature' && widget.widget !== 'ImageUpload' && widget.widget !== 'PdfUpload'">{{ $t('form_builder.filler_value_0') }}</option>
            <option value="1" v-if="widget.widget !== 'Signature' && widget.widget !== 'ImageUpload' && widget.widget !== 'PdfUpload'">{{ $t('form_builder.filler_value_1') }}</option>
            <option value="2">{{ $t('form_builder.filler_value_2') }}</option>
        </select>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'FillerProperty',
    props: ["property", "value", "widget"],
    data() {
        return {
            fillerValue: this.value,
            showFillerProperty: false
        }
    },
    computed: {
        ...mapStores(useFormStore)
    },
    created() {
        this.showFillerProperty = this.formStore.formData.type === 'TWO_STEP'
    },
    watch: {
        fillerValue(newValue) {
            this.$emit('updateProperty', this.property, newValue);
        }
    }
}
</script>

<style>
</style>
