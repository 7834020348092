import widgetsUtility from '@/widgetsUtility'

const PagebreakWidget = {
    name: "PagebreakWidget",
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Pagebreak')
};

const PagebreakWidgetPdf = {
    name: "PagebreakWidgetPdf",
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Pagebreak')
};

export { PagebreakWidget, PagebreakWidgetPdf };
