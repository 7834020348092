import TheIndex from '@/views/admin/TheIndex.vue';
import UsersIndex from '@/views/admin/UsersIndex.vue';

const routes = [
    {
        path: '/admin',
		name: 'TheIndex',
		component: TheIndex,
        meta: { layout: 'AdminLayout' }
    },
    {
        path: '/admin/users',
		name: 'UsersIndex',
		component: UsersIndex,
        meta: { layout: 'AdminLayout' }
    }
];

export default routes
