import widgetsUtility from '@/widgetsUtility'

const ImageUploadWidget = {
    name: 'ImageUploadWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('ImageUpload')
};

const ImageUploadWidgetPdf = {
    name: 'ImageUploadWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('ImageUpload')
};

export { ImageUploadWidget, ImageUploadWidgetPdf };
