import widgetsUtility from '@/widgetsUtility'

const HeadingWidget = {
    name: 'HeadingWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Heading')
};

const HeadingWidgetPdf = {
    name: 'HeadingWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Heading')
};

export { HeadingWidget, HeadingWidgetPdf };
