<template>
    <div class="pdf-style-property-container">

        <CollapseSection :isOpen="isOpen"
                @collapseOpen="collapseOpen"
                @collapseClose="collapseClose">

            <template v-slot:title>
                <h3 class="form-wrapper-title">{{ $t('form_builder.pdf_style_section_title') }}</h3>
            </template>

            <template v-slot:body>
                <div class="form-wrapper">
                    <template v-for="property in propertyOrder">
                        <div v-if="hasAnyOfProperties(property)" class="form-row" :key="property.toString()">
                            <template v-for="item in property">
                                <component v-if="styleProperty.hasOwnProperty(item)" :key="item + '_widget_' + keyHash"
                                    :is="translateToCamelCase(item)+'Property'"
                                    :property="item"
                                    :value="styleProperty[item]"
                                     @updateStyle="updateStyleProperty"
                                     class="property-component" />
                            </template>
                        </div>
                    </template>
                </div>
            </template>

        </CollapseSection>
    </div>
</template>

<script>
import FontFamilyProperty from "./style/FontFamilyProperty.vue";
import FontWeightProperty from "./style/FontWeightProperty.vue";
import FontStylingProperty from "./style/FontStylingProperty.vue";
import FontSizeProperty from "./style/FontSizeProperty.vue";
import TextAlignmentProperty from "./style/TextAlignmentProperty.vue";
import ColorProperty from "./style/ColorProperty.vue";

/*
import BackgroundColorProperty from "./style/BackgroundColorProperty.vue";

import BorderWidthProperty from "./style/BorderWidthProperty.vue";
import BorderStyleProperty from "./style/BorderStyleProperty.vue";
import BorderRadiusProperty from "./style/BorderRadiusProperty.vue";
import BorderColorProperty from "./style/BorderColorProperty.vue";

import PaddingProperty from "./style/PaddingProperty.vue";
import MarginProperty from "./style/MarginProperty.vue";
*/
import CollapseSection from "../global/CollapseSection.vue"

export default {
    name: 'PdfStyleProperty',
    props: ["property", "value", "widget", "isOpen"],
    emits: ['updateWidgetProperty', 'collapseOpen', 'collapseClose'],
    components: {
        FontFamilyProperty,
        FontWeightProperty,
        FontStylingProperty,
        FontSizeProperty,
        TextAlignmentProperty,
        ColorProperty,
        /*
        BackgroundColorProperty,
        BorderWidthProperty,
        BorderStyleProperty,
        BorderRadiusProperty,
        BorderColorProperty,
        PaddingProperty,
        MarginProperty,
        */
        CollapseSection
    },
    data() {
        return {
            propertyOrder: [
                [
                    'font-family',
                ],
                [
                    'font-weight',
                    'color'
                ],
                [
                    'text-alignment',
                    'font-styling',
                    'font-size'
                ]
                /*
                'font-styling',
                'text-alignment',
                'color',
                'background-color',
                'border-width',
                'border-style',
                'border-color',
                'border-radius',
                'padding',
                'margin'
                */
            ],
            keyHash: '',
            styleProperty: this.value ? JSON.parse(JSON.stringify(this.value)) : ''
        }
    },
    created() {
        for (var key in this.styleProperty) {
            if (key == 'font-style' || key == 'text-decoration' ||
                    key == 'font-weight') {

                this.styleProperty['font-styling'] = {
                    'font-style': this.styleProperty['font-style'] || null,
                    'text-decoration': this.styleProperty['text-decoration'] || null,
                    'font-weight': this.styleProperty['font-weight'] || null
                }
                break;
            }
            else if (key == 'text-align' || key == 'direction') {
                this.styleProperty['text-alignment'] = {
                    'text-align': this.styleProperty['text-align'] || null,
                    'direction': this.styleProperty['direction'] || null
                }
            }
        }
    },
    methods: {
        hasAnyOfProperties(properties) {
            for (let i = 0; i < properties.length; i++) {
                if (this.styleProperty.hasOwnProperty(properties[i])) {
                    return true
                }
            }
            return false
        },
        updateStyleProperty(styleProperty, styleValue) {
            //var newStyleObject = JSON.parse(JSON.stringify(this.value));
            //newStyleObject[styleProperty] = styleValue;
            //console.log(newStyleObject);
            this.$emit('updateWidgetProperty', 'pdf_style', styleProperty, styleValue);
        },
        collapseOpen() {
            this.$emit('collapseOpen')
        },
        collapseClose() {
            this.$emit('collapseClose')
        }
    }
};
</script>

<style>
</style>
