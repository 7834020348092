<template>
    <div class="fill-property-container">
        <component v-for="(item, key) in value" :key="key" :is="translateToCamelCase(key)+'Property'"
            :widget="widget" :property="key"
            :value="item" @updateProperty="updateProperty" class="property-component form-group" />
    </div>
</template>

<script>
import FillerProperty from "./fill/FillerProperty.vue";
import RequiredProperty from "./fill/RequiredProperty.vue";

export default {
    name: 'FillProeprty',
    props: ["property", "value", "widget"],
    components: {
        FillerProperty,
        RequiredProperty
    },
    data: function() {
        return {

        }
    },
    methods: {
        updateProperty(property, value) {
            this.$emit('updateWidgetProperty', 'fill', property, value)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
