<template>
    <div class="dashboard-container">
        <div class="navbar">
            <router-link to="/forms"><img :src="require('../../assets/images/site-logo-white.svg')" alt="Tofsy" class="logo" /></router-link>
            <router-link to="/profile"><span class="user-name">{{ userName }}</span></router-link>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js'

export default {
    name: 'NavigationMenu',
    data() {
        return {
            //userName: ''
        }
    },
    computed: {
        userName() {
            return this.authStore.$state.user?.first_name
        },
        ...mapStores(useAuthStore)
    }
}
</script>

<style lang="scss" scoped>
.navbar {
	padding: 5px 20px;
	background-color: $navbar-background-color;
	display: flex;
	align-items: center;
	justify-content: space-between;
    height: $nav-height;
    box-sizing: border-box;
    width: 100%;

    .logo {
        padding: 10px;
    }

    a {
        text-decoration: none;
        text-transform: uppercase;
    }

    span.user-name {
		font-size: $font-size-small;
        font-weight: $font-weight-bold;
        position: relative;
        color: $white-color;
        padding: 12px 34px 12px 12px;

        &::before {
            position: absolute;
            content: '';
            background-image: url('~@/assets/images/icon-user.svg');
            width: 18px;
            height: 20px;
            right: 10px;
            top: 12px;
        }
	}
}
</style>
