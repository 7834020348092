<template>
    <div class="content-property-container">
        <component v-for="(item, key) in value" :key="key" :is="translateToCamelCase(key)+'Property'"
            :widget="widget" :property="key"
            :value="item" @updateContent="updateContentProperty" class="property-component form-group" />
    </div>
</template>

<script>
import HtmlProperty from "./content/HtmlProperty.vue";
import TextProperty from "./content/TextProperty.vue";
import UrlProperty from "./content/UrlProperty.vue";

import HeadingLevelProperty from "./content/HeadingLevelProperty.vue";
//import FieldsProperty from "./content/FieldsProperty.vue";

import EditableProperty from "./content/EditableProperty.vue";

import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'ContentProperty',
    props: ["widget", "property", "value"],
    components: {
        HtmlProperty,
        TextProperty,
        UrlProperty,
        HeadingLevelProperty,
        //FieldsProperty,
        EditableProperty
    },
    data() {
        return {
            showFillerComponent: false
        }
    },
    computed: {
        isTwoStep() {
            return this.formStore.$state.formData.type == 'TWO_STEP'
        },
        ...mapStores(useFormStore)
    },
    methods: {
        updateContentProperty(contentProperty, contentValue) {
            this.$emit('updateWidgetProperty', 'content', contentProperty, contentValue)
            /*
            var newContentObject = JSON.parse(JSON.stringify(this.value));
            newContentObject[contentProperty] = contentValue;
            this.$emit('updateWidget', this.property, newContentObject);
            */
        }
    }
};
</script>

<style>
</style>
