<template>
    <div>
        <input type="checkbox" v-model="editableValue" />
        {{ $t('form_builder.allow_editing_in_first_step') }}
    </div>
</template>

<script>
export default {
    name: 'EditableProperty',
    props: ["property", "value"],
    data: function() {
        return {
            editableValue: this.value
        }
    },
    watch: {
        editableValue(newValue) {
            this.$emit('updateContent', this.property, newValue);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
