<template>
    <div class="change-email">
        <!--h2 class="tab-heading">{{ $t('profile.change_email_title') }}</h2-->
        <form v-on:submit="updateEmail">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>{{ $t('profile.current_email') }}</label>
                        <input type="email" name="old_email" class="form-control" disabled :value="currentEmail" />
                    </div>
                    <!--
                    <div class="form-group">
                        <label>{{ $t('profile.new_email') }}</label>
                        <input type="email" name="new_email" class="form-control" required="required" v-model="newEmail" />
                    </div>
                    <div class="form-group mb-4">
                        <label>{{ $t('profile.current_password') }}</label>
                        <input type="password" name="password" class="form-control" required="required" v-model="currentPassword" />
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary">{{ $t('profile.email_update_button') }}</button>
                    </div>
                    -->
                </div>
            </div>
        </form>

        <OverlayAlert class="files-modal" v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="modalOpen = false">
        </OverlayAlert>
    </div>
</template>

<script>
import OverlayAlert from '@/components/global/OverlayAlert.vue'

const profileEndpoint = process.env.VUE_APP_API_BASE + '/profile';

export default {
    name: 'ChangeEmail',
    props: ['user'],
    emits: ['userInfoUpdated'],
    components: {
        OverlayAlert
    },
    data() {
        return {
            currentPassword: '',
            newEmail: '',
            modalOpen: false,
            alertMsg: ''
        }
    },
    computed: {
        currentEmail() {
            return this.user.email
        },
        formData() {
            return {
                'email': this.newEmail,
                'password': this.currentPassword
            }
        }
    },
    methods: {
        updateEmail(e) {
            /*
            e.preventDefault()
            if (this.currentEmail == this.newEmail) {
                //alert(this.$t('profile.same_email_change_error'))
                this.alertMsg = this.$t('profile.same_email_change_error')
                this.modalOpen = true
                return
            }
            this.axios.post(profileEndpoint + '/update-email', this.formData)
                .then(response => {
                    //console.log(response.data)
                    if (response.data['result'] == 'success') {
                        //alert(this.$t('profile.email_updated_successfully'))
                        this.alertMsg = this.$t('profile.email_updated_successfully')
                        this.modalOpen = true

                        this.$emit('userInfoUpdated', {'email': this.newEmail})
                        this.newEmail = ''
                        this.currentPassword = ''
                    }
                    else if (response.data['result'] == 'error') {
                        //alert(response.data['message'])
                        this.alertMsg = this.$t('profile.' + response.data['message'])
                        this.modalOpen = true
                    }
                }).catch(error => {
                    alert('Error occurred -- #8100')
                    console.log(error)
                })
            */
        }
    }
}
</script>

<style lang="scss" scoped>
.form-control {
    max-width: 400px;
}
</style>
