import { useAuthStore } from '@/stores/auth.js';
import axios from 'axios'
import router from '../router'

export const handleUnauthorizedResponse = async function(error) {
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401) {
		if (!originalConfig._retry) {
	        originalConfig._retry = true;
			const errorMessage = error.response.data?.message
			if (errorMessage == 'invalid_token') {
                console.log('invalid token')
				return router.push("/auth/login")
			}
			else if (errorMessage == 'expired_token') {
				const authStore = useAuthStore();
                if (authStore.refresh_token) {
                    const myResult = await authStore.refreshToken()
                    if (myResult) {
                        originalConfig.headers['Authorization'] = 'Bearer ' + authStore.token
                        return axios(originalConfig)
                    }
                    else {
                        return router.push("/auth/login")
                    }
                }
			}
            else if (errorMessage == 'expired_refresh_token') {
                return router.push("/auth/login")
            }
		}
		else {
			return router.push("/auth/login")
		}
      }
	  else if (error.response.status === 403) {
		  alert('Unauthorized Access')
		  return Promise.reject(error)
		  //return router.push("/auth/login")
	  }
      else {
        return Promise.reject(error)
      }
    }
    return Promise.reject(error);
}
