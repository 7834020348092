<template>
    <div class="editor-preview">
        <div class="editor-preview__container">

            <PreviewControls />

            <div class="web-preview-holder" v-if="formStore.activePreviewView == 'WEB_VIEW'">
                <WebPreview :formData="formData"
                        @widgetDragStart="widgetDragStart"
                        @widgetDragOver="widgetDragOver"
                        @widgetDragLeave="widgetDragLeave"
                        @widgetDropOn="widgetDropOn"
                        @editWidget="editWidget"
                        @cloneWidget="cloneWidget"
                        @removeWidget="removeWidget"
                        @moveWidgetUp="moveWidgetUp"
                        @moveWidgetDown="moveWidgetDown" />
            </div>

            <div class="pdf-preview-holder" v-if="formStore.activePreviewView == 'PDF_VIEW'">
                <PdfPreview :formData="formData"
                        @widgetDragStart="widgetDragStart"
                        @widgetDragOver="widgetDragOver"
                        @widgetDragLeave="widgetDragLeave"
                        @widgetDropOn="widgetDropOn"
                        @editWidget="editWidget"
                        @cloneWidget="cloneWidget"
                        @removeWidget="removeWidget"
                        @moveWidgetUp="moveWidgetUp"
                        @moveWidgetDown="moveWidgetDown" />
            </div>

        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

import PreviewControls from "@/components/form-builder/preview/PreviewControls.vue";
import WebPreview from "@/components/form-builder/preview/WebPreview.vue";
import PdfPreview from "@/components/form-builder/preview/PdfPreview.vue";

export default {
    name: 'ThePreview',
    props: ['formData'],
    computed: {
        ...mapStores(useFormStore)
    },
    components: {
        PreviewControls,
        WebPreview,
        PdfPreview
    },
    methods: {
        editWidget(index) {
            this.formStore.editWidget(index)
        },
        cloneWidget(index) {
            this.formStore.cloneWidget(index)
        },
        removeWidget(index) {
            if (confirm(this.$t('form_builder.delete_widget_confirmation_text'))) {
                this.formStore.removeWidget(index)
            }
        },
        moveWidgetUp(index) {
            this.formStore.moveWidget(index, index-1)
        },
        moveWidgetDown(index) {
            this.formStore.moveWidget(index, index+1)
        },
        widgetDragStart(e, index) {
            var draggedWidget = this.formStore.$state.formData?.form_schema[index];
            this.activeWidget = draggedWidget;
            e.dataTransfer.setData('widget_position', index);
        },
        widgetDragOver(e, index) {

            if (this.activeWidget && this.activeWidget == this.formStore.$state.formData?.form_schema[index]) {
                //dragging over same widget
                return;
            }

            var myElement = e.target;
            //console.log(myElement);
            myElement = this.findWidgetComponentInNodeTree(myElement);
            if (myElement !== null) {
                if (this.isEventOnTopHalfOfElement(e, myElement)) {
                    myElement.classList.remove('drop-zone-bottom');
                    myElement.classList.add('drop-zone-top');
                }
                else {
                    myElement.classList.remove('drop-zone-top');
                    myElement.classList.add('drop-zone-bottom');
                }
            }
            else {
                console.log('Error #11002')
            }
        },
        widgetDragLeave(e, index) {
            var myElement = e.target;
            myElement = this.findWidgetComponentInNodeTree(myElement);
            if (myElement !== null) {
                myElement.classList.remove('drop-zone-top');
                myElement.classList.remove('drop-zone-bottom');
                //console.log('left?');
            }
            else {
                //console.log('whops');
            }

        },
        widgetDropOn(e, index) {
            const widgetPosition = e.dataTransfer.getData('widget_position')
            const widget = e.dataTransfer.getData('widget');

            var myElement = e.target;
            myElement = this.findWidgetComponentInNodeTree(myElement);
            if (myElement !== null) {

                if (widgetPosition) {
                    if (this.isEventOnTopHalfOfElement(e, myElement) && widgetPosition < index) {
                        index--
                    }
                    else if (!this.isEventOnTopHalfOfElement(e, myElement) && widgetPosition > index) {
                        index++
                    }
                    this.formStore.moveWidget(widgetPosition, index);
                }
                else if (widget) {
                    if (!this.isEventOnTopHalfOfElement(e, myElement)) {
                        index++
                    }
                    this.formStore.addWidget(widget, index);
                }

                myElement.classList.remove('drop-zone-top');
                myElement.classList.remove('drop-zone-bottom');
            }
        },
        findWidgetComponentInNodeTree(el) {
            while (el) {
                if (el.classList.contains('widget-component') ||
                        el.classList.contains('pdf-widget-component')) {
                    return el;
                }
                el = el.parentElement;
            }
            return null
        },
        isEventOnTopHalfOfElement(e, el) {
            const rect = el.getBoundingClientRect();
            //const relativeX = e.clientX - rect.left;
            const relativeY = e.clientY - rect.top;
            const midPoint = rect.height / 2;

            if (relativeY < midPoint) {
                return true;
            }
            return false;
        }
    }
}
</script>

<style lang="scss">
.widget-component {
    &.drop-zone-top {
        border: 1px solid $hover-effects-color !important;
        border-top: 15px solid $hover-effects-color !important;
        border-radius: 0;
    }
    &.drop-zone-bottom {
        border: 1px solid $hover-effects-color !important;
        border-bottom: 15px solid $hover-effects-color !important;
        border-radius: 0;
    }
}


.editor-preview {
    &__container {
        width: 880px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: 100%;

        .web-preview-holder, .pdf-preview-holder {
            padding-left: 7px;
            overflow-y: auto;
            border-radius: 10px 10px 0 0;
        }

        .web-preview, .pdf-preview {
            flex: auto;
            direction: ltr;
            height: auto;
            min-height: calc(100vh - $nav-height - 160px);
        }
    }
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: $page-background-color;
}
::-webkit-scrollbar-thumb {
  background: $brand-color;
}
</style>
