import widgetsUtility from '@/widgetsUtility'

const RadiobuttonWidget = {
    name: "RadiobuttonWidget",
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Radiobutton')
};

const RadiobuttonWidgetPdf = {
    name: "RadiobuttonWidgetPdf",
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Radiobutton')
};

export { RadiobuttonWidget, RadiobuttonWidgetPdf };
