<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import AuthLayout from "@/layouts/AuthLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import FormBuilderLayout from "@/layouts/FormBuilderLayout";
import AdminLayout from "@/layouts/AdminLayout";

export default {
  components: {
    DefaultLayout,
    AuthLayout,
    DashboardLayout,
    FormBuilderLayout,
    AdminLayout
  },
  data() {
    return {
      layout: null,
    };
  },
  watch: {
    $route(to) {
      // set layout by route meta
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "DefaultLayout" // this is default layout if route meta is not set
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
