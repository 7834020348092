<template>
    <div>
        <slot />
    </div>
</template>

<script>

export default {
    name: 'DefaultLayout'/*,
    data() {
        return {
            cssAdded: false,
            headEl: null,
            linkEl: null
        }
    },
    mounted() {
        if (this.cssAdded) {
            return;
        }
        var head  = document.getElementsByTagName('head')[0];
        var link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.media = 'all';

        this.headEl = head
        this.linkEl = link
        this.cssAdded = true

        head.appendChild(link);
    },
    unmounted() {
        //console.log('unmounted')
        this.headEl.removeChild(this.linkEl)
        this.cssAdded = false;
    }
    */
}
</script>

<style lang="scss">
</style>
