<template>
    <div>
        <NavigationMenu />
        <slot />
    </div>
</template>

<script>

import NavigationMenu from "@/components/dashboard/NavigationMenu";

export default {
    name: 'DashboardLayout',
    components: {
        NavigationMenu
    }
}
</script>

<style lang="scss">
@import "@/assets/style/normalize.scss";
@import "@/assets/style/common.scss";
@import '@/assets/style/dashboard.scss';
</style>
