import { createI18n } from 'vue-i18n'

import heLocale from './assets/locales/he.json'


/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    const messages = {
        'he': heLocale
    }
    return messages
}

export default createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'he',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'he',
    messages: loadLocaleMessages()
})
