<template>
    <div class="settings-view">
        <div class="inner-wrapper">
            <h3 class="sidebar-title">{{ $t('form_builder.configurations_settings_view_title') }}</h3>
            <p class="sidebar-subtitle">
                {{ $t('form_builder.configurations_settings_view_subtitle') }}
            </p>
            <div class="form-wrapper">
                <div class="form-group">
                    <label for="form-name-input">{{ $t('form_builder.configurations_form_name') }}: *</label>
                    <input type="text" name="name" id="form-name-input" v-model="formNameValue"
                            v-on:blur="saveFormName" />
                </div>

                <div class="form-group">
                    <label for="form-language-input">{{ $t('form_builder.configurations_form_language') }}: *</label>
                    <select id="form-language-input" v-model="formLanguageValue">
                        <option value="en">{{ $t('form_builder.en') }}</option>
                        <option value="he">{{ $t('form_builder.he') }}</option>
                        <option value="ar">{{ $t('form_builder.ar') }}</option>
                        <option value="ru">{{ $t('form_builder.ru') }}</option>
                    </select>
                </div>


                <div class="form-group">
                    <label for="form-direction-input">{{ $t('form_builder.configurations_form_direction') }}: *</label>
                    <select id="form-direction-input" v-model="formDirectionValue">
                        <option value="RTL">RTL</option>
                        <option value="LTR">LTR</option>
                    </select>
                </div>

                <!--
                <div class="form-group">
                    <label for="form-type-input">{{ $t('form_builder.configurations_form_type') }}: *</label>
                    <input type="text" name="type" id="form-type-input" :value="formType" disabled />
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'SettingsView',
    data() {
        return {
            formNameValue: '',
            formLanguageValue: '',
            formDirectionValue: ''
        }
    },
    computed: {
        formName() {
            return this.formStore.$state.formData?.name
        },
        formType() {
            return this.$t('form_builder.' + this.formStore.$state.formData?.type)
        },
        ...mapStores(useFormStore)
    },
    mounted() {
        this.formNameValue = this.formStore.$state.formData?.name
        this.formLanguageValue = this.formStore.$state.formData?.language
        this.formDirectionValue = this.formStore.$state.formData?.direction
    },
    watch: {
        formName() {
            this.formNameValue = this.formStore.$state.formData?.name
        },
        formLanguageValue(newValue, oldValue) {
            if (oldValue == '' || newValue == '') return
            this.formStore.updateConfiguration('language', newValue)
        },
        formDirectionValue(newValue, oldValue) {
            if (oldValue == '' || newValue == '') return
            this.formStore.updateConfiguration('direction', newValue)
        }
    },
    methods: {
        saveFormName() {
            this.formStore.updateFormName(this.formNameValue)
        }
    }
}
</script>

<style lang="scss" scoped>
.settings-view {

    padding: 20px 10px;
    box-sizing: border-box;
    height: 100%;
    display: flex;

    .inner-wrapper {
        flex-grow: 1;
        overflow-y: auto;
        direction: ltr;
        padding: 0 10px;

        & > * {
            direction: rtl;
        }
    }
}
</style>
