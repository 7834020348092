<template>
    <div class="text-property">
        <label for="text">{{ $t('form_builder.text') }}</label>
        <input type="text" v-model="textValue" />
    </div>
</template>

<script>
export default {
    name: 'TextProperty',
    props: ["property", "value"],
    data() {
        return {
            textValue: this.value
        }
    },
    watch: {
        textValue(newValue) {
            this.$emit('updateContent', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
