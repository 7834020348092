<template>
    <div>
        <div class="share-block-heading">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1466_2806)">
                <path d="M11.0833 0.583008H2.91667C2.1434 0.583934 1.40208 0.891522 0.855295 1.4383C0.308514 1.98508 0.00092625 2.72641 0 3.49967L0 10.4997C0.00092625 11.2729 0.308514 12.0143 0.855295 12.561C1.40208 13.1078 2.1434 13.4154 2.91667 13.4163H11.0833C11.8566 13.4154 12.5979 13.1078 13.1447 12.561C13.6915 12.0143 13.9991 11.2729 14 10.4997V3.49967C13.9991 2.72641 13.6915 1.98508 13.1447 1.4383C12.5979 0.891522 11.8566 0.583934 11.0833 0.583008ZM2.91667 1.74967H11.0833C11.4326 1.75036 11.7737 1.85556 12.0627 2.05173C12.3517 2.2479 12.5754 2.52606 12.705 2.85042L8.23783 7.31817C7.90908 7.64561 7.46399 7.82945 7 7.82945C6.53601 7.82945 6.09092 7.64561 5.76217 7.31817L1.295 2.85042C1.42459 2.52606 1.64827 2.2479 1.93728 2.05173C2.22628 1.85556 2.56738 1.75036 2.91667 1.74967ZM11.0833 12.2497H2.91667C2.45254 12.2497 2.00742 12.0653 1.67923 11.7371C1.35104 11.4089 1.16667 10.9638 1.16667 10.4997V4.37467L4.93733 8.14301C5.48487 8.68916 6.22665 8.99586 7 8.99586C7.77335 8.99586 8.51513 8.68916 9.06267 8.14301L12.8333 4.37467V10.4997C12.8333 10.9638 12.649 11.4089 12.3208 11.7371C11.9926 12.0653 11.5475 12.2497 11.0833 12.2497Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_1466_2806">
                <rect width="14" height="14" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <h3>{{ $t('forms_share.share_by_email') }}</h3>
        </div>
        <div class="share-block-content">
            <div class="email-form">
                <form method="post" action="javascript:;" v-on:submit="emailFormSubmitted()">
                    <div class="form-group">
                        <p>{{ $t('forms_share.split_emails_by_comma') }}</p>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('forms_share.email_recipients_label') }}</label>
                        <input type="text" v-model="recipientEmails"
                            :class="emailHasError && 'has-error'"
                            :placeholder="$t('forms_share.email_recipients_label')" />
                    </div>
                    <!--
                    <div class="form-group">
                        <label>{{ $t('forms_share.email_subject_label') }}</label>
                        <input type="text" v-model="recipientEmails" :placeholder="$t('forms_share.email_subject_label')" />
                    </div>
                    -->
                    <div class="form-group">
                        <label>{{ $t('forms_share.email_message_label') }}</label>
                        <textarea v-model="emailMessage" :placeholder="$t('forms_share.email_message_label')"></textarea>
                    </div>
                    <div class="form-group">
                        <button type="submit" :disabled="buttonDisabled">{{ $t('forms_share.email_form_submit') }}</button>
                    </div>
                </form>
            </div>
        </div>

        <OverlayAlert v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="modalOpen = false">
        </OverlayAlert>
    </div>
</template>

<script>
import OverlayAlert from '@/components/global/OverlayAlert.vue'

import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js';

export default {
    name: 'ShareByEmail',
    props: ['shareUrl'],
    components: {
        OverlayAlert
    },
    data: function() {
        return {
            recipientEmails: '',
            emailMessage: '',
            emailHasError: false,

            modalOpen: false,
            alertMsg: '',
            buttonDisabled: false,

            emailRegex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        }
    },
    computed: {
        ...mapStores(useFormStore)
    },
    watch: {
        recipientEmails() {
            this.emailHasError = false
        }
    },
    methods: {
        getEmailsArrayFromInput() {
            const stringValue = this.recipientEmails
            const arrayValue = stringValue.replace(/\s/g,'').split(",");

            const finalArray = []

            if (arrayValue.length == 0 || arrayValue.length == 1 && arrayValue[0] == '') {
                this.emailHasError = true
                return false
            }

            for (let i = 0; i < arrayValue.length; i++) {
                let email = arrayValue[i]
                if (email == "" || !this.emailRegex.test(email)) {
                     alert('Invalid email: ' + email)
                     this.emailHasError = true
                     return false
                }
                finalArray.push(email)
            }
            return finalArray
        },
        emailFormSubmitted() {
            const emailsArray = this.getEmailsArrayFromInput()
            if (emailsArray !== false && emailsArray.length > 0) {
                //submit to backend
                //@todo - disable button
                this.buttonDisabled = true
                this.formStore.shareByEmail(this.$route.params.id, emailsArray, this.emailMessage)
                        .then(response => {
                            this.buttonDisabled = false
                            if (response.data?.result == 'success') {
                                this.alertMsg = this.$t('forms_share.share_by_email_success_message')
                                this.modalOpen = true
                                this.emailMessage = ''
                                this.recipientEmails = ''
                            }
                            else {
                                this.alertMsg = this.$t('forms_share.share_by_email_error_message')
                                if (response.data?.error_code) {
                                    this.alertMsg += ' #' + response.data.error_code
                                }
                                this.modalOpen = true
                            }
                        })
                        .catch(error => {
                            this.buttonDisabled = false
                            console.log(error)
                            alert('Error sending email')
                        })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.share-block-heading {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    gap: 5px;

    h3 {
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: $line-height;
        margin: 0;
        padding: 0;
    }
    margin-bottom: 13px;
}

.email-form {
    p {
        margin-top: 0;
    }
    label {
        display: none;
    }
    .form-group {
        margin-bottom: 10px;
    }
    button {
        color: $text-color;
    }
    textarea {
        max-width: 100%;
        min-width: 100%;
        height: auto;
        max-height: 300px;
    }
}
</style>
