import widgetsUtility from '@/widgetsUtility'

const ButtonWidget = {
    name: 'ButtonWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Button')
};

const ButtonWidgetPdf = {
    name: 'ButtonWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Button')
};

export { ButtonWidget, ButtonWidgetPdf };
