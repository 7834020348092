<template>
    <div class="web-preview-outer">
        <div class="web-preview" :style="webPreviewStyle">

            <div  class="web-preview__widget" v-for="(item, index) of formData.form_schema" :key="item">

                <component :is="item.widget+'Widget'" :widget="item" v-show="item.properties?.display?.showInWebView !== false"
                        class="web-preview__widget-component widget-component"
                        :class="formStore.activeWidget == item && 'active'"
                        draggable="true"
                        @dragstart.self="$emit('widgetDragStart', $event, index)"
                        v-on:click="editWidget(index)"

                        @dragover.prevent="$emit('widgetDragOver', $event, index)"
                        @dragleave.prevent.self="$emit('widgetDragLeave', $event, index)"
                        @drop.prevent="$emit('widgetDropOn', $event, index)">
                </component>

                <div class="widget-controls" v-if="item.properties?.display?.showInWebView !== false &&
                        formStore.$state.activeWidgetIndex == index">
                    <a v-on:click="$emit('cloneWidget', index)"><i class="duplicate-widget-icon"></i></a>
                    <a v-on:click="$emit('removeWidget', index)"><i class="delete-widget-icon"></i></a>
                </div>

                <div class="sort-controls" v-if="item.properties?.display?.showInWebView !== false &&
                        formStore.$state.activeWidgetIndex == index">
                    <a v-on:click="$emit('moveWidgetUp', index)"><i class="sort-up-icon"></i></a>
                    <a v-on:click="$emit('moveWidgetDown', index)"><i class="sort-down-icon"></i></a>
                </div>

            </div>
            
            <DragPlaceholder />

            <!--WidgetDropZone :zoneId="formData.form_schema?.length" @addWidget="addWidget" @moveWidget="moveWidget" /-->
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

import DragPlaceholder from "@/components/form-builder/preview/DragPlaceholder.vue"

//widget imports
import {ContentWidget} from "@/components/form-builder/widgets/ContentWidget.js";
import {HeadingWidget} from "@/components/form-builder/widgets/HeadingWidget.js";
import {ImageWidget} from "@/components/form-builder/widgets/ImageWidget.js";
import {ButtonWidget} from "@/components/form-builder/widgets/ButtonWidget.js";
import {SeparatorWidget} from "@/components/form-builder/widgets/SeparatorWidget.js";
import {PagebreakWidget} from "@/components/form-builder/widgets/PagebreakWidget.js";

import {TextboxWidget} from "@/components/form-builder/widgets/TextboxWidget.js";
import {TextareaWidget} from "@/components/form-builder/widgets/TextareaWidget.js";
import {DateWidget} from "@/components/form-builder/widgets/DateWidget.js";
import {TimeWidget} from "@/components/form-builder/widgets/TimeWidget.js";
import {EmailWidget} from "@/components/form-builder/widgets/EmailWidget.js";
import {UrlWidget} from "@/components/form-builder/widgets/UrlWidget.js";
import {ColorWidget} from "@/components/form-builder/widgets/ColorWidget.js";
import {NumberWidget} from "@/components/form-builder/widgets/NumberWidget.js";

import {CheckboxWidget} from "@/components/form-builder/widgets/CheckboxWidget.js";
import {RadiobuttonWidget} from "@/components/form-builder/widgets/RadiobuttonWidget.js";
import {SelectWidget} from "@/components/form-builder/widgets/SelectWidget.js";

import {IdWidget} from "@/components/form-builder/widgets/IdWidget.js";
import {PhoneWidget} from "@/components/form-builder/widgets/PhoneWidget.js";

import {SignatureWidget} from "@/components/form-builder/widgets/SignatureWidget.js";
import {ImageUploadWidget} from "@/components/form-builder/widgets/ImageUploadWidget.js";
import {PdfUploadWidget} from "@/components/form-builder/widgets/PdfUploadWidget.js";

import {BankinfoWidget} from "@/components/form-builder/widgets/BankinfoWidget.js";
import {AddressinfoWidget} from "@/components/form-builder/widgets/AddressinfoWidget.js";
import {DaterangeWidget} from "@/components/form-builder/widgets/DaterangeWidget.js";

export default {
    name: 'WebPreview',
    components: {
        DragPlaceholder,

        ContentWidget,
        HeadingWidget,
        ImageWidget,
        ButtonWidget,
        SeparatorWidget,
        PagebreakWidget,

        TextboxWidget,
        TextareaWidget,
        DateWidget,
        TimeWidget,
        EmailWidget,
        UrlWidget,
        ColorWidget,
        NumberWidget,

        CheckboxWidget,
        RadiobuttonWidget,
        SelectWidget,

        IdWidget,
        PhoneWidget,

        SignatureWidget,
        ImageUploadWidget,
        PdfUploadWidget,

        BankinfoWidget,
        AddressinfoWidget,
        DaterangeWidget
    },
    emits: [
        'widgetDragStart',
        'widgetDragOver',
        'widgetDragLeave',
        'widgetDropOn',
        'editWidget',
        'cloneWidget',
        'removeWidget',
        'moveWidgetUp',
        'moveWidgetDown'
    ],
    computed: {
        previewWidth() {
            return this.formStore.$state.activeDeviceView === 'MOBILE' ? '420px' : '100%'
        },
        webPreviewStyle() {

            const isMobile = this.formStore.$state.activeDeviceView === 'MOBILE'
            const previewWidth = isMobile ? '420px' : '100%'
            const borderRadius = isMobile ? '0' : '10px 10px 0 0'

            return {
                'font-family': this.formStore.formData.font,
                'font-size': this.formStore.formData.font_size,
                'direction': this.formStore.formData.direction,
                'width':  previewWidth,
                'border-radius': borderRadius
            }

            /*
            return 'font-family: ' + this.formStore.formData.font +'; \
                font-size: ' + this.formStore.formData.font_size +'; \
                direction: ' + this.formStore.formData.direction + '; \
                width: ' + this.formStore.formData.previewWidth + ';'
            */
        },
        direction() {
            return this.formStore.formData.direction
        },
        formData() {
            return this.formStore.formData
        },
        activeWidgetIndex() {
            return this.formStore.activeWidgetIndex
        },
        ...mapStores(useFormStore)
    },
    methods: {
        editWidget(index) {
            this.formStore.editWidget(index)
        }
    }
}
</script>

<style lang="scss" scoped>
.web-preview-outer {
    background: #e7e7e7;
    border-radius: 10px 10px 0 0;
}

.web-preview {
    /*padding-right: 7px;*/

    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
    background: #ffffff;
    padding: 35px 45px;
    box-sizing: border-box;
    border-top: 1px solid transparent; /*to fix collapsing margins*/
    width: 100%;
    height: auto;

    h1, h2, h3, h4, h5, p {
        margin: 0 !important;
    }

    .wrapper {

    }

    &__widget {
        position: relative;

        &-component {
            border: 1px solid transparent;
            border-radius: $default-border-radius;
            box-sizing: border-box;
            position: relative;
            transition: margin 500ms;

            &:hover {
                cursor: pointer;
                border: 1px dotted $secondary-color;
            }

            &.active {
                cursor: pointer;
                border: 1px solid $secondary-color;
                margin-bottom: 50px !important;
            }
        }

        .widget-controls {
            position: absolute;
            bottom: -50px;
            left: 0px;
            z-index: 999;
            display: flex;
            gap: 12px;
            flex-flow: row;
            flex-direction: row;
            padding-right: 50px;
            padding-bottom: 10px;
            box-sizing: border-box;
            height: 40px;

            a {
                text-decoration: none;
                display: inline-block;
                width: 30px;
                height: 30px;
                padding: 3px;
                box-sizing: border-box;
                cursor: pointer;

                i {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &.duplicate-widget-icon {
                        background-image: url('~@/assets/images/duplicate-widget-icon.svg');
                    }
                    &.delete-widget-icon {
                        background-image: url('~@/assets/images/delete-widget-icon.svg');
                    }
                }
            }
        }

        .sort-controls {
            position: absolute;
            right: -25px;
            top: 50%;
            margin-top: -19px;
            display: flex;
            flex-direction: column;
            flex-flow: column;
            gap: 16px;
            align-content: flex-start;
            height: 38px;

            a {
                flex-grow: 0;
                display: flex;
                text-decoration: none;
                padding: 2px;
                width: 16px;
                height: 11px;
                box-sizing: border-box;
                cursor: pointer;

                i {
                    flex-grow: 0;
                    display: inline-block;
                    width: 12px;
                    height: 7px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &.sort-up-icon {
                        background-image: url('~@/assets/images/move-widget-up-icon.svg');
                    }
                    &.sort-down-icon {
                        background-image: url('~@/assets/images/move-widget-down-icon.svg');
                    }
                }
            }
        }
    }
}
</style>
