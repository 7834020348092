import widgetsUtility from '@/widgetsUtility'

const SeparatorWidget = {
    name: "SeparatorWidget",
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Separator')
};

const SeparatorWidgetPdf = {
    name: "SeparatorWidgetPdf",
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Separator')
};

export { SeparatorWidget, SeparatorWidgetPdf };
