import widgetsUtility from '@/widgetsUtility'

const ImageWidget = {
    name: 'ImageWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Image')
};

const ImageWidgetPdf = {
    name: 'ImageWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Image')
};

export { ImageWidget, ImageWidgetPdf };
