<template>
    <div id="profile-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <router-link to="/forms" class="back-link">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.19541 4.47119L9.72408 7.99986L6.19541 11.5285L7.13808 12.4712L10.6667 8.94252C10.9167 8.69249 11.0571 8.35341 11.0571 7.99986C11.0571 7.64631 10.9167 7.30723 10.6667 7.05719L7.13808 3.52852L6.19541 4.47119Z" fill="#4E2053"/>
                    </svg>
                    <span>{{ $t("forms_share.back_to_forms") }}</span>
                </router-link>

                <div class="page-heading">
                    <h1 class="page-title">{{ $t("profile.my_profile_title") }}</h1>
                </div>

                <div class="profile-nav" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <TabsNavigation class="tabs-navigation" :linksData="tabsData"
                            :activeLink="activeSection"
                            @linkClicked="linkClicked"/>
                </div>

                <div class="tab-content">
                    <div class="tab-content__wrapper">
                        <PersonalInformation v-if="activeSection == 'personal_info'" :user="user"
                                @userInfoUpdated="userInfoUpdated" />

                        <ChangeEmail v-if="activeSection == 'change_email'" role="tabpanel" :user="user"
                                @userInfoUpdated="userInfoUpdated" />

                        <ChangePassword v-if="activeSection == 'change_password'" role="tabpanel" :user="user" />
                        <SubscriptionPlan v-if="activeSection == 'subscription_plan'" role="tabpanel" :user="user" />
                    </div>
                </div>

                <a class="logout-link" href="javascript:;" v-on:click="logout">
                    <svg fill="#4e2053" height="14px" width="14px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        	 viewBox="0 0 490.3 490.3" xml:space="preserve">
                        <g>
                        	<g>
                        		<path d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3
                        			s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6
                        			c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1
                        			C27.9,58.95,0,86.75,0,121.05z"/>
                        		<path d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9
                        			c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63
                        			C380.6,325.15,380.6,332.95,385.4,337.65z"/>
                        	</g>
                        </g>
                    </svg>
                    <span>{{ $t("profile.logout") }}</span>
                </a>

            </section>
        </div>
    </div>
</template>

<script>
import TabsNavigation from "@/components/global/TabsNavigation.vue"

import PersonalInformation from "@/components/dashboard/profile/PersonalInformation.vue";
import ChangeEmail from "@/components/dashboard/profile/ChangeEmail.vue";
import ChangePassword from "@/components/dashboard/profile/ChangePassword.vue";
import SubscriptionPlan from "@/components/dashboard/profile/SubscriptionPlan.vue";

import { mapStores } from "pinia";
import { useAuthStore } from "@/stores/auth.js";

export default {
    name: "ProfileIndex",
    components: {
        TabsNavigation,

        PersonalInformation,
        ChangeEmail,
        ChangePassword,
        SubscriptionPlan,
    },
    data: function() {
        return {
            activeSection: "personal_info",
            tabsData: {
                'personal_info': this.$t("profile.personal_information_navigation_text"),
                'change_email': this.$t("profile.change_email_navigation_text"),
                'change_password': this.$t("profile.change_password_navigation_text"),
                'subscription_plan': this.$t("profile.subscription_plan_navigation_text")
            }
        };
    },
    computed: {
        user() {
            return this.authStore.$state.user
        },
        ...mapStores(useAuthStore),
    },
    watch: {
        activeSection(newValue) {
            this.$router.replace({
                path: '/profile',
                hash: '#' + newValue
            })
        }
    },
    mounted() {
        const currentUrl = window.location.href
        const hashSplitParts = currentUrl.split('#')
        if (hashSplitParts.length == 2) {
            const tabData = hashSplitParts[1]
            switch(tabData) {
                case('personal_info'):
                    this.activeSection = 'personal_info'
                    break
                case('change_email'):
                    this.activeSection = 'personal_info'
                    break
                case('change_password'):
                    this.activeSection = 'change_password'
                    break
                case('subscription_plan'):
                    this.activeSection = 'subscription_plan'
                    break
            }
        }
    },
    methods: {
        linkClicked(section) {
            this.activeSection = section
        },
        logout() {
            this.authStore.logout()
            this.$router.push('/auth/login')
        },
        userInfoUpdated(data = null) {
            this.authStore.refreshUserInfo(data)
        }
    }
};
</script>

<style lang="scss" scoped>
.tabs-navigation {
    font-size: $font-size-small;
    margin-bottom: 20px;
}

.tab-content {

    &__wrapper {
        display: flex;
        flex-flow: column;
        flex-direction: column;
        gap: 20px;

        & > * {
            background: $white-color;
            padding: 20px 20px 30px;
            border-radius: $default-border-radius;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    }
}

a.logout-link {
    font-size: $font-size-small;
    color: $secondary-color;
    line-height: 24px;
    text-decoration: none;
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    gap: 7px;
    margin-top: 20px;
    cursor: pointer;
}
</style>
