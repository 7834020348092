<template>
    <div class="device-control-component">

        <a href="javascript:;" @click="linkClicked('DESKTOP')" class="desktop-link"
                :class="[isWebView && activeLink === 'DESKTOP' && 'active',
                        !isWebView && 'disabled']"></a>

        <a href="javascript:;" @click="linkClicked('MOBILE')" class="mobile-link"
                :class="[isWebView && activeLink === 'MOBILE' && 'active',
                        !isWebView && 'disabled']"></a>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'DeviceControl',
    data: () => {
        return {

        }
    },
    computed: {
        isWebView() {
            return this.activeView === 'WEB_VIEW'
        },
        activeLink() {
            return this.formStore.$state.activeDeviceView
        },
        activeView() {
            return this.formStore.$state.activePreviewView
        },
        ...mapStores(useFormStore)
    },
    methods: {
        linkClicked(deviceType) {
            this.formStore.setActiveDeviceView(deviceType)
        }
    }
}
</script>

<style lang="scss" scoped>
.device-control-component {
    display: flex;
    gap: 10px;

    a {
        display: inline-block;
        text-decoration: none;
        background-color: transparent;
        border: 1px solid $brand-color;
        width: 40px;
        height: 40px;
        padding: 9px;
        box-sizing: border-box;
        border-radius: 5px;

        &:active, &:hover {
            text-decoration: none;
        }

        &::before {
            display: inline-block;
            content: '';
            width: 20px;
            height: 20px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &.active {
            background-color: $brand-color;
        }

        &.disabled {
            border: 1px solid #B2B2B2;
            opacity: 0.4;
            cursor: default;
        }

    }
    .mobile-link::before {
        background-image: url('~@/assets/images/mobile-icon.svg');
    }
    .desktop-link::before {
        background-image: url('~@/assets/images/desktop-icon.svg');
    }
}
</style>
