<template>
    <div id="the-index-page">
        <div class="container">
            <div class="row">
                <div class="page-heading">
                    <h1 class="page-title">{{ $t('admin.index_page_title') }}</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheIndex'
}
</script>

<style>
</style>
