import widgetsUtility from '@/widgetsUtility'

const TextboxWidget = {
    name: 'TextboxWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Textbox')
};

const TextboxWidgetPdf = {
    name: 'TextboxWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Textbox')
};

export { TextboxWidget, TextboxWidgetPdf };
