<template>
    <div class="image-property-container">
        <component v-for="(item, key) in value" :key="key" :is="translateToCamelCase(key)+'Property'"
            :widget="widget" :property="key"
            :value="item" @updateProperty="updateProperty" class="property-component form-group" />
    </div>
</template>

<script>
import ImageSourceProperty from "./image/ImageSourceProperty.vue";
import ImageWidthProperty from "./image/ImageWidthProperty.vue";

export default {
    name: 'ImageProperty',
    props: ["widget", "property", "value"],
    components: {
        ImageSourceProperty,
        ImageWidthProperty
    },
    data() {
        return {

        }
    },
    methods: {
        updateProperty(property, value) {
            this.$emit('updateWidgetProperty', 'image', property, value)
            /*
            var newContentObject = JSON.parse(JSON.stringify(this.value));
            newContentObject[contentProperty] = contentValue;
            this.$emit('updateWidget', this.property, newContentObject);
            */
        }
    }
};
</script>

<style>
</style>
