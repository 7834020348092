<template>
    <div class="preview-control">
        <TabsNavigation class="tabs-navigation" :linksData="activePreviewControlsData"
                :activeLink="activeLink"
                @linkClicked="linkClicked"/>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

import TabsNavigation from "@/components/global/TabsNavigation.vue"

export default {
    name: 'PreviewControl',
    components: {
        TabsNavigation
    },
    data: function() {
        return {
            activePreviewControlsData: {
                'WEB_VIEW': this.$t('form_builder.preview_controls_web_view'),
                'PDF_VIEW': this.$t('form_builder.preview_controls_pdf_view')
            }
        }
    },
    computed: {
        activeLink() {
            return this.formStore.activePreviewView
        },
        ...mapStores(useFormStore)
    },
    methods: {
        linkClicked(view) {
            this.formStore.setActivePreviewView(view)
        }
    }
}
</script>

<style lang="scss">
.preview-control {

    font-size: $font-size;

    .tabs-navigation > a {
        width: 170px;
        box-sizing: border-box;
        position: relative;

        &.active {
            overflow: visible;

            &::after {
                display: inline-block;
                content: '';
                position: absolute;
                width: 15px;
                height: 15px;
                padding: 0;
                margin: 0;
                right: 77px;
                bottom: -7px;
                background: $brand-color;
                transform: rotateY(0deg) rotate(45deg);

            }
        }
    }

}
</style>
