import { createWebHistory, createRouter } from 'vue-router'

import formRoutes from '@/routes/formRoutes.js'
import authRoutes from '@/routes/authRoutes.js'
import profileRoutes from '@/routes/profileRoutes.js'
import adminRoutes from '@/routes/adminRoutes.js'

var routes = []
routes.push(...formRoutes)
routes.push(...authRoutes)
routes.push(...profileRoutes)
routes.push(...adminRoutes)

routes.push({
	path: '/',
	redirect: '/forms'
})

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router
