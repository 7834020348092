/** Helper methods - mostly str manipulation **/
const strUtility = {
    methods: {
        //translates key to match component name (rom dash separated and Snake case)
        //Converts CSS syntax dash-separated to CamelCase
        translateToCamelCase(str) {
            //console.log(str)
            var newKey = str.split('')
            newKey[0] = newKey[0].toUpperCase()
            //console.log(newKey);
            for (var i=1; i < newKey.length-1; i++) {
                if (newKey[i] == '-' || newKey[i] == '_') {
                    newKey[i+1] = newKey[i+1].toUpperCase()
                    newKey.splice(i, 1)
                }
            }
            newKey = newKey.join('')
            //console.log(newKey);
            return newKey
        },

        //translates from both, CamelCase and dash-separated
        //to snake_case (underscore_separated)
        translateToSnakeCase(str) {
            var newKey = str.split('')
            newKey[0] = newKey[0].toLowerCase()
            for (var i=1; i < newKey.length-1; i++) {
                if (newKey[i] == '-' || newKey[i] == '_') {
                    newKey[i] = '_'
                }
                else if (newKey[i] !== newKey[i].toLowerCase()) {
                    newKey.splice(i, 0, newKey[i])
                    newKey[i] = '_'
                }
            }
            newKey = newKey.join('')
            //console.log(newKey);
            return newKey
        },


        //take Date string, and returns it in app convention format
        //DD.MM.YYYY | h:i
        formatDate(strDate) {
            if (strDate) {
                const hasTime = strDate.includes(':')

                const date = new Date(strDate)
                const datePart = ("0" + date.getDate()).slice(-2) + "." +
                        ("0" + (date.getMonth()+1)).slice(-2) + "." +
                        date.getFullYear();

                let timePart = ''
                if (hasTime) {
                    timePart = ("0" + date.getHours()).slice(-2)+":"+("0" + date.getMinutes()).slice(-2);
                }

                return hasTime ? datePart + " | " + timePart : datePart
            }
            return ''
        }
    }
}

export default strUtility;
