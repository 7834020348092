<template>
    <div>
        <div class="share-block-heading" v-if="hideTitle !== true">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_738_10647)">
                <path d="M8.07632 10.0723L6.17349 11.9751C5.62103 12.5128 4.87908 12.8113 4.10817 12.8062C3.33726 12.801 2.59938 12.4925 2.05417 11.9475C1.50896 11.4024 1.20028 10.6646 1.19489 9.89373C1.18951 9.12282 1.48787 8.38079 2.02541 7.82818L3.92824 5.9236C4.03762 5.81414 4.09904 5.66572 4.09898 5.51098C4.09893 5.35624 4.03741 5.20786 3.92795 5.09848C3.81849 4.9891 3.67007 4.92768 3.51533 4.92773C3.36058 4.92779 3.2122 4.98931 3.10282 5.09877L1.20057 7.00335C0.431898 7.77241 0.000214912 8.81532 0.000488411 9.90267C0.00076191 10.99 0.43297 12.0327 1.20203 12.8014C1.97109 13.5701 3.01401 14.0017 4.10135 14.0015C5.1887 14.0012 6.2314 13.569 7.00007 12.7999L8.90291 10.8971C9.00917 10.7871 9.06796 10.6397 9.06663 10.4868C9.0653 10.3338 9.00396 10.1875 8.8958 10.0794C8.78765 9.97121 8.64134 9.90986 8.48839 9.90853C8.33544 9.9072 8.18809 9.966 8.07807 10.0723H8.07632Z" fill="#313131"/>
                <path d="M12.8006 1.20226C12.421 0.820073 11.9694 0.51701 11.4718 0.310637C10.9743 0.104264 10.4407 -0.00131627 9.90203 1.43738e-05C9.36365 -0.0014158 8.83033 0.103893 8.33291 0.309853C7.83548 0.515812 7.38382 0.818335 7.00403 1.19993L5.09828 3.10335C4.98882 3.21273 4.9273 3.36111 4.92725 3.51585C4.92719 3.67059 4.98861 3.81902 5.09799 3.92847C5.20737 4.03793 5.35575 4.09945 5.51049 4.09951C5.66523 4.09956 5.81366 4.03814 5.92311 3.92876L7.8277 2.02593C8.09927 1.75273 8.42234 1.53611 8.7782 1.38862C9.13406 1.24112 9.51565 1.16569 9.90086 1.16668C10.4809 1.16687 11.0478 1.33902 11.5299 1.66135C12.0121 1.98368 12.3879 2.44173 12.6098 2.97759C12.8317 3.51346 12.8897 4.10308 12.7765 4.67192C12.6634 5.24076 12.3841 5.76328 11.974 6.17343L10.0712 8.07627C9.96174 8.18572 9.90025 8.33418 9.90025 8.48897C9.90025 8.64377 9.96174 8.79222 10.0712 8.90168C10.1807 9.01114 10.3291 9.07263 10.4839 9.07263C10.6387 9.07263 10.7872 9.01114 10.8966 8.90168L12.7994 7.00001C13.5671 6.23065 13.9984 5.18824 13.9986 4.10138C13.9988 3.01452 13.568 1.97193 12.8006 1.20226Z" fill="#313131"/>
                <path d="M8.33775 4.83758L4.83775 8.33757C4.78204 8.39138 4.7376 8.45575 4.70703 8.52692C4.67645 8.59809 4.66036 8.67463 4.65969 8.75209C4.65902 8.82954 4.67378 8.90635 4.70311 8.97804C4.73244 9.04973 4.77575 9.11486 4.83052 9.16963C4.88529 9.2244 4.95042 9.26772 5.02211 9.29705C5.0938 9.32638 5.17062 9.34114 5.24807 9.34046C5.32552 9.33979 5.40207 9.3237 5.47324 9.29313C5.54441 9.26256 5.60878 9.21812 5.66259 9.1624L9.16259 5.66241C9.26885 5.55239 9.32764 5.40504 9.32631 5.25209C9.32498 5.09914 9.26364 4.95284 9.15548 4.84468C9.04733 4.73653 8.90102 4.67518 8.74807 4.67385C8.59512 4.67252 8.44777 4.73132 8.33775 4.83758Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_738_10647">
                <rect width="14" height="14" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <h3>{{ $t('forms_share.share_by_link') }}</h3>
        </div>
        <div class="share-block-content">
            <div class="url-flex-wrapper">
                <a :href="shareUrl" class="share-link" target="_blank" v-on:click="urlClicked($event)">{{ shareUrl }}</a>

                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-on:click="iconClicked($event)">
                    <g clip-path="url(#clip0_1466_2539)">
                    <path d="M9.75 15C10.7442 14.9988 11.6973 14.6034 12.4003 13.9003C13.1033 13.1973 13.4988 12.2442 13.5 11.25V4.68226C13.5012 4.28803 13.4241 3.89749 13.2732 3.53328C13.1223 3.16906 12.9006 2.83842 12.621 2.56051L10.9395 0.879013C10.6616 0.599393 10.3309 0.377715 9.96674 0.226828C9.60252 0.0759412 9.21198 -0.00115346 8.81775 1.30422e-05H5.25C4.2558 0.00120394 3.30267 0.396675 2.59966 1.09968C1.89666 1.80268 1.50119 2.75582 1.5 3.75001V11.25C1.50119 12.2442 1.89666 13.1973 2.59966 13.9003C3.30267 14.6034 4.2558 14.9988 5.25 15H9.75ZM3 11.25V3.75001C3 3.15328 3.23705 2.58098 3.65901 2.15902C4.08097 1.73707 4.65326 1.50001 5.25 1.50001C5.25 1.50001 8.93925 1.51051 9 1.51801V3.00001C9 3.39784 9.15803 3.77937 9.43934 4.06067C9.72064 4.34198 10.1022 4.50001 10.5 4.50001H11.982C11.9895 4.56076 12 11.25 12 11.25C12 11.8468 11.7629 12.419 11.341 12.841C10.919 13.263 10.3467 13.5 9.75 13.5H5.25C4.65326 13.5 4.08097 13.263 3.65901 12.841C3.23705 12.419 3 11.8468 3 11.25ZM16.5 6.00001V14.25C16.4988 15.2442 16.1033 16.1973 15.4003 16.9003C14.6973 17.6034 13.7442 17.9988 12.75 18H6C5.80109 18 5.61032 17.921 5.46967 17.7803C5.32902 17.6397 5.25 17.4489 5.25 17.25C5.25 17.0511 5.32902 16.8603 5.46967 16.7197C5.61032 16.579 5.80109 16.5 6 16.5H12.75C13.3467 16.5 13.919 16.263 14.341 15.841C14.7629 15.419 15 14.8468 15 14.25V6.00001C15 5.8011 15.079 5.61034 15.2197 5.46968C15.3603 5.32903 15.5511 5.25001 15.75 5.25001C15.9489 5.25001 16.1397 5.32903 16.2803 5.46968C16.421 5.61034 16.5 5.8011 16.5 6.00001Z"

                            :fill="linkIconFillColor"/>

                    </g>
                    <defs>
                    <clipPath id="clip0_1466_2539">
                    <rect width="18" height="18" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShareByLink',
    props: ['shareUrl', 'hideTitle', 'allowLinkOpen'],
    data: function() {
        return {
            linkClicked: false
        }
    },
    computed: {
        linkIconFillColor() {
            return this.linkClicked ? '#E4E4E4' : '#90C148'
        }
    },
    methods: {
        iconClicked(e) {
            navigator.clipboard.writeText(this.shareUrl);
            this.linkClicked = true

            setTimeout(() => {
                this.linkClicked = false
            }, 1000)
        },
        urlClicked(e) {
            //copy link to clipboard

            if (!this.allowLinkOpen) {
                e.preventDefault()
                e.stopPropagation()

                this.iconClicked(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.share-block-heading {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    gap: 5px;

    h3 {
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: $line-height;
        margin: 0;
        padding: 0;
    }
    margin-bottom: 13px;
}

.url-flex-wrapper {
    display: flex;
    flex-flow: row;
    gap: 10px;
    align-items:center;
    cursor: pointer;
    width: 100%;

    a.share-link {
        text-decoration: none;
        color: $text-color;
        line-height: $line-height;
        font-size: $font-size-small;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 1 auto;
    }
    svg {
        flex: 0 0 20px;
    }
}
</style>
