<template>
    <div class="login-component auth-component">
        <div class="auth-container">

            <div class="auth-container__logo">
                <figure><img :src="require('../assets/images/site-logo.png')"/></figure>
            </div>

            <div class="auth-container__title">
                <h3>{{ $t('auth.login_title') }}</h3>
            </div>

            <div class="auth-container__content">
                <form v-on:submit="loginViaEmail">

                    <div class="form-group">
                        <label>{{ $t('auth.email_address') }}</label>
                        <i class="input-icon email-icon"></i>
                        <input type="email" name="email" v-model="email" required="required" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label>{{ $t('auth.password') }}</label>
                        <i class="input-icon password-icon"></i>
                        <input type="password" name="password" v-model="password" required="required" class="form-control" />
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary form-control">{{ $t('auth.login_submit_text') }}</button>
                    </div>

                </form>

                <div class="auth-bottom-links">
                    <router-link to="/auth/register" class="auth-navigation-link">{{ $t('auth.register_link_from_login') }}</router-link>
                    <router-link to="/auth/password" class="auth-navigation-link">{{ $t('auth.forgot_password_link_from_login') }}</router-link>
                </div>
            </div>
        </div>

        <OverlayAlert class="files-modal" v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="closeModal">
        </OverlayAlert>

    </div>
</template>

<script>
const authEndpoint = process.env.VUE_APP_API_BASE + '/auth';

import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';
import OverlayAlert from '@/components/global/OverlayAlert';

export default {
    name: 'AuthLogin',
    components: {
        OverlayAlert
    },
    data: function() {
        return {
            modalOpen: false,
            alertMsg:'',
            email: '',
            password: ''
        }
    },
    computed: {
        formData() {
            return {
                'email': this.email,
                'password': this.password
            }
        },
        ...mapStores(useAuthStore)
    },
    methods: {
        loginViaEmail(e) {
            e.preventDefault()
            this.axios.post(authEndpoint + '/login', this.formData)
                .then(response => {
                    if (response.data['result'] == 'success') {
                        this.authStore.setAuthData(response.data['user'], response.data['token'], response.data['refresh_token'])
                        if (response.data['user']?.isAdmin === true) {
                            this.$router.push('/admin')
                        }
                        else {
                            this.$router.push(this.authStore.returnUrl || '/forms')
                        }
                    }
                    else if (response.data['result'] == 'error') {
                        this.alertMsg = this.$t('auth.' + response.data['message']);
                        this.modalOpen = true;
                    }
                }).catch(error => {
                    console.log(error)
                    alert('Error occurred. #002')
                })
        },
        closeModal(){
            this.modalOpen = false;
        }
    }
}
</script>

<style lang="scss">
</style>
