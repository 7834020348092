export default {
  "auth": {
    "login_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כניסה לחשבון"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["דואר אלקטרוני"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה"])},
    "login_submit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כניסה"])},
    "register_link_from_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יצירת חשבון חדש"])},
    "forgot_password_link_from_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שכחתי סיסמה"])},
    "register_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יצירת חשבון"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם פרטי"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם משפחה"])},
    "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אימות סיסמה"])},
    "register_agreement_confirmation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אני מאשר/ת שקראתי את תנאי השימוש באתר"])},
    "register_submit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הרשמה"])},
    "login_link_from_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כניסה לחשבון קיים"])},
    "email_already_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת האימייל שהקלדת כבר קיימת במערכת"])},
    "forgot_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שחזור סיסמה"])},
    "reset_password_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שלח"])},
    "forgot_password_submitted_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אם יש חשבון תחת כתובת המייל שהזנת, יישלח מייל עם הוראות איפוס סיסמא."])},
    "login_link_from_forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזרה לדף כניסה"])},
    "email_not_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא קיים חשבון תחת כתובת המייל שהזנת"])},
    "wrong_login_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הפרטים שהזנת לא תקינים. נא לבדוק ולנסות שנית"])},
    "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אימות סיסמה לא זהה לסיסמה שבחרת"])},
    "registration_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נרשמת בהצלחה"])},
    "password_reset_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אם יש חשבון תחת מייל זה, תקבל תוך דקות ספורות הוראות לאיפוס סיסמה לכתובת המייל שלך."])},
    "reset_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["איפוס סיסמה"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה חדשה"])},
    "new_password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אימות סיסמה חדשה"])},
    "error_occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["התרחשה שגיאה"])}
  },
  "profile": {
    "profile_dropdown_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרופיל"])},
    "profile_dropdown_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ניהול פרופיל / הגדרות"])},
    "profile_dropdown_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יציאה"])},
    "my_profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרופיל שלי"])},
    "personal_information_navigation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרטים אישיים"])},
    "change_password_navigation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שינוי סיסמה"])},
    "change_email_navigation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["דואר אלקטרוני"])},
    "subscription_plan_navigation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ניהול חבילה"])},
    "personal_information_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרטים אישיים"])},
    "change_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שינוי סיסמה"])},
    "change_email_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["דואר אלקטרוני"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם פרטי"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם משפחה"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מס' טלפון"])},
    "personal_information_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עדכן פרטים"])},
    "personal_information_update_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הפרטים נשמרו בהצלחה"])},
    "current_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת דואר אלקטרוני"])},
    "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת חדשה"])},
    "email_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עדכן פרטים"])},
    "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה נוכחית"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה חדשה"])},
    "new_password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה חדשה (אימות)"])},
    "password_update_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עדכן סיסמה"])},
    "password_changed_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה עודכנה בהצלחה"])},
    "password_confirmation_does_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שגיאה: האימות לא זהה לסיסמה"])},
    "wrong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיסמה נוכחית לא תקינה, נא לבדוק ולנסות שוב"])},
    "email_updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת עודכנה בהצלחה"])},
    "same_email_change_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת מייל חדשה זהה לכתובת נוכחית!"])},
    "new_email_already_registered_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת חדשה שהזנת כבר קיימת במערכת"])},
    "plan_is_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פעיל"])},
    "choose_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר חבילה"])},
    "plan_features_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul style=\"margin: 0; padding: 0;\"> <li>עד 3 טפסים</li> <li>עד 30 שדות בטופס</li> <li>עד 100 רשומות לכל הטפסים</li> <li>עד 30 מגה נפח</li> </ul>"])},
    "plan_features_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul style=\"margin: 0; padding: 0;\"> <li>עד 25 טפסים</li> <li>עד 30 שדות בטופס</li> <li>עד 1000 רשומות לכל הטפסים לשנה</li> <li>עד 500 מגה נפח</li> </ul>"])},
    "plan_features_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul style=\"margin: 0; padding: 0;\"> <li>עד 50 טפסים</li> <li>ללא הגבלת שדות לטופס</li> <li>עד 2500 רשומות לכל הטפסים לשנה</li> <li>עד 1024 מגה נפח</li> </ul>"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יציאה מחשבון"])}
  },
  "navigation": {
    "my_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טפסים שלי"])}
  },
  "alert": {
    "default_submit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["המשך"])}
  },
  "form_builder": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ברירת מחדל"])},
    "navbar_click_name_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לחצו על שם הטופס כדי לערוך אותו"])},
    "editor_tabs_header_widgets_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אלמנטים"])},
    "editor_tabs_header_editor_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות אלמנט"])},
    "sidenav_back_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לטפסים שלי"])},
    "sidenav_widgets_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אלמנטים"])},
    "sidenav_style_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סגנון טופס"])},
    "sidenav_settings_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות טופס"])},
    "widgets_view_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לחץ או גרור לאמצע המסך את האלמנט הרצוי לצד שמאל של המסך וערוך את האלמנט"])},
    "widgets_section_title_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תוכן"])},
    "widgets_section_title_single_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדות בסיסיים"])},
    "widgets_section_title_multi_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדות בחירה"])},
    "widgets_section_title_grouped_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבוצות שדות"])},
    "preview_controls_web_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תצוגת מילוי"])},
    "preview_controls_pdf_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תצוגת PDF"])},
    "configurations_style_view_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סגנון עיצוב טופס"])},
    "configurations_style_view_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר סגנון עיצוב לטופס שלך"])},
    "configurations_settings_view_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות טופס"])},
    "configurations_settings_view_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שנה את הגדרות טופס זה בהתאם"])},
    "widget_editor_view_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["הגדרות ", _interpolate(_named("widgetName"))])},
    "widget_editor_view_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדירו את הנתונים המתאימים לאלמנט הנבחר"])},
    "widget_editor_content_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תוכן"])},
    "widget_editor_input_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות שדה"])},
    "widget_editor_fill_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות מילוי"])},
    "widget_editor_image_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תמונה"])},
    "widget_editor_display_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הצגת אלמנט"])},
    "widget_editor_validations_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["וולידציות"])},
    "widget_editor_style_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עיצוב אלמנט "])},
    "widget_editor_style_section_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר את עיצוב המתאים לאלמנט הנבחר"])},
    "last_saved_at_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["נשמר לאחרונה בשעה ", _interpolate(_named("time"))])},
    "delete_widget_confirmation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["למחוק את האלמנט?"])},
    "drag_a_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יש לגרור ווידג'ט מסרגל ימני לאיזור זה!"])},
    "drag_first_element_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["גרור את האלמנט הראשון לכאן "])},
    "drag_first_element_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר בעמודה מצד ימין את האלמנט המתאים עבור הטופס אותו אתה בונה, לאחר מכן תפוס אותו באמצעות העכבר וגרור אותו למלבן במרכז המסך "])},
    "Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תוכן"])},
    "Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת"])},
    "Image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תמונה"])},
    "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כפתור"])},
    "Separator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פס מפריד"])},
    "Pagebreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שבירת דף בPDF"])},
    "Textbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה טקסט"])},
    "Textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה תוכן"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה תאריך"])},
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה זמן"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה דואר אלקטרוני"])},
    "Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה כתובת אינטרנט"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה צבע"])},
    "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה מספר"])},
    "Checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה בחירות"])},
    "Radiobutton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה בחירה"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה Dropdown"])},
    "Id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה ת.ז"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה מס' טלפון"])},
    "ImageUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה העלאת תמונה"])},
    "PdfUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה העלאת PDF"])},
    "Signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה חתימה"])},
    "Bankinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרטי חשבון בנק"])},
    "Addressinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרטי כתובת"])},
    "Daterange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טווח תאריכים"])},
    "Content_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הוספת תוכן לטופס; פסקה או מספר משפטים"])},
    "Heading_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת ראשית לטופס או תת כותרת"])},
    "Image_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הוספת תמונה לטופס כמו לוגו או כל תמונה"])},
    "Button_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Separator_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הוספת קו מפריד"])},
    "Pagebreak_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מעבר לדף PDF חדש"])},
    "Textbox_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה טקסט קצר למילוי; שם פרטי ומשפחה לדוגמא"])},
    "Textarea_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה טקסט ארוך שהמשתמש ממלא המכיל מספר משפטים"])},
    "Date_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה בחירת תאריך"])},
    "Time_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה הקלדת זמן"])},
    "Email_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה הקלדת אימייל"])},
    "Url_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה כתובת אינטרנט"])},
    "Color_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה בחירת צבע"])},
    "Number_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה הקלדת מספר"])},
    "Checkbox_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה המאפשר מספר בחירות"])},
    "Radiobutton_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה המאפשר בחירה אחת ממספר אפשרויות"])},
    "Select_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה בחירה אחת מרשימה"])},
    "Id_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה הקלדת ת.ז"])},
    "Phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה הקלדת טלפון"])},
    "ImageUpload_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה העלאת תמונה"])},
    "PdfUpload_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה העלאת מסמך PDF"])},
    "Signature_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה הוספת חתימה"])},
    "Bankinfo_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Addressinfo_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Daterange_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "widgets_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["וודג'טים"])},
    "form_settings_link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות הטופס"])},
    "edit_widget_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ווידג'ט: "])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזרה"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עריכה"])},
    "clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שכפול"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מחיקה"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הזזה"])},
    "content_widgets_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תוכן"])},
    "single_fields_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדות בסיסיים"])},
    "multi_fields_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדות בחירה"])},
    "grouped_fields_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבוצות שדות"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תוכן"])},
    "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עיצוב"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טקסט"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת שדה"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדה חובה"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחירות"])},
    "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הוסף בחירה"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קישור"])},
    "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["גופן"])},
    "font_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["גודל פונט"])},
    "font_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג גופן"])},
    "font_weight_normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
    "font_weight_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צבע טקסט"])},
    "background_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צבע רקע"])},
    "heading_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג כותרת"])},
    "text_align": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ישור טקסט"])},
    "border_radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רדיוס מסגרת"])},
    "border_width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עובה מסגרת"])},
    "border_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סגנון מסגרת"])},
    "border_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צבע מסגרת"])},
    "font_styling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אלמנטים "])},
    "padding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רוחב פנימי"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רוחב חיצוני"])},
    "hint_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עליון"])},
    "hint_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ימין"])},
    "hint_bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תחתון"])},
    "hint_left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שמאל"])},
    "font_inherit_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לפי הגדרות הטופס"])},
    "reset_style_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["איפוס עיצוב"])},
    "option_default_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחירה"])},
    "phone_extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיומות אפשריות"])},
    "save_and_close_widget_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שמור/סגור ווידג'ט"])},
    "publish_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["המשך לפרסום טופס"])},
    "save_and_close_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שמור וסגור טיוטה"])},
    "ONE_STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טופס חד שלבי"])},
    "TWO_STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טופס דו שלבי"])},
    "web_form_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תצוגת מילוי"])},
    "pdf_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תצוגת PDF"])},
    "filler_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["איזה שלב יכול למלא את השדה?"])},
    "filler_value_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שני השלבים"])},
    "filler_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שלב ראשון בלבד"])},
    "filler_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שלב שני בלבד"])},
    "download_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הורדת קובץ PDF לדוגמא"])},
    "settings_widget_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["איפה להציג"])},
    "settings_visibility_both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הצגה בגרסת מילוי + גרסת PDF"])},
    "settings_visibility_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הצגה בגרסת מילוי בלבד"])},
    "settings_visibility_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הצגה בגרסת PDF בלבד"])},
    "form_configurations_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות הטופס"])},
    "form_configurations_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כללי"])},
    "form_configurations_style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עיצוב"])},
    "form_configurations_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הגדרות PDF"])},
    "configurations_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם הטופס"])},
    "configurations_form_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שפת הטופס"])},
    "configurations_form_direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כיוון כתיבה"])},
    "configurations_form_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג הטופס"])},
    "configurations_default_font_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פונט (ברירת מחדל)"])},
    "configurations_default_font_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["גודל פונט"])},
    "configurations_pdf_margins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רוחב פנימי (PDF)"])},
    "save_and_close_configurations_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שמור / סגור"])},
    "use_separate_styles_in_widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יצירת סגנון שונה עבור תצוגת PDF"])},
    "web_form_style_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עיצוב ברירת מחדל / תצוגת מילוי"])},
    "pdf_style_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עיצוב תצוגת PDF"])},
    "bank_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם הבנק"])},
    "bank_owner_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם המוטב"])},
    "bank_branch_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר סניף"])},
    "bank_account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר חשבון"])},
    "bank_name_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ליאומי^מרכנתיל^דיסקונט^טפחות"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עיר"])},
    "street_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת רחוב"])},
    "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מיקוד"])},
    "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מתאריך"])},
    "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עד תאריך"])},
    "image_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תמונה"])},
    "choose_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחר תמונה"])},
    "image_width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רוחב תמונה"])},
    "allow_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר קבצים"])},
    "validations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ולידציות"])},
    "validation_add_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הוספת ולידציה"])},
    "validation_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מחיקה"])},
    "no_available_validations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אין ולידציות מתאימות"])},
    "choose_validation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחרו וולידציה עבור אלמנט זה"])},
    "min_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערך מינימלי"])},
    "max_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערך מקסימלי"])},
    "min_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אורך מינימלי"])},
    "max_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אורך מקסימלי"])},
    "letters_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אותיות בלבד"])},
    "numbers_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ספרות בלבד"])},
    "regex_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RegEx Pattern"])},
    "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שדות"])},
    "edit_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עריכת שדה"])},
    "hide_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הסתר"])},
    "validations_count_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["אין ולידציות"]), _normalize(["ולידציה אחת (1)"]), _normalize([_interpolate(_named("n")), " ולידציות"])])},
    "edit_field_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עריכת שדה"])},
    "edit_field_label_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת שדה"])},
    "edit_field_modal_submit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["המשך"])},
    "modal_default_cancel_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ביטול"])},
    "publish_form_confirmation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא תהיה אפשרות לערוך את הטופס לאחר הפקה. להמשיך?"])},
    "allow_editing_in_first_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אפשר עריכת תוכן בשלב ראשון"])},
    "search_in_widgets_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חיפוש אלמנט..."])},
    "publishing_empty_form_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן לפרסם טופס ריק"])},
    "heading_H1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת ראשית"])},
    "heading_H2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת שניה"])},
    "heading_H3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת שלישית"])},
    "heading_H4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כותרת רביעית"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנגלית"])},
    "he": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עברית"])},
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערבית"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רוסית"])}
  },
  "forms_index": {
    "my_forms_title_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הטפסים שלי"])},
    "create_new_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יצירת טופס חדש"])},
    "_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מצב"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג טופס"])},
    "DRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טיוטה"])},
    "PUBLISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פורסם"])},
    "ONE_STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חד שלבי"])},
    "TWO_STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["דו שלבי"])},
    "filled_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך מילוי"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך יצור"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך פרסום"])},
    "records_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "forms_published_tab_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פורסם"])},
    "forms_all_tab_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הכל"])},
    "forms_draft_tab_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טיוטה"])},
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חפש טופס ברשימה"])},
    "no_results_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אין תוצאות"])},
    "empty_table_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קצת משעמם פה, כדאי לנסות  ליצור טופס חדש"])},
    "delete_form_confirmation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["האם ברצונך למחוק את הטופס ואת המידע שהטופס מכיל, כולל נתוני המילוי?"])}
  },
  "form_records": {
    "form_records_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["תוצאות מילוי - ", _interpolate(_named("formName"))])},
    "share_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שליחת קישור למילוי"])}
  },
  "forms_create": {
    "new_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יצירת טופס חדש"])},
    "new_form_title_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחרו את סוג הטופס אותו תרצו ליצור"])},
    "one_step_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טופס כללי"])},
    "one_step_form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" צור טופס בהתאמה אישית ושתף אותו ישירות ללקוח. סוג טופס זה מתאים ליצירת טופס הרשמה, הצהרה בריאות ועוד"])},
    "two_step_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טופס דו שלבי"])},
    "two_step_form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צור טופס בהתאמה אישית אותו ניתן לערוך ולהתאים לפני השליחה ללקוח. סוג טופס זה מתאים לחוזים, הסכמים ועוד"])},
    "for_more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לפרטים נוספים"])},
    "choose_form_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בחרו סוג טופס כדי להתחיל"])},
    "two_step_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טופס דו שלבי"])},
    "two_step_details_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul class=\"checkmark-ul\"> <li>מאפשר לערוך באופן פרטני לפני השליחה לנמען</li> <li>ניתן להגדיר שדות ואלמנטים ספציפיים שחובה לערוך ולהתאים אותם לפני השליחה לנמען</li> <li>ניתן להוסיף אלמנטים, כמו פסקה, אשר יופיעו רק כאשר עורכים את הטופס ולא יוצגו לנמען/ ללקוח.</li> <li>טופס זה שימושי מאוד ליצירת חוזים והסכמים</li> </ul>"])},
    "form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם הטופס"])},
    "form_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שפת הטופס"])},
    "language_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אנגלית"])},
    "language_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערבית"])},
    "language_he": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עברית"])},
    "language_ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רוסית"])},
    "language_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אחר.."])},
    "form_flow_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג הטופס"])},
    "form_flow_one_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צעד אחד (שיתוף בבולק דרך קישור כללי)"])},
    "form_flow_two_step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שני צעדים (עריכה ושליחה פרטנית לפי נמען)"])},
    "form_create_submit_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יצירה"])},
    "back_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזרה"])},
    "forms_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לא ניתן לייצר טפסים נוספים בחבילה זו, על מנת לייצר טופס חדש נוסף יש לשדרג את החבילה"])}
  },
  "forms_share": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזרה"])},
    "back_to_forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חזרה למסך הטפסים שלי"])},
    "share_form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיתוף עם נמענים"])},
    "form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שם המסמך"])},
    "form_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שפת המסמך"])},
    "form_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג המסמך"])},
    "form_publish_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך הפרסום"])},
    "how_do_you_want_to_share_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כיצד תרצו לשתף את המסמך?"])},
    "share_by_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיתוף דרך קישור"])},
    "share_by_whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיתוף דרך וואטסאפ"])},
    "share_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיתוף דרך מייל"])},
    "split_emails_by_comma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ניתן לשתף מספר כתובות מייל ע\"י הוספה של פסיק ,"])},
    "email_message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הודעה"])},
    "email_subject_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נושא"])},
    "email_recipients_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתובת מייל"])},
    "email_form_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שליחת קישור"])},
    "share_form_step_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צעד 1 - עריכה לפני שליחה"])},
    "share_form_step_1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יש למלא\\לערוך את הטופס לפני שיתוף עם נמענים דרך הקישור"])},
    "share_form_step_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צעד 2 - שליחה לנמען"])},
    "share_by_email_success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["האימייל נשלח בהצלחה"])},
    "share_by_email_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["התרחשה שגיאה!"])}
  },
  "data_table": {
    "results_counter_showing_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מציג"])},
    "results_counter_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "results_counter_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עד"])},
    "results_counter_total_count_prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מתוך"])},
    "results_counter_total_count_suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תוצאות"])},
    "view_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["צפייה"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבצים"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טוען.."])},
    "files_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבצים"])},
    "close_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סגור"])},
    "modal_default_cancel_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סגור"])}
  },
  "admin": {
    "index_page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ממשק ניהול"])},
    "navigation_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ניהול יוזרים"])},
    "users_index_page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יוזרים"])}
  }
}