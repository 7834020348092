import widgetsUtility from '@/widgetsUtility'

const NumberWidget = {
    name: 'NumberWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Number')
};

const NumberWidgetPdf = {
    name: 'NumberWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Number')
};

export { NumberWidget, NumberWidgetPdf };
