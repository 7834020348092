<template>
    <div class="overlay-sidebar">
        <div class="overlay-sidebar__header">
            <div class="overlay-sidebar__header__title">
                <h3>{{ title }}</h3>
            </div>
            <span class="overlay-sidebar__header__close" v-on:click="$emit('closeClicked')">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1492_2641)">
                <path d="M10.4586 8.85423L16.9483 2.36519C17.3512 1.96234 17.3512 1.3092 16.9483 0.906384C16.5455 0.503536 15.8923 0.503536 15.4895 0.906384L9.00047 7.3961L2.51143 0.906384C2.10858 0.503536 1.45544 0.503536 1.05262 0.906384C0.649809 1.30923 0.649776 1.96237 1.05262 2.36519L7.54234 8.85423L1.05262 15.3433C0.649776 15.7461 0.649776 16.3993 1.05262 16.8021C1.45547 17.2049 2.10861 17.2049 2.51143 16.8021L9.00047 10.3124L15.4895 16.8021C15.8923 17.2049 16.5455 17.2049 16.9483 16.8021C17.3512 16.3993 17.3512 15.7461 16.9483 15.3433L10.4586 8.85423Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_1492_2641">
                <rect width="16.5" height="16.5" fill="white" transform="translate(0.75 0.604004)"/>
                </clipPath>
                </defs>
                </svg>
            </span>
        </div>
        <div class="overlay-sidebar__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'OverlaySidebar',
    props: ['title']
}
</script>

<style lang="scss" scoped>
.overlay-sidebar {
    position: absolute;
    right: 0;
    top: $nav-height;
    height: calc(100vh - $nav-height);
    width: 500px;
    box-sizing: border-box;
    background: $white-color;
    z-index: 99;

    &__header {
        background: $secondary-color;
        height: 75px;

        &__title {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;

            h3 {
                margin: 0;
                line-height: $line-height;
            }
        }

        &__close {
            position: absolute;
            top: 17px;
            left: 20px;
            color: $white-color;

            cursor: pointer;
        }

        h3 {
            margin-top: 0;
            color: $white-color;
            font-size: $font-size-huge;
            font-weight: $font-weight;
        }
    }

    &__content {
        padding: 30px 20px;
    }
}
</style>
