<template>
    <div class="dashboard-container">
        <div class="navbar">
            <router-link to="/forms"><img :src="require('@/assets/images/site-logo-white.svg')" alt="Tofsy" class="logo" /></router-link>
            <div class="form-name-section">
                <span class="form-name-section__input" v-if="!!editingName">
                    <input type="text" id="form-name-input" v-model="formName" />
                    <i class="save-icon"></i>
                </span>
                <span class="form-name-section__name" v-if="!editingName" v-on:click="editName">{{ formStore.formData?.name }}</span>
                <span class="form-name-section__description">{{ $t('form_builder.navbar_click_name_to_edit') }}</span>
            </div>
            <div class="form-controls-section">
                <span class="form-type-tag">{{ $t('form_builder.' + formStore.formData.type ) }}</span>
                <a href="javascript:;" class="save-form-btn" v-on:click="saveCloseForm">{{ $t('form_builder.save_and_close_form') }}</a>
                <a href="javascript:;" class="publish-form-btn" v-on:click="publishForm">{{ $t('form_builder.publish_form') }}</a>
            </div>
            <router-link to="/profile"><span class="user-name">{{ userName }}</span></router-link>
        </div>

        <OverlayAlert class="publish-form-error-modal" v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="modalOpen = false">
        </OverlayAlert>
    </div>
</template>

<script>
import OverlayAlert from '@/components/global/OverlayAlert.vue'

import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'NavigationBar',
    components: {
        OverlayAlert
    },
    data() {
        return {
            userName: '',
            formName: '',
            editingName: false,

            alertMsg: '',
            modalOpen: false
        }
    },
    computed: {
        ...mapStores(useAuthStore, useFormStore)
    },
    beforeMount() {
        let user = this.authStore.$state.user
        this.userName = user.first_name
        this.formName = this.formStore.formData.name
    },
    methods: {
        cancelEditName() {
            this.editingName = false
        },
        inputKeyClicked(event) {
            if (event.key === 'Enter' || event.keyCode === 13) {
                //alert('Enter clicked')
                //save
                this.formStore.updateFormName(this.formName)
                this.formStore.saveChanges()
                this.cancelEditName()
            }
            else if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
                this.cancelEditName()
            }
        },
        addEventListeners() {
            //alert('adding listeners: ' + this.formName)
            document.querySelector('#form-name-input').focus()
            document.querySelector('#form-name-input').addEventListener('keyup', this.inputKeyClicked)
            setTimeout(this.addBlurEventListener, 100)
        },
        addBlurEventListener() {
            document.querySelector('#form-name-input').addEventListener('blur', () => {
                this.formStore.updateFormName(this.formName)
                this.formStore.saveChanges()
                this.cancelEditName()
            })
        },
        editName() {
            this.editingName = true
            this.formName = this.formStore.formData?.name
            setTimeout(this.addEventListeners, 100)
            //console.log(document.querySelector('#form-name-input'))
            //alert('edit name')
        },
        saveCloseForm() {
            this.formStore.saveChanges()
            this.$router.push({ name: 'FormsIndex' })
        },
        publishForm() {
            const formData = this.formStore.$state.formData?.form_schema
            if (formData && formData.length == 0) {
                this.alertMsg = this.$t('form_builder.publishing_empty_form_error_message')
                this.modalOpen = true
                return
            }

            if (confirm(this.$t('form_builder.publish_form_confirmation_message'))) {
                this.formStore.saveChanges(() => {
                    this.formStore.publishForm().then(response => {
                        if (response.data.status === 'PUBLISHED') {
                            this.$router.push({
                                name: 'FormsShare',
                                params: this.$route.params
                            })
                        }
                        else {
                            alert('Could not publish form - error #18')
                            console.log(response)
                        }
                    })
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
	padding: 5px 20px;
	background-color: $navbar-background-color;
	display: flex;
	align-items: center;
    gap: 30px;

    & > * {
        flex-grow: 0;
    }

    .logo {
        padding: 10px;
    }

    a {
        text-decoration: none;
    }

    span.user-name {
		font-size: $font-size-small;
        font-weight: $font-weight-bold;
        position: relative;
        color: $white-color;
        padding: 12px 34px 12px 12px;
        text-transform: uppercase;

        &::before {
            position: absolute;
            content: '';
            background-image: url('~@/assets/images/icon-user.svg');
            width: 18px;
            height: 20px;
            right: 10px;
            top: 12px;
        }
	}

    .form-name-section {
        display: flex;
        flex-flow: column nowrap;
        color: #fff;
        gap: 5px;
        flex-grow: 1;

        &__input {
            input {
                display: inline-block;
                background: transparent;
                border: 0;
                border-radius: 0;
                outline: transparent;
                border-bottom: 1px solid #fff;
                color: #fff;
                font-size: $font-size-huge;
                width: 300px;
                max-width: 300px;
                padding: 2px;
            }
        }

        &__name {
            font-size: $font-size-huge;
            font-weight: $font-weight-bold;
            line-height: 24px;
            cursor: pointer;

            &::after {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-inline-start: 10px;

                background-image: url('~@/assets/images/icon-pencil.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }

        &__description {
            font-size: $font-size;
            font-weight: $font-weight;
            line-height: 24px;
        }
    }

    .form-controls-section {
        display: flex;
        flex-flow: row nowrap;
        gap: 40px;
        padding-inline-end: 40px;
        border-left: 1px solid white;
        align-items: center;

        & > * {
            display: inline-block;
        }

        & > a {
            width: 200px;
            box-sizing: border-box;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px solid #fff;
            border-radius: $default-border-radius;
            font-size: $font-size;
            font-weight: $font-weight-bold;

            &.publish-form-btn {
                background: #fff;
                color: #000;
            }
            &.save-form-btn {
                background: transparent;
                color: #fff;
            }
        }

        .form-type-tag {
            width: auto;
            height: 24px;
            line-height: 24px;
            padding: 0 12px;
            border-radius: 5px;
            color: $brand-color;
            border: 1px solid $brand-color;
            text-align: center;
        }
    }
}
</style>
