<template>
    <div id="form-records-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <router-link to="/forms" class="back-link">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.19541 4.47119L9.72408 7.99986L6.19541 11.5285L7.13808 12.4712L10.6667 8.94252C10.9167 8.69249 11.0571 8.35341 11.0571 7.99986C11.0571 7.64631 10.9167 7.30723 10.6667 7.05719L7.13808 3.52852L6.19541 4.47119Z" fill="#4E2053"/>
                    </svg>
                    <span>{{ $t("forms_share.back_to_forms") }}</span>
                </router-link>

                <h1 class="page-title">{{ $t( 'form_records.form_records_title', {formName: form.name} ) }}</h1>

                <div class="table-header">


                    <div class="table-header__tabs">
                        <!--
                        <a href="javascript:;" class="active-btn">files <span>(20)</span></a>
                        <a href="javascript:;">files <span>(12)</span></a>
                        <a href="javascript:;">files <span>(8)</span></a>
                        -->
                    </div>


                    <div class="table-header__controls">
                        <div class="table-header__controls__search">
                            <SearchBox :placeholder="$t('forms_index.search_placeholder')"
                                    @search="updateFilterData" />
                        </div>
                        <router-link :to="shareUrl" class="table-header__controls__btn">{{ $t('form_records.share_form') }}</router-link>
                    </div>

                </div>

                <NewDataTable
                    :dataUrl="dataUrl"
                    :displayId="false"
                    :dataFormatter="formatData"
                    @actionTriggered="actionTriggered"
                    :sortable="true"
                    :filterData="filterData"
                />

            </section>

        </div>
    </div>
</template>

<script>
/*import DataTable from 'datatables.net-vue3';*/
import NewDataTable from '@/components/global/NewDataTable.vue'
import SearchBox from '@/components/global/SearchBox.vue'

const apiBase = process.env.VUE_APP_API_BASE;

import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'FormRecords',
    components: {
        NewDataTable,
        SearchBox
    },
    data() {
        return {
            form: {},
            records: [],
            formattedRecords: [],
            visibleFields: [],
            dataUrl: apiBase + '/forms/' + this.$route.params.id + '/records',
            shareUrl: '/forms/' + this.$route.params.id + '/share',

            publicTokensMap: {},
            filterData: {}
        }
    },
    created() {
        this.axios.get(apiBase + '/forms/' + this.$route.params.id)
            .then(response => {
                this.form = response.data
            }).catch(error => {
                console.log(error)
            })

        /*
        this.axios.get(formsEndpoint + '/' + this.$route.params.id + '/records')
            .then(response => {
                this.records = response.data
            }).catch(error => {
                console.log(error)
            })
        */
    },
    computed: {
        ...mapStores(useAuthStore)
    },
    methods: {
        updateFilterData(query) {
            this.filterData['*'] = query
        },
        formatData(recordsData) {
            for (var recordKey in recordsData) {

                //set actions data
                var record = recordsData[recordKey]
                //console.log(record)

                var actions = {}

                if (record.filled_at?.value) {
                    actions['view_pdf'] = '<i class="view-pdf-action"></i>'
                }
                else {
                    actions['share'] = '<i class="share-action"></i>'
                }

                record['actions'] = {
                    'label': '',
                    'value': actions,
                    'display_type': 'actions'
                }

                //translate labels
                for (var k in record) {
                    if (record[k]['label'] == 'created_at') {
                        record[k]['label'] = this.$t('forms_index.created_at')
                        record[k]['value'] = this.formatDate(record[k]['value'])
                    }
                    else if (record[k]['label'] == 'filled_at') {
                        record[k]['label'] = this.$t('forms_index.filled_at')
                        record[k]['value'] = this.formatDate(record[k]['value'])
                    }
                }

                this.publicTokensMap[record['_id'].value] = record['public_token'].value
            }

            return recordsData
        },
        actionTriggered(actionKey, identifier) {
            if (!identifier) {
                alert('Error occurred #89')
            }
            else if (actionKey == 'view_pdf') {
                window.location = apiBase + '/ui/records/' + this.$route.params.id + '/'
                        + identifier + '/pdf?token=' + this.publicTokensMap[identifier]
            }
            else if (actionKey == 'share') {
                this.$router.push('/forms/' + this.$route.params.id + '/share?recordId=' + identifier)
            }
        }
    }
}
</script>

<style lang="scss">
.dt-td-actions {
    text-align: left;
    padding-left: 25px;

    .dt-action {
        padding: 3px;
        margin: 0 5px;

        i {
            display: inline-block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 19px;
            height: 19px;
            vertical-align: middle;
        }
        .view-pdf-action {
            background-image: url('~@/assets/images/icon-pdf.svg');
        }
        .share-action {
            background-image: url('~@/assets/images/icon-share.svg');
        }
    }
}
</style>
