var widgetsUtility = {

    getPossibleValidations(widgetType) {

        let validationsByWidget = {
            'Textbox': ["min_length", "max_length", "letters_only", "numbers_only", "regex_pattern"],
            'Textarea': ["min_length", "max_length"],
            'Date': ["min_value", "max_value"],
            'Time': ["min_value", "max_value"],
            'Number': ["min_value", "max_value"]
        };

        let response = validationsByWidget[widgetType];
        return response === undefined ? [] : response;
    },
    getValidations() {
        return {
            'min_length': {
                'param_type': 'Number',
                'value': '',
                'value_locked': false,
                'value_required': true
            },
            'max_length': {
                'param_type': 'Number',
                'value': '',
                'value_locked': false,
                'value_required': true
            },
            'min_value': {
                'param_type': 'Inherit',
                'value': '',
                'value_locked': false,
                'value_required': true
            },
            'max_value': {
                'param_type': 'Inherit',
                'value': '',
                'value_locked': false,
                'value_required': true
            },
            'regex_pattern': {
                'param_type': 'Pattern',
                'value': '',
                'value_locked': false,
                'value_required': true
            },
            'numbers_only': {
                'param_type': 'Pattern',
                'value': '^[,.\d]+$',
                'value_locked': true,
                'value_required': true
            },
            'letters_only': {
                'param_type': 'Pattern',
                'value': '^[\p{Lu}\p{Ll} ]+$',
                'value_locked': true,
                'value_required': true
            }
        }
    },
    getLanguageDirection(language) {
        switch(language) {
            case 'he':
            case 'ar': return 'RTL'; break;
            default: return 'LTR'; break;
        }
    },

    /** WIDGETS DATA **/
    getDefaultWidgets() {
        if (localStorage['widgets']) {
            return JSON.parse(localStorage['widgets']);
        }
        console.log('Could not load default widgets')
        return []
    },
    setDefaultWidgets(data) {
        if (data) {
            localStorage['widgets'] = JSON.stringify(data);
        }
    },
    getNewWidget(widgetType) {

        var widgetObj = this.getDefaultWidgets()[widgetType];

        widgetObj['id'] = this.generateUniqueWidgetId(widgetType);

        //move to separate function
        //@TODO
        if (widgetObj.properties.hasOwnProperty('style')) {
            if (widgetObj['properties']['style']['color'] == '') {
                widgetObj['properties']['style']['color'] = '#111111';
            }
            if (widgetObj['properties']['style']['background-color'] == '') {
                widgetObj['properties']['style']['background-color'] = '#d8d8d8';
            }
            if (widgetObj['properties']['style']['border-color'] == '') {
                widgetObj['properties']['style']['border-color'] = '#111111';
            }
            if (widgetObj['properties']['style']['border-style'] == '') {
                widgetObj['properties']['style']['border-style'] = 'solid';
            }
            if (widgetObj['properties']['style']['padding'] == '') {
                widgetObj['properties']['style']['padding'] = '10px 10px 10px 10px';
            }
            if (widgetObj['properties']['style']['margin'] == '') {
                widgetObj['properties']['style']['margin'] = '0px 0px 0px 0px';
            }
            if (widgetObj['properties']['style']['font-family'] == '') {
                widgetObj['properties']['style']['font-family'] = 'inherit';
            }
            if (widgetObj['properties']['style']['direction'] == '') {
                widgetObj['properties']['style']['direction'] = 'inherit';
            }
        }

        //add unique IDs to sub-fields where applicable
        if (widgetObj.properties.hasOwnProperty('fields')) {
            for (var field of widgetObj.properties.fields) {
                field['_id'] = this.generateUniqueFieldId();
            }
        }

        return widgetObj;
    },

    /** HTML TEMPLATES **/
    getHtmlTemplates() {
        if (localStorage['html_templates']) {
            return JSON.parse(localStorage['html_templates'])
        }
        console.log('Could not load html templates')
        return []
    },
    getHtmlTemplate(widgetName) {
        let template = this.getHtmlTemplates()[widgetName];
        if (template) {
            return this.translateTemplate(template);
        }
        return '';
    },
    setHtmlTemplates(data) {
        if (data) {
            localStorage['html_templates'] = JSON.stringify(data);
        }
    },

    /** PDF TEMPLATES **/
    getPdfTemplates() {
        if (localStorage['pdf_templates']) {
            return JSON.parse(localStorage['pdf_templates'])
        }
        console.log('Could not load pdf templates')
        return []
    },
    getPdfTemplate(widgetName) {
        let template = this.getPdfTemplates()[widgetName];
        if (template) {
            return this.translatePdfTemplate(template);
        }
        return '';
    },
    setPdfTemplates(data) {
        if (data) {
            localStorage['pdf_templates'] = JSON.stringify(data);
        }
    },

    /** Helpers **/
    //template parser
    translateTemplate(template) {

        template = template.replaceAll(' name=', ' :name=');
        //template = template.replaceAll(' value=', ' :value=');

        //id
        template = template.replaceAll('%id%', 'widget._id');
        template = template.replaceAll(' for=', ' :for=');
        template = template.replaceAll(' id=', ' :id=');

        //style
        template = template.replaceAll(' style="%', ' :style="%');
        template = template.replaceAll('%style%', 'widget.properties.style');
        template = template.replaceAll('%text_align_style%', `{ 'text-align': widget.properties.style['text-align'] }`);

        //label
        template = template.replaceAll('%label%', 'widget.properties.input.label');
        template = template.replaceAll('%label_html%', '{{ widget.properties.input.label }}');

        //text
        template = template.replaceAll('%text%', 'widget.properties.content.text');
        template = template.replaceAll('%text_content%', '{{ widget.properties.content.text }}');

        //heading_level
        template = template.replaceAll('<h%heading_level%', '<component :is="\'h\' + widget.properties.content.heading_level"');
        template = template.replaceAll('</h%heading_level%>', '</component>');

        //href / url
        template = template.replaceAll('%url%', 'widget.properties.content.url');
        template = template.replaceAll(' href=', ' :href=');


        //image
        template = template.replaceAll(' src=', ' :src=');
        template = template.replaceAll(' width=', ' :width=');
        template = template.replaceAll('%image_source%', 'widget.properties.image.image_source');
        template = template.replaceAll('%image_width%', 'widget.properties.image.image_width');

        //required
        template = template.replaceAll('%required_html%', '<span v-if="!!widget.properties.fill?.required">*</span>');
        //template = template.replaceAll('%required%', '!!widget.properties.content.required')
        template = template.replaceAll('%required_attribute%', ':required="!!widget.properties.fill?.required"');
        //template = template.replaceAll(' required=', ' :required=');
        template = template.replaceAll("%disabled_class%", "")

        //html_content
        const hasHtml = template.includes('%html_content%');
        if (hasHtml) {
            template = template.replaceAll('%html_content%', '');
            template = template.slice(0, template.indexOf('>')) + ' v-html="widget.properties.content.html"'
                    + template.slice(template.indexOf('>'));
        }


        var firstPart = '';
        var secondPart = '';
        //options render
        const hasOptionsRender = template.includes('%foreach_option%');
        if (hasOptionsRender) {
            //find first element after the foreach start, and inject and v-for params
            firstPart = template.slice(0, template.indexOf('%foreach_option%'));
            secondPart = template.slice(template.indexOf('%foreach_option%'));
            secondPart = secondPart.slice(0, secondPart.indexOf('>')) +
                    ' v-for="option in widget.properties.input.options" :key="option"' +
                    secondPart.slice(secondPart.indexOf('>'));
            template = firstPart + secondPart;
            template = template.replace('%foreach_option%', '');
            template = template.replace('%end_foreach_option%', '');


            //option and option_html
            template = template.replaceAll('%option%', 'option');
            template = template.replaceAll('%option_html%', '{{ option }}');
        }

        //extensions render
        const hasExtensionsRender = template.includes('%foreach_extension%');
        if (hasExtensionsRender) {
            //find first element after the foreach start, and inject and v-for params
            firstPart = template.slice(0, template.indexOf('%foreach_extension%'));
            secondPart = template.slice(template.indexOf('%foreach_extension%'));
            secondPart = secondPart.slice(0, secondPart.indexOf('>')) +
                    ' v-for="extension in widget.properties.input.extensions" :key="extension"' +
                    secondPart.slice(secondPart.indexOf('>'));
            template = firstPart + secondPart;
            template = template.replace('%foreach_extension%', '');
            template = template.replace('%end_foreach_extension%', '');

            //option and option_html
            template = template.replaceAll('%extension%', 'extension');
            template = template.replaceAll('%extension_html%', '{{ extension }}');
        }

        //grouped_fields
        const hasFieldsRender = template.includes('%foreach_field%');
        if (hasFieldsRender) {
            template = template.replace('%foreach_field%', '<template v-for="field in widget.properties.content.fields" :key="field.reference_key">');
            template = template.replace('%end_foreach_field%', '</template>');

            template = template.replaceAll('%if_field_enabled%', '<template v-if="field.enabled === true">');
            template = template.replaceAll('%end_if_field_enabled%', '</template>');

            template = template.replaceAll('%field_label_html%', '{{ field.label }}');
            template = template.replaceAll('%field_required_html%', '<span v-if="!!field.required">*</span>');

            template = template.replaceAll('%field_required_attribute%', ':required="!!field.required"');
            template = template.replaceAll('%field_reference_key%', 'field.reference_key');
            template = template.replaceAll('%field_reference_key_unique%', 'field.reference_key.concat(`_`, field._id)');

            template = template.replaceAll('%if_field_is_text%', '<template v-if="field.field_type === `text`">');
            template = template.replaceAll('%end_if_field_is_text%', '</template>');

            template = template.replaceAll('%if_field_is_number%', '<template v-if="field.field_type === `number`">');
            template = template.replaceAll('%end_if_field_is_number%', '</template>');

            template = template.replaceAll('%if_field_is_select%', '<template v-if="field.field_type === `select`">');
            template = template.replaceAll('%end_if_field_is_select%', '</template>');



            const hasFieldOptionsRender = template.includes('%foreach_field_option%');
            if (hasFieldOptionsRender) {
                firstPart = template.slice(0, template.indexOf('%foreach_field_option%'));
                secondPart = template.slice(template.indexOf('%foreach_field_option%'));
                secondPart = secondPart.slice(0, secondPart.indexOf('>')) +
                        ' v-for="option in field.options" :key="option"' +
                        secondPart.slice(secondPart.indexOf('>'));
                template = firstPart + secondPart;
                template = template.replace('%foreach_field_option%', '');
                template = template.replace('%end_foreach_field_option%', '');

                template = template.replace('%field_option%', 'option');
                template = template.replace('%field_option_html%', '{{ option }}');
            }

        }

        template = template.replaceAll('%step%', 'widget.properties.content.step');

        template = template.replaceAll('%multiple_attribute%', ':multiple="!!widget.properties.input?.multiple"');

        template = template.replaceAll('%disabled_attribute%', '')

        template = template.replaceAll('%value%', '&nbsp;')
        template = template.replaceAll('%field_value%', '&nbsp;')
        template = template.replaceAll("%signature%", "&nbsp;")

        //return
        //console.log(template);
        return template;
    },

    translatePdfTemplate(template) {
        template = this.translateTemplate(template)
        //template = template.replaceAll('%option_value_attribute%', '')

        template = template.replaceAll(':href="%value%"', 'href="javascript:;"')
        template = template.replaceAll('background: %value%', 'background: black')

        template = template.replaceAll('widget.properties.style', 'widget.properties.pdf_style ? widget.properties.pdf_style : widget.properties.style')

        const hasImages = template.includes('%foreach_image%');
        if (hasImages) {
            template = template.replaceAll('%image_data%', '');
            template = template.replace('%foreach_image%', '');
            template = template.replace('%end_foreach_image%', '');
        }

        const hasPdfs = template.includes('%foreach_pdf%');
        if (hasPdfs) {
            template = template.replaceAll('%pdf_data%', '');
            template = template.replace('%foreach_pdf%', '');
            template = template.replace('%end_foreach_pdf%', '');
        }
        //console.log(template)

        template = template.replaceAll("%selected_option_class%", "")
        template = template.replaceAll("%checked_option_class%", "")

        return template;
    },

    //unique ID generating based on timestamp
    generateUniqueWidgetId(widgetType) {
        var typeLower = widgetType.toLowerCase();
        return typeLower + '_' + Date.now();
    },

    generateUniqueFieldId() {
        return Date.now();
    }

}
export default widgetsUtility;
