import widgetsUtility from '@/widgetsUtility'

const IdWidget = {
    name: 'IdWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Id')
};

const IdWidgetPdf = {
    name: 'IdWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Id')
};

export { IdWidget, IdWidgetPdf };
