<template>
    <div class="last-saved" v-show="lastSavedAt !== ''">
        <i class="clock-icon"></i>
        <span>{{ $t('form_builder.last_saved_at_time', { time : lastSavedAt }) }}</span>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'LastSaved',
    data: () => {
        return {

        }
    },
    computed: {
        lastSavedAt() {
            return this.formStore.lastSavedAt
        },
        ...mapStores(useFormStore)
    }
}
</script>

<style lang="scss" scoped>
.last-saved {
    font-size: $font-size-small;

    .clock-icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('~@/assets/images/clock-icon.svg');
        vertical-align: middle;
        margin-inline-end: 4px;
    }
}
</style>
