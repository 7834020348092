import widgetsUtility from '@/widgetsUtility'

const EmailWidget = {
    name: 'EmailWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Email')
};

const EmailWidgetPdf = {
    name: 'EmailWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Email')
};

export { EmailWidget, EmailWidgetPdf };
