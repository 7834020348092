<template>
    <div class="tabs-navigation">
        <a href="javascript:;" v-for="(item, index) of linksData"
                :key="index" @click="linkClicked(index)"
                :class="activeLink == index && 'active'">{{ item }}</a>
    </div>
</template>

<script>
export default {
    name: 'TabsNavigation',
    props: ['linksData', 'activeLink'],
    emits: ['linkClicked'],
    methods: {
        linkClicked(index) {
            this.$emit('linkClicked', index)
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs-navigation {
    display: flex;
    gap: 10px;
    width: auto;
    flex-flow: row wrap;

    a {
        flex-grow: 0;
        border-radius: $default-border-radius;
        border: 1px solid $brand-color;
        padding: 8px 20px;
        box-sizing: border-box;
        white-space: nowrap;
        text-decoration: none;
        color: $text-color;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 100px;
        max-width: 220px;

        &.active {
            background: $brand-color;
            font-weight: $font-weight-bold;
        }
    }
}
</style>
