<template>
    <div class="heading-level-property">
        <label>{{ $t('form_builder.heading_level') }}</label>

        <select v-model="levelValue">
            <option v-for="(item, key) in levelValues" :key="key" :value="key">{{ $t('form_builder.heading_' + item) }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'HeadingLevelProperty',
    props: ["property", "value"],
    data() {
        return {
            levelValue: parseInt(this.value),
            levelValues: {
                1: 'H1',
                2: 'H2',
                3: 'H3',
                4: 'H4',
                //5: 'H5',
                //6: 'H6'
            }
        }
    },
    watch: {
        levelValue(newValue) {
            this.$emit('updateContent', this.property, newValue);
        }
    },
};
</script>

<style>
</style>
