<template>
    <div class="style-view">
        <div class="inner-wrapper">
            <h3 class="sidebar-title">{{ $t('form_builder.configurations_style_view_title') }}</h3>
            <p class="sidebar-subtitle">
                {{ $t('form_builder.configurations_style_view_subtitle') }}
            </p>
            <div class="form-wrapper">
                <div class="form-group">
                    <label for="default-font-input">{{ $t('form_builder.configurations_default_font_family') }}: *</label>
                    <select name="default_font" id="default-font-input" v-model="fontValue" class="form-control">
                        <option v-for="(font, key) in fonts" :value="font" :key="key" :style="{'font-family': font}">{{ key }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="default-font-input">{{ $t('form_builder.configurations_default_font_size') }}: *</label>
                    <select name="font-size" v-model="fontsizeValue" class="form-control">
                        <option v-for="(size, key) in fontSizes" :value="key" :key="key">{{ size }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

export default {
    name: 'StyleView',
    props: ['formData', 'isOpen'],
    data: function() {
        return {
            fontValue: '',
            fonts: {
                "Arial": "arial, helvetica, sans-serif",
                "Comic Sans MS": "'comic sans ms', sans-serif",
                "Courier New": "'courier new', courier, monospace",
                "Georgia": "georgia, palatino, serif",
                "Impact": "impact, sans-serif",
                "Tahoma": "tahoma, arial, helvetica, sans-serif"
            },
            fontsizeValue: '',
            fontSizes: {
                12: '12px',
                14: '14px',
                16: '16px',
                18: '18px',
                20: '20px',
                24: '24px'
            }
        }
    },
    computed: {
        ...mapStores(useFormStore)
    },
    created() {
        this.fontsizeValue = this.formStore.formData.font_size ? parseInt(this.formStore.formData.font_size.replace('px', '')) : 16
        this.fontValue = this.formStore.formData.font
    },
    watch: {
        fontValue(newValue, oldValue) {
            if (newValue != oldValue && oldValue != '') {
                this.formStore.updateConfiguration('font', newValue)
            }
        },
        fontsizeValue(newValue, oldValue) {
            if (newValue != oldValue && oldValue != '') {
                this.formStore.updateConfiguration('font_size', newValue + 'px')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.style-view {
    padding: 20px 10px;
    box-sizing: border-box;
    height: 100%;
    display: flex;

    .inner-wrapper {
        flex-grow: 1;
        overflow-y: auto;
        direction: ltr;
        padding: 0 10px;

        & > * {
            direction: rtl;
        }
    }
}
</style>
