import { defineStore } from 'pinia'
import axios from 'axios'
import i18n from '@/i18n'
import widgetsUtility from '@/widgetsUtility.js';

const formsEndpoint = process.env.VUE_APP_API_BASE + '/forms';

const UPLOADS_PUBLIC_URL = process.env.VUE_APP_API_BASE + '/images';

export const useFormStore = defineStore({
    id: 'form',
    state: () => ({
        activeSidebarView: 'WIDGETS_VIEW',
        activePreviewView: 'WEB_VIEW',
        activeDeviceView: 'DESKTOP',
        activeWidgetsView: 'LIST',

        lastSavedAt: '',

        formData: {},
        activeWidget: {},
        activeWidgetIndex: -1,

        hasUnsavedChanges: false
    }),
    actions: {
        widgetEditorClosed() {

        },

        async loadForm(formId) {
            try {
                const response = await axios.get(formsEndpoint + '/' + formId)
                this.formData = response.data
            }
            catch (error) {
                throw(error)
                return false
            }
            this.activeSidebarView = 'WIDGETS_VIEW'
            this.activePreviewView = 'WEB_VIEW'
            this.activeDeviceView = 'DESKTOP'
            this.activeWidgetsView = 'LIST'

            this.lastSavedAt = ''

            this.activeWidget = {}
            this.activeWidgetIndex = -1
            //this.hasUnsavedChanges = false
        },

        setActiveWidgetsView(view) {
            this.activeWidgetsView = view
        },
        setActiveSidebarView(view) {
            if (view !== 'EDITOR_VIEW') {
                this.unsetActiveWidget()
            }
            this.activeSidebarView = view
        },
        setActivePreviewView(view) {
            this.activePreviewView = view
        },
        setActiveDeviceView(view) {
            this.activeDeviceView = view
        },
        setActiveWidget(index) {
            if (index >= this.formData.form_schema.length) {
                console.log('Invalid index at edit widget: '+index)
                return
            }
            this.activeWidget = this.formData.form_schema[index]
            this.activeWidgetIndex = index
            this.activeSidebarView = 'EDITOR_VIEW'
        },
        unsetActiveWidget() {
            if (this.activeWidgetIndex !== -1) {
                this.saveChanges()
            }
            this.activeWidget = {}
            this.activeWidgetIndex = -1
            this.activeSidebarView = 'WIDGETS_VIEW'
        },
        updateFormName(newName) {
            this.formData.name = newName
        },

        saveChanges(callback = null) {
            //console.log(this.formData)
            //this.hasUnsavedChanges = false
            return axios.put(formsEndpoint + '/' + this.formData._id, this.formData)
                .then(response => {
                    var date = new Date();
                    this.lastSavedAt = ("0" + date.getHours()).slice(-2)+":"+("0" + date.getMinutes()).slice(-2)
                    if (callback) {
                        callback()
                    }
                }).catch(error => {
                    //console.log('Error saving form');
                    //console.log(error)
                    //this.hasUnsavedChanges = true

                    if (error.response?.status == 402) {
                        if (error.response?.data?.message) {
                            alert(this.$t('form_builder.' + error.response.data.message))
                            //this.loadForm(this.formData._id)
                        }
                    }
                    else {
                        alert('Error saving form')
                        console.log(error)
                        //this.loadForm(this.formData._id)
                    }
                })
        },

        updateConfiguration(config, value) {
            this.formData[config] = value
        },

        addPdfStyleToWidget() {
            this.activeWidget.properties['pdf_style'] = JSON.parse(JSON.stringify(this.activeWidget.properties.style))
        },
        removePdfStyleFromWidget() {
            delete this.activeWidget.properties.pdf_style
        },

        //Widget functions
        __formatWidgetFields(fields) {
            for (var field of fields) {
                field.label = i18n.global.t('form_builder.' + field.reference_key);

                if (field.hasOwnProperty('options')) {
                    const translatedOptions = i18n.global.t('form_builder.' + field.reference_key + '_options');
                    const optionsArray = translatedOptions.split('^');
                    field.options = optionsArray;
                }
            }
        },

        __formatWidget(widgetObj, widgetName) {

            if (widgetObj.properties.hasOwnProperty('content')) {
                const contentObj = widgetObj.properties.content

                if (contentObj.hasOwnProperty('label')) {
                    contentObj.label = i18n.global.t('form_builder.' + widgetName);
                }
                else if (contentObj.hasOwnProperty('text')) {
                    contentObj.text = i18n.global.t('form_builder.' + widgetName);
                }
                else if (contentObj.hasOwnProperty('html')) {
                    contentObj.html = '<p>' + i18n.global.t('form_builder.' + widgetName) + '</p>';
                    if (this.$state.formData.type === 'TWO_STEP' && !contentObj.hasOwnProperty('editable')) {
                        contentObj['editable'] = true
                    }
                }
            }
            else if (widgetObj.properties.hasOwnProperty('image')) {
                const imageObj = widgetObj.properties.image
                imageObj.image_source = UPLOADS_PUBLIC_URL + '/placeholder.jpg';
            }
            else if (widgetObj.properties.hasOwnProperty('input')) {
                const inputObj = widgetObj.properties.input

                if (inputObj.hasOwnProperty('label')) {
                    inputObj.label = i18n.global.t('form_builder.' + widgetName);
                }
                if (inputObj.hasOwnProperty('options') && inputObj.options.length === 0) {
                    inputObj.options.push(i18n.global.t('form_builder.option_default_text') + ' 1');
                    inputObj.options.push(i18n.global.t('form_builder.option_default_text') + ' 2');
                }
            }
            else if (widgetObj.properties.hasOwnProperty('fields')) {
                this.__formatWidgetFields(widgetObj.properties.fields)
            }

            if (widgetObj.properties.hasOwnProperty('style')) {

            }

        },
        addWidget(widgetName, index = null) {

            const newWidget = widgetsUtility.getNewWidget(widgetName);

            this.__formatWidget(newWidget, widgetName)

            if (index == null) {
                index = this.formData.form_schema.length;
            }

            //console.log(newWidget)

            this.formData.form_schema.splice(index, 0, newWidget);
            this.editWidget(index);

            if (widgetName == 'Pagebreak') {
                this.setActivePreviewView('PDF_VIEW')
            }
        },
        moveWidget(fromIndex, toIndex = null) {

            if (toIndex == null) {
                toIndex = this.formData.form_schema.length;
            }

            if (fromIndex == toIndex || toIndex < 0) {
                return;
            }

            const widgetData = JSON.parse(JSON.stringify(this.formData.form_schema[fromIndex]));
            this.removeWidget(fromIndex, false);
            /*
            if (fromIndex < toIndex) {
                this.formData.form_schema.splice(toIndex, 0, widgetData);
            }
            else if (fromIndex > toIndex) {
                this.formData.form_schema.splice(toIndex, 0, widgetData);
            }
            */
            this.formData.form_schema.splice(toIndex, 0, widgetData);
            this.editWidget(toIndex)
        },
        editWidget(index) {
            if (this.activeWidgetIndex !== -1) {
                this.unsetActiveWidget()
            }
            this.setActiveWidget(index)
        },
        removeWidget(index, save = true) {
            this.formData.form_schema.splice(index, 1)
            this.unsetActiveWidget()

            if (save) {
                this.saveChanges()
            }
        },
        cloneWidget(index) {
            const widgetData = JSON.parse(JSON.stringify(this.formData.form_schema[index]));
            this.formData.form_schema.splice(index+1, 0, widgetData);
            //@TODO replace to ID generator
            this.formData.form_schema[index+1]['id'] = Math.floor(Math.random() * 10000);
            this.editWidget(index+1);
        },
        moveWidgetUp(index) {
            alert('Move widget up')
        },
        moveWidgetDown(index) {
            alert('Move widget down')
        },
        updateWidgetProperty(propertySection, propertyKey, propertyValue) {
            if (propertyKey == null) {
                this.activeWidget.properties[propertySection] = propertyValue
                return
            }

            if (propertySection === 'display') {
                if (propertyValue === true) {
                    if (propertyKey === 'showInWebView') {
                        this.setActivePreviewView('WEB_VIEW')
                    }
                    else if (propertyKey === 'showInPdfView') {
                        this.setActivePreviewView('PDF_VIEW')
                    }
                }
            }
            this.activeWidget.properties[propertySection][propertyKey] = propertyValue
        },
        publishForm() {
            return axios.post(formsEndpoint + '/' + this.formData._id + '/publish')
        },

        deleteForm(identifier) {
            return axios.delete(formsEndpoint + '/' + identifier)
        },

        shareByEmail(identifier, emails, message) {
            return axios.post(formsEndpoint + '/' + identifier + '/share', {
                emails: emails,
                message: message
            })
        }
    }
});
