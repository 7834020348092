<template>
    <div class="image-source-property">
        <div>
            <label for="color">{{ $t('form_builder.image_source') }}</label>
            <!--<input type="text" name="image_source" v-model="imageSource" disabled />-->
        </div>

        <img :src="imageSource" width="200" style="display: block; width: auto; height: auto; max-width: 200px; max-height: 200px;" />

        <label class="upload-btn-label btn btn-primary" @click="resetFile">
            {{ $t('form_builder.choose_image') }}
            <input type="file" id="image-upload-input" name="image_file"
                accept=".png,.jpg,.jpeg,.gif"
                hidden @change="uploadFile" />
        </label>


        <!--
        <a href="javascript:;" @click="chooseImageClicked">{{ $t('form_builder.choose_image') }}</a>

        <OverlayModal :title="$t('form_builder.media_manager_title')"
                :submitText="$t('form_builder.submit_media_image')"
                @modalClosed="closeModal"
                @modalSubmitted="submitModal"
                v-if="modalOpen === true">

            <MediaManager />

        </OverlayModal>
        -->

    </div>
</template>

<script>
//import OverlayModal from '@/components/global/OverlayModal.vue'
//import MediaManager from '@/components/form-builder/MediaManager.vue'
const uploadEndpoint = process.env.VUE_APP_API_BASE + '/uploads/image'; //store endpoint
const uploadsPublicUrl = process.env.VUE_APP_API_BASE + '/images'; //serving

export default {
    name: 'ImageSourceProperty',
    props: ["property", "value"],
    components: {
        //OverlayModal,
        //MediaManager
    },
    data() {
        return {
            imageSource: this.value,
            modalOpen: false
        }
    },
    watch: {
        imageSource(newValue) {
            this.$emit('updateProperty', this.property, newValue);
        }
    },
    methods: {
        /*
        chooseImageClicked() {
            this.modalOpen = true;
        },
        closeModal() {
            this.modalOpen = false;
        },
        */

        resetFile() {
            document.getElementById('image-upload-input').value = '';
        },
        uploadFile(event) {

            const myFile = event.target.files[0];

            var formData = new FormData()
            formData.append("image_file", myFile)

            this.axios.post(uploadEndpoint, formData)
                .then(response => {
                    console.log(response)
                    this.imageSource = uploadsPublicUrl + '/' + response.data.url
                }).catch(error => {
                    console.log(error)
                })
        }
    }
};
</script>

<style lang="scss" scoped>
.upload-btn-label {
    color: blue !important;
    cursor: pointer;
    text-decoration: underline !important;
}
</style>
