<template>
    <div class="content-property">
        <input type="checkbox" name="required" v-model="requiredValue" />&nbsp;
        <label for="required" style="margin: 0">{{ $t('form_builder.required') }}</label>
    </div>
</template>

<script>
export default {
    name: 'RequiredProperty',
    props: ["property", "value"],
    data() {
        return {
            requiredValue: this.value
        }
    },
    watch: {
        requiredValue(newValue) {
            this.$emit('updateProperty', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
