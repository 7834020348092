<template>
    <div class="collapse-section" :class="isOpen && 'active'">
        <div class="collapse-section__title" v-on:click="collapseToggle">
            <slot name="title"></slot>
            <span class="collapse-icon">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5288 6.19517L8.00014 9.72384L4.47148 6.19517L3.52881 7.13784L7.05748 10.6665C7.30751 10.9165 7.64659 11.0569 8.00014 11.0569C8.35369 11.0569 8.69277 10.9165 8.94281 10.6665L12.4715 7.13784L11.5288 6.19517Z" fill="#313131"/>
                </svg>
            </span>
        </div>
        <div class="collapse-section__body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CollapseSection',
    props: ['isOpen'],
    emits: ['collapseOpen', 'collapseClose'],
    methods: {
        collapseToggle() {
            if (this.isOpen) {
                //this.$emit('collapseClose')
            }
            else {
                this.$emit('collapseOpen')
            }
        }
    }
}
</script>

<style lang="scss">

.collapse-section {

    &__title {
        cursor: pointer;
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: 5px;

        & > * {
            flex-grow: 0;
        }
    }

    &__body {
        height: auto;
        max-height: 500px;

        -webkit-transition: max-height 0.3s;
        -moz-transition: max-height 0.3s;
        -ms-transition: max-height 0.3s;
        transition: max-height 0.3s;
    }

    &:not(.active) {
        .collapse-section__body {
            max-height: 0px !important;
            overflow: hidden !important;
        }
    }
    &.active {
        .collapse-section__title {
            cursor: default;
        }

        .collapse-icon {
            svg {
                display: none;
                /*transform: rotate(180deg);*/
            }
        }
    }
}

/*
.collapse-section {

    margin: 10px 0 20px;
    height: auto;
    max-height: 500px;
    overflow: hidden;
    position: relative;

    -webkit-transition: max-height 0.1s;
    -moz-transition: max-height 0.1s;
    -ms-transition: max-height 0.1s;
    transition: max-height 0.1s;

    &:not(.active) {
        max-height: 60px;
    }

    &.active {
        span.expand-icon {
            display: none;
        }
    }

    &__title {

        a {
            text-decoration: none;
            color: #333;
            display:block;
            background: #fff;
            line-height: 40px;
            border-top: 1px solid $gray-border-color;
            border-bottom: 1px solid $gray-border-color;
            padding: 0 10px;
        }

        span.expand-icon {
            position: absolute;
            left: 15px;
            top: 20px;
            width: 12px;
            height: 12px;
        }

    }
}
*/
</style>
