import widgetsUtility from '@/widgetsUtility'

const ContentWidget = {
    name: 'ContentWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Content')
};

const ContentWidgetPdf = {
    name: 'ContentWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Content')
};

export { ContentWidget, ContentWidgetPdf };
