<template>
    <div class="data-table-component">
        <table class="data-table" v-if="dataSet.length > 0">
            <thead>
                <th v-for="(item, key) of columns" :key="key" v-on:click="sortByColumn(key)">
                    {{ item }}
                </th>
                <th v-if="actionColumnName">
                    {{ actionColumnName }}
                </th>
            </thead>
            <tbody>
                <tr v-for="record of dataSet" :key="record">
                    <td v-for="(item, key) of columns" :key="record[key]">

                        <template v-if="record[key]['display_type'] == 'text'">{{ record[key]['value'] }}</template>

                        <template v-else-if="record[key]['display_type'] == 'date'">{{ formatDateValue(record[key]['value']) }}</template>

                        <template v-else-if="record[key]['display_type'] == 'signature'">
                            <template v-if="record[key]['value'].length > 0">
                                SIGNED
                            </template>
                            <template v-else>
                                NOT SIGNED
                            </template>
                        </template>

                        <template v-else-if="record[key]['display_type'] == 'files'">
                            <template v-if="record[key]['value'].split('|').length > 0">
                                <a href="javascript:;" v-on:click="showFiles(record[key]['value'].split('|'))">
                                    {{ record[key]['value'].split('|').length }} files
                                </a>
                            </template>
                            <template v-else>
                                0 files
                            </template>
                        </template>

                        <template v-else-if="record[key]['display_type'] == 'file'">
                            <a :href="record[key]['value'] + '?token=' + authStore.token" target="_blank">{{ $t('data_table.view_file') }}</a>
                        </template>

                        <template v-else>
                            ***
                        </template>

                    </td>

                    <td v-if="actionColumnName">
                            <a href="javascript:;" v-on:click="$emit('actionTriggered', record['_id']['value'])">{{ actionColumnValue }}</a>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="pagination" v-if="pagesNum > 1">
            <a href="javascript:;" v-for="currentPage in [...Array(pagesNum).keys()]" class="pagination__page-link"
                    :class="currentPage+1 == page && 'active'"
                    :key="currentPage" v-on:click="goToPage(currentPage+1)">{{ currentPage+1 }}</a>
        </div>

        <div class="results-counter" v-if="totalCount > 0">
            {{ $t('data_table.results_counter_showing_results') }}
            {{ $t('data_table.results_counter_from') }}
            {{ (page - 1) * resultsPerPage + 1 }}
            {{ $t('data_table.results_counter_to') }}
            {{ (page - 1) * resultsPerPage + dataSet.length }}
            {{ $t('data_table.results_counter_total_count_prefix') }}
            {{ totalCount }}
            {{ $t('data_table.results_counter_total_count_suffix') }}
        </div>

        <OverlayModal class="files-modal" v-if="modalOpen"
                :title="modalTitle"
                :closeText="closeModalText"
                @modalClosed="closeModal">

            <div v-for="item of filesToShow" :key="item">
                <a :href="item + '?token=' + authStore.token" target="_blank">{{ (item.split('/'))[item.split('/').length-1] }}</a>
            </div>

        </OverlayModal>
    </div>
</template>

<script>
import OverlayModal from '@/components/global/OverlayModal'
import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'DataTable',
    props: ['dataUrl', 'actionColumnName', 'actionColumnValue'],
    components: {
        OverlayModal
    },
    data() {
        return {
            page: 1,
            pagesNum: 1,
            totalCount: 0,
            resultsPerPage: 20,

            sortBy: '',
            sortDirection: 1, //1 or -1
            filterText: '',

            dataSet: [], //array of objects, objects in format key: value
            columns: {}, //key: label format

            filesToShow: [],
            modalOpen: false,
            modalTitle: this.$t('data_table.files_modal_title'),
            closeModalText: this.$t('data_tables.close_modal_text')
        }
    },
    computed: {
        ...mapStores(useAuthStore)
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            //fetch data
            //populate dataset and columns
            var finalUrl = this.dataUrl
            finalUrl += '?p=' + this.page + '&resultsPerPage=' + this.resultsPerPage
            finalUrl += '&sortBy=' + this.sortBy + '&sortDirection=' + this.sortDirection
            finalUrl += '&filterText=' + this.filterText

            this.axios.get(finalUrl)
                .then(response => {
                    this.setData(response.data['data'], response.data['totalCount'])
                }).catch(error => {
                    console.log(error)
                })
        },
        setData(rowsData, totalCount) {
            var data = []
            var activatedColumns = {}

            for (var row of rowsData) {
                var currentData = {}
                //console.log(record.form_data)
                for (var fieldKey in row) {
                    activatedColumns[fieldKey] = row[fieldKey]['label']
                    currentData[fieldKey] = row[fieldKey]
                }
                data.push(currentData)
            }

            //console.log(data)
            //console.log(activatedColumns)
            this.dataSet = data
            this.pagesNum = Math.ceil(totalCount / this.resultsPerPage)
            this.totalCount = totalCount
            this.columns = activatedColumns
        },
        formatDateValue(strDate) {
            const date = new Date(strDate)
            const datePart = date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear();
            const timePart = date.getHours()+":"+date.getMinutes();
            return datePart + " " + timePart
        },
        showFiles(filesArray) {
            this.filesToShow = filesArray
            this.modalOpen = true
        },
        closeModal() {
            this.filesToShow = []
            this.modalOpen = false
        },

        sortByColumn(columnKey) {
            if (this.sortBy == columnKey) {
                this.sortDirection = -1 * this.sortDirection
            }
            else {
                this.sortBy = columnKey
                this.sortDirection = 1
            }
            this.fetchData()
        },
        goToPage(page) {
            this.page = page
            this.fetchData()
        }

        /*,
        filterResults() {
            //update filterText?
            this.fetchData()
        }
        */
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/data-table.scss';
</style>
