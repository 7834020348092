<template>
    <div class="text-align-property no-grow">
        <label for="text-align">{{ $t('form_builder.text_align') }}</label>

        <div class="tabs-control" style="width: 102px;">
            <div class="tabs-control__option" :class="textAlignValue === 'left' && 'active'"
                    v-on:click="textAlignValue = 'left'">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_615_6808)">
                <path d="M1 6H23C23.2652 6 23.5196 5.89464 23.7071 5.7071C23.8946 5.51957 24 5.26521 24 5C24 4.73478 23.8946 4.48043 23.7071 4.29289C23.5196 4.10536 23.2652 4 23 4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26521 0.105357 5.51957 0.292893 5.7071C0.48043 5.89464 0.734784 6 1 6Z" fill="#313131"/>
                <path d="M1 11H15C15.2652 11 15.5196 10.8946 15.7071 10.7071C15.8946 10.5196 16 10.2652 16 10C16 9.73478 15.8946 9.48043 15.7071 9.29289C15.5196 9.10536 15.2652 9 15 9H1C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10C0 10.2652 0.105357 10.5196 0.292893 10.7071C0.48043 10.8946 0.734784 11 1 11Z" fill="#313131"/>
                <path d="M15 19H1C0.734784 19 0.48043 19.1053 0.292893 19.2929C0.105357 19.4804 0 19.7348 0 20C0 20.2652 0.105357 20.5195 0.292893 20.7071C0.48043 20.8946 0.734784 21 1 21H15C15.2652 21 15.5196 20.8946 15.7071 20.7071C15.8946 20.5195 16 20.2652 16 20C16 19.7348 15.8946 19.4804 15.7071 19.2929C15.5196 19.1053 15.2652 19 15 19Z" fill="#313131"/>
                <path d="M23 14H1C0.734784 14 0.48043 14.1054 0.292893 14.2929C0.105357 14.4805 0 14.7348 0 15C0 15.2653 0.105357 15.5196 0.292893 15.7071C0.48043 15.8947 0.734784 16 1 16H23C23.2652 16 23.5196 15.8947 23.7071 15.7071C23.8946 15.5196 24 15.2653 24 15C24 14.7348 23.8946 14.4805 23.7071 14.2929C23.5196 14.1054 23.2652 14 23 14Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_615_6808">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </div>


            <div class="tabs-control__option" :class="textAlignValue === 'center' && 'active'"
                    v-on:click="textAlignValue = 'center'">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_615_6818)">
                <path d="M1 6.00001H23C23.2652 6.00001 23.5196 5.89465 23.7071 5.70712C23.8946 5.51958 24 5.26523 24 5.00001C24 4.7348 23.8946 4.48044 23.7071 4.29291C23.5196 4.10537 23.2652 4.00002 23 4.00002H1C0.734784 4.00002 0.48043 4.10537 0.292893 4.29291C0.105357 4.48044 0 4.7348 0 5.00001C0 5.26523 0.105357 5.51958 0.292893 5.70712C0.48043 5.89465 0.734784 6.00001 1 6.00001Z" fill="#313131"/>
                <path d="M4.99982 9C4.7346 9 4.48025 9.10536 4.29271 9.29289C4.10517 9.48043 3.99982 9.73478 3.99982 10C3.99982 10.2652 4.10517 10.5196 4.29271 10.7071C4.48025 10.8946 4.7346 11 4.99982 11H18.9998C19.265 11 19.5194 10.8946 19.7069 10.7071C19.8945 10.5196 19.9998 10.2652 19.9998 10C19.9998 9.73478 19.8945 9.48043 19.7069 9.29289C19.5194 9.10536 19.265 9 18.9998 9H4.99982Z" fill="#313131"/>
                <path d="M18.9998 19H4.99982C4.7346 19 4.48025 19.1053 4.29271 19.2929C4.10517 19.4804 3.99982 19.7348 3.99982 20C3.99982 20.2652 4.10517 20.5195 4.29271 20.7071C4.48025 20.8946 4.7346 21 4.99982 21H18.9998C19.265 21 19.5194 20.8946 19.7069 20.7071C19.8945 20.5195 19.9998 20.2652 19.9998 20C19.9998 19.7348 19.8945 19.4804 19.7069 19.2929C19.5194 19.1053 19.265 19 18.9998 19Z" fill="#313131"/>
                <path d="M23 14H1C0.734784 14 0.48043 14.1054 0.292893 14.2929C0.105357 14.4805 0 14.7348 0 15C0 15.2653 0.105357 15.5196 0.292893 15.7071C0.48043 15.8947 0.734784 16 1 16H23C23.2652 16 23.5196 15.8947 23.7071 15.7071C23.8946 15.5196 24 15.2653 24 15C24 14.7348 23.8946 14.4805 23.7071 14.2929C23.5196 14.1054 23.2652 14 23 14Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_615_6818">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </div>


            <div class="tabs-control__option" :class="textAlignValue === 'right' && 'active'"
                    v-on:click="textAlignValue = 'right'">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_615_6806)">
                <path d="M1 6.00001H23C23.2652 6.00001 23.5196 5.89465 23.7071 5.70712C23.8946 5.51958 24 5.26523 24 5.00001C24 4.7348 23.8946 4.48044 23.7071 4.29291C23.5196 4.10537 23.2652 4.00002 23 4.00002H1C0.734784 4.00002 0.48043 4.10537 0.292893 4.29291C0.105357 4.48044 0 4.7348 0 5.00001C0 5.26523 0.105357 5.51958 0.292893 5.70712C0.48043 5.89465 0.734784 6.00001 1 6.00001Z" fill="#313131"/>
                <path d="M23.0001 9H9.00012C8.73491 9 8.48055 9.10536 8.29302 9.29289C8.10548 9.48043 8.00012 9.73478 8.00012 10C8.00012 10.2652 8.10548 10.5196 8.29302 10.7071C8.48055 10.8946 8.73491 11 9.00012 11H23.0001C23.2653 11 23.5197 10.8946 23.7072 10.7071C23.8948 10.5196 24.0001 10.2652 24.0001 10C24.0001 9.73478 23.8948 9.48043 23.7072 9.29289C23.5197 9.10536 23.2653 9 23.0001 9Z" fill="#313131"/>
                <path d="M23.0001 19H9.00012C8.73491 19 8.48055 19.1053 8.29302 19.2929C8.10548 19.4804 8.00012 19.7348 8.00012 20C8.00012 20.2652 8.10548 20.5195 8.29302 20.7071C8.48055 20.8946 8.73491 21 9.00012 21H23.0001C23.2653 21 23.5197 20.8946 23.7072 20.7071C23.8948 20.5195 24.0001 20.2652 24.0001 20C24.0001 19.7348 23.8948 19.4804 23.7072 19.2929C23.5197 19.1053 23.2653 19 23.0001 19Z" fill="#313131"/>
                <path d="M23 14H1C0.734784 14 0.48043 14.1054 0.292893 14.2929C0.105357 14.4805 0 14.7348 0 15C0 15.2653 0.105357 15.5196 0.292893 15.7071C0.48043 15.8947 0.734784 16 1 16H23C23.2652 16 23.5196 15.8947 23.7071 15.7071C23.8946 15.5196 24 15.2653 24 15C24 14.7348 23.8946 14.4805 23.7071 14.2929C23.5196 14.1054 23.2652 14 23 14Z" fill="#313131"/>
                </g>
                <defs>
                <clipPath id="clip0_615_6806">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </div>

            <!--
            <div class="tabs-control__option" :class="textAlignValue === 'justify' && 'active'"
                    v-on:click="textAlignValue = 'justify'">
                <svg class="tab-icon" width="24" height="24" focusable="false"><path d="M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 110-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2zm0 4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 010-2z" fill-rule="evenodd"></path></svg>
            </div>
            -->
        </div>

        <!--
        <div class="tabs-control" style="direction: ltr; margin-right: 25px;">
            <div class="tabs-control__option" :class="directionValue === 'ltr' && 'active'"
                    v-on:click="directionValue = 'ltr'">
                <svg class="tab-icon" width="24" height="24" focusable="false"><path d="M11 5h7a1 1 0 010 2h-1v11a1 1 0 01-2 0V7h-2v11a1 1 0 01-2 0v-6c-.5 0-1 0-1.4-.3A3.4 3.4 0 017.8 10a3.3 3.3 0 010-2.8 3.4 3.4 0 011.8-1.8L11 5zM4.4 16.2L6.2 15l-1.8-1.2a1 1 0 011.2-1.6l3 2a1 1 0 010 1.6l-3 2a1 1 0 11-1.2-1.6z" fill-rule="evenodd"></path></svg>
            </div>
            <div class="tabs-control__option" :class="directionValue === 'rtl' && 'active'"
                    v-on:click="directionValue = 'rtl'">
                <svg class="tab-icon" width="24" height="24" focusable="false"><path d="M8 5h8v2h-2v12h-2V7h-2v12H8v-7c-.5 0-1 0-1.4-.3A3.4 3.4 0 014.8 10a3.3 3.3 0 010-2.8 3.4 3.4 0 011.8-1.8L8 5zm12 11.2a1 1 0 11-1 1.6l-3-2a1 1 0 010-1.6l3-2a1 1 0 111 1.6L18.4 15l1.8 1.2z" fill-rule="evenodd"></path></svg>
            </div>
        </div>
        -->

    </div>
</template>

<script>
export default {
    name: 'TextAlignmentProperty',
    props: ["property", "value"],
    data() {
        return {
            textAlignValue: JSON.parse(JSON.stringify(this.value))['text-align'] || null,
            directionValue: JSON.parse(JSON.stringify(this.value))['direction'] || null
        }
    },
    watch: {
        textAlignValue(newValue) {
            this.$emit('updateStyle', 'text-align', newValue);
        },
        directionValue(newValue) {
            this.$emit('updateStyle', 'direction', newValue);
        }
    }
};
</script>

<style>
</style>
