import { defineStore } from 'pinia'
import axios from 'axios'
import i18n from '@/i18n'

const authEndpoint = process.env.VUE_APP_API_BASE + '/auth';
const profileEndpoint = process.env.VUE_APP_API_BASE + '/profile';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('token'),
        refresh_token: localStorage.getItem('refresh_token'),
        returnUrl: null
    }),
    actions: {
        __persistUserData() {
            localStorage.setItem('user', JSON.stringify(this.user))
        },
        __persistTokenData() {
            localStorage.setItem('token', this.token)
            localStorage.setItem('refresh_token', this.refresh_token)
        },
        __clearUserData() {
            localStorage.removeItem('user');
        },
        __clearTokenData() {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
        },

        setAuthData(user, token, refreshToken = null) {
            this.user = user
            this.token = token
            this.refresh_token = refreshToken
            this.__persistUserData()
            this.__persistTokenData()
        },
        async resetPassword(email) {
            try {
                const response = await axios.post(authEndpoint + '/reset-password', {
                    'email': email
                })

                if (response.data['result'] === 'success') {
                    return true
                }
                else if (response.data.message == 'email_not_registered') {
                    return false
                }
                else {
                    console.log(response)
                    return 0
                }
            }
            catch (error) {
                console.log(error)
                return 0
            }
        },
        async refreshToken() {
            //console.log('attempting token refresh')
            try {
                const response = await axios.post(authEndpoint + '/refresh-token', {
                    'refresh_token': this.refresh_token
                })
                if (response.data['result'] === 'success') {
                    //alert('success')
                    this.setAuthData(response.data['user'],
                            response.data['token'],
                            response.data['refresh_token'])

                    return true
                }
            }
            catch (error) {
                console.log(error)
                return false
            }
        },
        async verifyPasswordToken(userId, token) {
            try {
                const response = await axios.post(authEndpoint + '/verify-password-token', {
                    'token': token,
                    'user_id': userId
                })
                return response.data['result'] === 'success'
            }
            catch (error) {
                console.log(error)
                return 0
            }
        },
        async setPassword(userId, token, newPassword) {
            try {
                const response = await axios.post(authEndpoint + '/set-password', {
                    'token': token,
                    'user_id': userId,
                    'password': newPassword
                })
                return response.data['result'] === 'success'
            }
            catch (error) {
                console.log(error)
                return 0
            }
        },
        /*
        //Should use refreshUserInfo function instead
        updatePersonalInformation(data) {
            this.user.first_name = data['first_name']
            this.user.last_name = data['last_name']
            this.__persistUserData()
        },
        */
        refreshUserInfo(data = null) {
            if (data == null) {
                //re-fetch user info
                axios.get(profileEndpoint + '/info')
                    .then(response => {
                        if (response.data && response.data._id) {
                            this.user = response.data
                            this.__persistUserData()
                        }
                        else {
                            console.log('Error refreshing user info')
                            console.log(response)
                        }
                    })
                    .catch(err => {
                        console.log('Error refreshing user info')
                        console.log(response)
                    })
            }
            else {
                for (let k in data) {
                    if (this.user[k]) {
                        this.user[k] = data[k]
                    }
                }
                this.__persistUserData()
            }
        },
        logout() {
            this.user = null;
            this.token = null;
            this.__clearUserData()
            this.__clearTokenData()
        }
    }
});
