<template>
    <div id="forms-share-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <router-link to="/forms" class="back-link">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.19541 4.47119L9.72408 7.99986L6.19541 11.5285L7.13808 12.4712L10.6667 8.94252C10.9167 8.69249 11.0571 8.35341 11.0571 7.99986C11.0571 7.64631 10.9167 7.30723 10.6667 7.05719L7.13808 3.52852L6.19541 4.47119Z" fill="#4E2053"/>
                    </svg>
                    <span>{{ $t("forms_share.back_to_forms") }}</span>
                </router-link>

                <div class="inner-container">

                    <div class="summary-section">
                        <h1 class="summary-section__title">{{ $t('forms_share.share_form_title') }}</h1>
                        <div class="summary-section__summary">
                            <div class="summary-section__summary__form-group">
                                <label>{{ $t('forms_share.form_name') }}:&nbsp;</label>
                                <span>{{ formData?.name }}</span>
                            </div>
                            <div class="summary-section__summary__form-group">
                                <label>{{ $t('forms_share.form_type') }}:&nbsp;</label>
                                <span>{{ $t('form_builder.' + formData?.type) }}</span>
                            </div>
                            <div class="summary-section__summary__form-group">
                                <label>{{ $t('forms_share.form_language') }}:&nbsp;</label>
                                <span>{{ $t('form_builder.' + formData?.language) }}</span>
                            </div>

                            <div class="summary-section__summary__form-group">
                                <label>{{ $t('forms_share.form_publish_date') }}:&nbsp;</label>
                                <span>{{ formatDate(formData?.published_at) }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="share-section">
                        <template v-if="formData?.type == 'ONE_STEP'">
                            <h2 class="share-section__title">{{ $t('forms_share.how_do_you_want_to_share_title') }}</h2>
                            <div class="share-section__share-grid">

                                <div class="share-section__share-grid__share-block share-block-link">
                                    <ShareByLink :shareUrl="shareUrl" />
                                </div>

                                <div class="share-section__share-grid__share-block share-block-whatsapp">
                                    <ShareByWhatsapp :shareUrl="shareUrl" />
                                </div>

                                <div class="share-section__share-grid__share-block share-block-email">
                                    <ShareByEmail :shareUrl="shareUrl" />
                                </div>
                            </div>
                        </template>

                        <template v-if="formData?.type == 'TWO_STEP'">
                            <template v-if="isSecondStep">
                                <h2 class="share-section__title">{{ $t('forms_share.share_form_step_2_title') }}</h2>
                                <div class="share-section__share-grid">

                                    <div class="share-section__share-grid__share-block share-block-link">
                                        <ShareByLink :shareUrl="shareUrl" />
                                    </div>

                                    <div class="share-section__share-grid__share-block share-block-whatsapp">
                                        <ShareByWhatsapp :shareUrl="shareUrl" />
                                    </div>

                                    <div class="share-section__share-grid__share-block share-block-email">
                                        <ShareByEmail :shareUrl="shareUrl" />
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <h2 class="share-section__title">{{ $t('forms_share.share_form_step_1_title') }}</h2>
                                <div class="share-section__share-grid--single-column">
                                    <div class="share-section__share-grid__share-block share-block-link">
                                        <p>{{ $t('forms_share.share_form_step_1_description') }}</p>
                                        <ShareByLink :shareUrl="shareUrl"
                                                :hideTitle="true"
                                                :allowLinkOpen="true" />
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>

                </div>
            </section>
        </div>
    </div>
</template>

<script>
import ShareByLink from '@/components/dashboard/share/ShareByLink.vue'
import ShareByWhatsapp from '@/components/dashboard/share/ShareByWhatsapp.vue'
import ShareByEmail from '@/components/dashboard/share/ShareByEmail.vue'

const formsEndpoint = process.env.VUE_APP_API_BASE + '/forms';
const shareUrlBase = process.env.VUE_APP_API_BASE + '/ui'

export default {
    name: 'FormsShare',
    components: {
        ShareByLink,
        ShareByWhatsapp,
        ShareByEmail
    },
    data: () => {
        return {
            formData: null,
            shareUrl: ''
        }
    },
    created() {
        this.axios.get(formsEndpoint + '/' + this.$route.params.id)
            .then(response => {

                this.formData = response.data

                if (this.formData.type == 'TWO_STEP') {
                    if (this.isSecondStep) {
                        this.recordId = this.$route.query.recordId
                        this.shareUrl = shareUrlBase + '/records/' + response.data._id + '/' + this.recordId + '/fill'
                    }
                    else {
                        this.shareUrl = shareUrlBase + '/forms/' + response.data._id + '/create'
                    }
                }
                else {
                    this.shareUrl = shareUrlBase + '/forms/' + response.data._id + '/fill'
                }

                if (this.formData.status !== 'PUBLISHED') {
                    alert('Invalid URL - Form not published')
                    this.$router.push({ path: '/form-builder/' + this.route.params.id })
                }

            }).catch(error => {
                console.log(error)
                alert('Error occurred - could not fetch specified form data')
            })
    },
    computed: {
        isSecondStep() {
            return this.$route.query.recordId && this.$route.query.recordId.length > 0
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.inner-container {
    width: 100%;
    max-width: 946px;
    margin:0 auto;
    padding: 20px 0;
    box-sizing: border-box;

    & > * {
        padding: 0 10px;
    }
}

.summary-section {
    margin-bottom: 40px;

    &__title {
        line-height: 24px;
        text-align: center;
        font-weight: $font-weight-bold;
        font-size: $font-size-huge;
        margin: 0 0 20px;
    }

    &__summary {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        border: 1px solid $brand-color;
        padding: 14px 20px;
        border-radius: $default-border-radius;
        background-color: $white-color;
        line-height: 24px;
        gap: 0px 30px;

        @media (max-width: $mobile-breakpoint) {
            flex-flow: column;
        }

        &__form-group {
            font-size: $font-size;
            height: 24px;
            line-height: 24px;
            padding: 0;
            margin: 5px 0;

            label {
                margin: 0;
                padding: 0;
            }
            span {
                font-weight: $font-weight-bold;
            }
        }
    }
}

.share-section {
    &__title {
        font-size: $font-size-big;
        line-height: 24px;
        text-align: center;
        margin: 0 0 15px;
    }

    &__share-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;

        &--single-column {
            grid-template-columns: 1fr;
        }

        &__share-block {
            background: $white-color;
            border: 1px solid $brand-color;
            border-radius: $default-border-radius;
            padding: 20px;
            min-height: 116px;
            box-sizing: border-box;
            padding: 20px;
            min-width: 0;

            &.share-block-email {
                grid-column: span 2;
                padding: 30px;
            }

            @media (max-width: $mobile-breakpoint) {
                grid-column: span 2;
            }

            .share-block-content {

            }
        }
    }
}
</style>
