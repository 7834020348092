import FormsIndex from '@/views/FormsIndex.vue';
import FormsCreate from '@/views/FormsCreate.vue';
import FormsShare from '@/views/FormsShare.vue';
import FormRecords from '@/views/FormRecords.vue';
import FormBuilder from '@/views/FormBuilder.vue';

const routes = [
    {
        path: '/forms',
		name: 'FormsIndex',
		component: FormsIndex,
        meta: { layout: 'DashboardLayout' }
    },
	{
		path: '/forms/create',
		name: 'FormsCreate',
		component: FormsCreate,
        meta: { layout: 'FormBuilderLayout' }
	},
    {
        path: '/forms/:id/share',
        name: 'FormsShare',
        component: FormsShare,
        meta: { layout: 'DashboardLayout' }
    },
    {
        path: '/forms/:id/records',
        name: 'FormRecords',
        component: FormRecords,
        meta: { layout: 'DashboardLayout' }
    },
    {
        path: '/form-builder/:id',
        name: 'FormBuilder',
        component: FormBuilder,
        meta: { layout: 'FormBuilderLayout' }
    }
];

export default routes
