import widgetsUtility from '@/widgetsUtility'

const AddressinfoWidget = {
    name: 'AddressinfoWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Addressinfo')
};

const AddressinfoWidgetPdf = {
    name: 'AddressinfoWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Addressinfo')
};

export { AddressinfoWidget, AddressinfoWidgetPdf };
