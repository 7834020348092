<template>
    <div class="font-size-property">
        <label>{{ $t('form_builder.font_size') }}</label>
        <select name="font-size" v-model="fontsizeValue" class="form-control">
            <option value="">{{ $t('form_builder.default') }}</option>
            <option v-for="(size, key) in fontSizes" :value="key" :key="key">{{ size }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'FontSizeProperty',
    props: ["property", "value"],
    data() {
        return {
            fontSizes: {
                10: '10px',
                12: '12px',
                14: '14px',
                16: '16px',
                18: '18px',
                20: '20px',
                24: '24px',
                32: '32px',
                40: '40px',
                52: '56px',
                72: '72px'
            },
            fontsizeValue: this.value['font-size'] ? parseInt(this.value['font-size']?.replace('px', '')) : ''
        }
    },
    watch: {
        fontsizeValue(newValue) {
            var myValue = newValue;
            if (isNaN(newValue) || newValue == 0 || newValue == '') {
                myValue = ''
            }
            else {
                myValue = newValue + 'px'

            }
            this.$emit('updateStyle', 'font-size', myValue)
        }
    }
};
</script>

<style>
</style>
