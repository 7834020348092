import widgetsUtility from '@/widgetsUtility'

const SelectWidget = {
    name: "SelectWidget",
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Select')
};

const SelectWidgetPdf = {
    name: "SelectWidgetPdf",
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Select')
};

export { SelectWidget, SelectWidgetPdf };
