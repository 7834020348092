import AuthLogin from '@/views/AuthLogin.vue';
import AuthRegister from '@/views/AuthRegister.vue';
import AuthPassword from '@/views/AuthPassword.vue';
import AuthPasswordChange from '@/views/AuthPasswordChange.vue';

const routes = [
    {
        path: '/auth/login',
		name: 'AuthLogin',
		component: AuthLogin,
        meta: { layout: 'AuthLayout' }
    },
	{
		path: '/auth/register',
		name: 'AuthRegister',
		component: AuthRegister,
        meta: { layout: 'AuthLayout' }
	},
    {
		path: '/auth/password',
		name: 'AuthPassword',
		component: AuthPassword,
        meta: { layout: 'AuthLayout' }
	},
    {
        path: '/auth/password-change/:userId/:token',
        name: 'AuthPasswordChange',
        component: AuthPasswordChange,
        meta: { layout: 'AuthLayout' }
    }
];

export default routes
