import widgetsUtility from '@/widgetsUtility'

const TimeWidget = {
    name: 'TimeWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Time')
};


const TimeWidgetPdf = {
    name: 'TimeWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Time')
};

export { TimeWidget, TimeWidgetPdf };
