<template>
    <div>
        <NavigationMenu />
        <slot />
    </div>
</template>

<script>

import NavigationMenu from "@/components/admin/NavigationMenu";

export default {
    name: 'AdminLayout',
    components: {
        NavigationMenu
    }
}
</script>

<style lang="scss">
/*@import '@/assets/style/dashboard.scss';*/
</style>
