import widgetsUtility from '@/widgetsUtility'

const PhoneWidget = {
    name: 'PhoneWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Phone')
};

const PhoneWidgetPdf = {
    name: 'PhoneWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Phone')
};

export { PhoneWidget, PhoneWidgetPdf };
