<template>
    <div class="color-property">
        <label for="color">{{ $t('form_builder.color') }}</label>
        <input type="text" class="text-color" v-model="colorValue" />
        <input type="color" name="color" v-model="colorValue" />
    </div>
</template>

<script>
export default {
    name: 'ColorProperty',
    props: ["property", "value"],
    data() {
        return {
            colorValue: this.value
        }
    },
    watch: {
        colorValue(newValue) {
            if (newValue.charAt(0) !== '#') {
                this.colorValue = '#' + newValue
            }
            if (this.colorValue.length === 7) {
                this.$emit('updateStyle', this.property, newValue);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.color-property {
    position: relative;
}

input[type="color"] {
    border: none;
    position: absolute;
    background: transparent;
    box-sizing: content-box;
    width: 15px;
    height: 15px;
    bottom: 8px;
    right: 10px;
    cursor: pointer;
    border: 1px solid #B2B2B2;
    border-radius: 3px;
    padding: 0;
}

input.text-color[type="text"] {
    display: block;
    padding-right: 35px;
    text-transform: uppercase;
}

*:focus{
  border-radius: 0;
  outline: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch{
  border: 0;
  border-radius: 0;
}

::-moz-color-swatch,
::-moz-focus-inner{
  border: 0;
}

::-moz-focus-inner{
  padding: 0;
}
</style>
