import widgetsUtility from '@/widgetsUtility'

const DateWidget = {
    name: 'DateWidget',
    props: ["widget"],
    template: widgetsUtility.getHtmlTemplate('Date')
};


const DateWidgetPdf = {
    name: 'DateWidgetPdf',
    props: ["widget"],
    template: widgetsUtility.getPdfTemplate('Date')
};

export { DateWidget, DateWidgetPdf };
