<template>
    <div class="image-width-property">
        <label for="width">{{ $t('form_builder.image_width') }}</label>
        <input type="number" v-model="widthValue" name="width" class="input-small" />
    </div>
</template>

<script>
export default {
    name: 'ImageWidthProperty',
    props: ["property", "value"],
    data() {
        return {
            widthValue: this.value
            //contentValue: this.value
        }
    },
    watch: {
        widthValue(newValue) {
            this.$emit('updateProperty', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
