<template>
    <div>
        <label style="display:none;">{{ $t('form_builder.settings_widget_visibility') }}</label>

        <select v-if="widget.type === 'content' && widget.widget !== 'Pagebreak'" v-model="selectedValue" v-on:change="updateValue">
            <option value="both">{{ $t('form_builder.settings_visibility_both') }}</option>
            <option value="web">{{ $t('form_builder.settings_visibility_web') }}</option>
            <option value="pdf">{{ $t('form_builder.settings_visibility_pdf') }}</option>
        </select>

        <select v-else-if="widget.widget === 'Pagebreak'" disabled>
            <option selected>{{ $t('form_builder.settings_visibility_pdf') }}</option>
        </select>

        <select v-else-if="widget.type === 'input' || widget.type === 'fields_group'" v-model="selectedValue" v-on:change="updateValue">
            <option value="both">{{ $t('form_builder.settings_visibility_both') }}</option>
            <option value="web">{{ $t('form_builder.settings_visibility_web') }}</option>
        </select>

    </div>
</template>

<script>
export default {
    name: 'VisibilityProperty',
    props: ['widget', 'property', 'value'],
    emits: ['updateVisibility'],
    data() {
        return {
            selectedValue: 'both'
        }
    },
    mounted() {
        this.updateSelectedValue()
    },
    watch: {
        widget(newWidget) {
            this.updateSelectedValue()
        }
    },
    methods: {
        updateSelectedValue() {

            const showInWeb = this.widget.properties.display.hasOwnProperty('showInWebView') ? this.widget.properties.display.showInWebView : true
            const showInPdf = this.widget.properties.display.hasOwnProperty('showInPdfView') ? this.widget.properties.display.showInPdfView : true

            if (showInWeb) {
                this.selectedValue = showInPdf ? 'both' : 'web'
                return
            }
            else if (showInPdf) {
                this.selectedValue = 'pdf'
                return
            }
            console.log('No selected value for visibility?! -- defaulting to "both"')
            this.selectedValue = 'both'

        },
        updateValue(event) {
            const selectedValue = event.target.value
            this.$emit('updateVisibility', 'visibility', selectedValue)
        }
    }
};
</script>

<style></style>
