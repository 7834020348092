<template>
    <div class="widgets-view" :class="activeView == 'GRID' ? 'grid' : 'list'">

        <div class="widgets-view__top-description sidebar-subtitle">
            <p>{{ $t('form_builder.widgets_view_subtitle') }}</p>
        </div>

        <div class="widgets-view__view-type">
            <button v-on:click="setView('LIST')" class="widgets-view__view-type__list-btn"
                    :class="activeView == 'LIST' && 'active'"></button>
            <button v-on:click="setView('GRID')" class="widgets-view__view-type__grid-btn"
                    :class="activeView == 'GRID' && 'active'"></button>
        </div>

        <div class="widgets-view__search">
            <SearchBox :placeholder="$t('form_builder.search_in_widgets_placeholder')" @search="searchWidgets" />
        </div>

        <div class="widgets-view__widgets">
            <div v-for="(item, index) of filteredWidgets" :key="index" class="widgets-section">
                <h4>{{ $t('form_builder.widgets_section_title_' + index) }}</h4>
                <div class="widgets-holder">
                    <div v-for="widgetKey of item" :key="widgetKey" class="widget-holder">
                        <WidgetBox :widget="widgetKey"
                                :viewType="activeView"
                                draggable="true"
                                @addWidget="addWidget" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchBox from "@/components/global/SearchBox.vue";
import WidgetBox from "@/components/form-builder/sidebar/widgets-view/WidgetBox.vue";

import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/form.js'

const WIDGETS = {
    'content': [
        'Heading',
        'Content',
        'Image',
        //'Button',
        'Separator',
        'Pagebreak'
    ],
    'single_fields': [
        'Textbox',
        'Textarea',

        'Id',
        'Date',
        'Phone',
        'Email',

        'Signature',

        'Radiobutton',
        'Checkbox',
        'Select',

        'PdfUpload',
        'ImageUpload',

        'Number',
        'Url',
        'Time',
        'Color'
    ]
    /*
    'grouped_fields': [
        'Bankinfo',
        'Addressinfo',
        'Daterange'
    ]
    */
}
export default {
    name: 'WidgetsView',
    emits: ['addWidget'],
    components: {
        SearchBox,
        WidgetBox
    },
    data: () => {
        return {
            widgets: WIDGETS,
            filteredWidgets: WIDGETS
        }
    },
    computed: {
        activeView() {
            return this.formStore.$state.activeWidgetsView
        },
        ...mapStores(useFormStore)
    },
    methods: {
        setView(view) {
            this.formStore.setActiveWidgetsView(view)
        },
        addWidget(widget) {
            this.formStore.addWidget(widget)
        },
        searchWidgets(query) {
            if (query == '') {
                this.filteredWidgets = this.widgets
                return
            }
            this.filteredWidgets = {}
            for (let section in this.widgets) {
                for (let widget of this.widgets[section]) {
                    let widgetName = widget.toLowerCase()
                    let translatedName = this.$t('form_builder.' + widget)

                    if (widgetName.includes(query.toLowerCase()) ||
                            translatedName.includes(query.toLowerCase())) {

                        if (!this.filteredWidgets[section]) {
                            this.filteredWidgets[section] = []
                        }
                        this.filteredWidgets[section].push(widget)
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    max-width: 400px;
    margin: 0;
}
.widgets-view {
    position: relative;
    box-sizing: border-box;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    & > * {
        padding: 0 10px;
    }
    &__top-description {
        margin-bottom: 15px;
    }

    &__search {
        margin-bottom: 20px;
    }

    &__view-type {
        margin-bottom: 13px;

        button {
            background-color: transparent;
            border: 1px solid $brand-color;
            width: 35px;
            height: 35px;
            box-sizing: border-box;
            padding: 7px;
            border-radius: 5px;
            outline: transparent;

            background-position: center center;
            background-repeat: no-repeat;
            background-size: 18px 18px;

            &.active {
                background-color: $brand-color;
            }
        }
        &__list-btn {
            background-image: url('~@/assets/images/widgets-view-list.svg');

        }
        &__grid-btn {
            background-image: url('~@/assets/images/widgets-view-grid.svg');
        }

        button + button {
            margin-inline: 5px;
        }
    }
    &__widgets {
        flex-grow: 1;
        overflow-y: auto;
        direction: ltr;

        .widgets-section + .widgets-section {
            margin-top: 25px;
        }
        .widgets-section {
            /*margin-bottom: 25px;*/
            direction: rtl;

            h4 {
                font-size: $font-size-small;
                font-weight: $font-weight-bold;
                margin: 0 0 10px;
            }
        }
    }
}

.widgets-view.list {
    .widgets-holder {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}
.widgets-view.grid {
    .widgets-holder {
        display: grid;
        gap: 10px;
        max-width: 100%;
        /*grid-template-columns: 145px 145px 145px;*/
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>
