<template>
    <div class="preview-controls">
        <PreviewControl class="preview-control" @previewChanged="previewChanged"/>
        <LastSaved class="last-saved-control"/>
        <DeviceControl class="device-control"/>
    </div>
</template>

<script>
import PreviewControl from "@/components/form-builder/preview/controls/PreviewControl.vue";
import LastSaved from "@/components/form-builder/preview/controls/LastSaved.vue";
import DeviceControl from "@/components/form-builder/preview/controls/DeviceControl.vue";

export default {
    name: 'PreviewControls',
    components: {
        PreviewControl,
        LastSaved,
        DeviceControl
    },
    methods: {
        previewChanged(preview) {
            alert('Preview changed: ' + preview)
        }
    }
}
</script>

<style lang="scss" scoped>
.preview-controls {
    display: flex;
    margin-top: 60px;
    margin-bottom: 20px;
    gap: 25px;

    .preview-control {
        flex-grow: 1;
    }

    & > * {
        display: flex;
        align-items: center;
    }
}
</style>
