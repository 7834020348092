<template>
    <div class="font-family-property">
        <label for="font">{{ $t('form_builder.font') }}</label>
        <select name="font" v-model="fontValue" class="input-medium">
            <option value="inherit">{{ $t('form_builder.font_inherit_value') }}</option>
            <option v-for="(font, key) in fonts" :value="font" :key="key" :style="{'font-family': font}">{{ key }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'FontFamilyProperty',
    props: ["property", "value"],
    data() {
        return {
            fontValue: this.value,
            fonts: {
                "Arial": "arial, helvetica, sans-serif",
                "Comic Sans MS": "'comic sans ms', sans-serif",
                "Courier New": "'courier new', courier, monospace",
                "Georgia": "georgia, palatino, serif",
                "Impact": "impact, sans-serif",
                "Tahoma": "tahoma, arial, helvetica, sans-serif"
            }
        }
    },
    watch: {
        fontValue(newValue) {
            this.$emit('updateStyle', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
