<template>
    <div class="html-property">
        <editor v-model="contentValue" api-key="3evmzdkaq6nra0qwth91djg8ei4bwoiqt3s0mxxfb2zvq7rf" :init="tinyMceSettings" />
        <!--
        <textarea v-model="contentValue" class="rich-text-editor"></textarea>-->
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    name: 'HtmlProperty',
    props: ["property", "value"],
    components: {
        'editor': Editor
    },
    data() {
        return {
            contentValue: this.value,
            tinyMceSettings: {
                height: 450,
                menubar: false,
                plugins: 'directionality lists code link table fontfamily',
                toolbar:
                    'undo redo | formatselect fontselect fontsizeselect | \
                    code | table | numlist bullist checklist | link | forecolor backcolor | bold italic underline strikethrough | \
                    alignleft aligncenter alignright alignjustify | ltr rtl | \
                    insertfile image media pageembed template  anchor codesample',
                toolbar_mode: 'wrap',
                directionality : 'rtl',
                font_formats: 'Arial=arial, helvetica, sans-serif; \
                Comic Sans MS=comic sans ms, sans-serif; \
                Courier New=courier new, courier, monospace; \
                Georgia=georgia, palatino, serif; \
                Impact=impact, sans-serif; \
                Tahoma=tahoma, arial, helvetica, sans-serif',
                fontsize_formats: '10px 11px 12px 14px 15px 16px 17px 18px 20px 22px 26px 32px 44px 56px'
            }
        }
    },
    watch: {
        contentValue(newValue) {
            this.$emit('updateContent', this.property, newValue);
        }
    }
};
</script>

<style>
</style>
