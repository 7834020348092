<template>
    <div class="register-component auth-component">
        <div class="auth-container">

            <div class="auth-container__logo">
                <figure><img :src="require('@/assets/images/site-logo.png')" /></figure>
            </div>

            <div class="auth-container__title">
                <h3>{{ $t("auth.register_title") }}</h3>
            </div>

            <div class="auth-container__content">

                <form v-on:submit="registerViaEmail">
                    <div class="form-group">
                        <label>{{ $t("auth.first_name") }}</label>
                        <input
                            type="text"
                            name="first_name"
                            v-model="firstName"
                            required="required"
                            class="form-control"
                        />
                    </div>

                    <div class="form-group">
                        <label>{{ $t("auth.last_name") }}</label>
                        <input
                            type="text"
                            name="last_name"
                            v-model="lastName"
                            required="required"
                            class="form-control"
                        />
                    </div>

                    <div class="form-group">
                        <label>{{ $t("auth.email_address") }}</label>
                        <input
                            type="email"
                            name="email"
                            v-model="email"
                            required="required"
                            class="form-control"
                        />
                    </div>

                    <div class="form-group">
                        <label>{{ $t("auth.password") }}</label>
                        <input
                            type="password"
                            name="password"
                            v-model="password"
                            required="required"
                            class="form-control"
                        />
                    </div>

                    <div class="form-group">
                        <label>{{ $t("auth.password_confirm") }}</label>
                        <input
                            type="password"
                            name="password_confirm"
                            v-model="passwordConfirm"
                            required="required"
                            class="form-control"
                        />
                    </div>

                    <div class="form-group">
                        <label><input type="checkbox" name="agreement" required="required" />
                            {{ $t("auth.register_agreement_confirmation_text") }}
                        </label>
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary form-control">
                            {{ $t("auth.register_submit_text") }}
                        </button>
                    </div>

                </form>

                <div class="auth-bottom-links">
                    <router-link to="/auth/login" class="auth-navigation-link">{{
                    $t("auth.login_link_from_register")
                    }}</router-link>
                </div>
            </div>


            <!--
            <div class="social-login-container">
                <a href="javascript:;" v-on:click="loginViaFacebook">{{ $t('auth.login_via_facebook') }}</a>
            </div>
            -->
        </div>

        <OverlayAlert class="files-modal" v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="closeModal">
        </OverlayAlert>

    </div>
</template>

<script>
const authEndpoint = process.env.VUE_APP_API_BASE + '/auth';
import OverlayAlert from '@/components/global/OverlayAlert';

export default {
    name: 'AuthRegister',
    components: {
        OverlayAlert
    },
    data: function() {
        return {
            modalOpen: false,
            alertMsg:'',
            email: '',
            password: '',
            passwordConfirm: '',
            firstName: '',
            lastName: ''
        }
    },
    computed: {
        formData() {
            return {
                'email': this.email,
                'password': this.password,
                'first_name': this.firstName,
                'last_name': this.lastName
            }
        }
    },
    methods: {
        registerViaEmail(e) {
            e.preventDefault()
            if (this.password != this.passwordConfirm) {
                //@TODO
                //HANDLE
                alert(this.$t('auth.passwords_do_not_match'))
                return
            }
            this.axios.post(authEndpoint + '/register', this.formData)
                .then(response => {
                    console.log(response.data)
                    if (response.data['result'] == 'success') {
                        //@TODO
                        alert(this.$t('auth.registration_success_text'))
                        this.$router.push('/auth/login')
                    }
                    else if (response.data['result'] == 'error') {
                        //@TODO
                        this.alertMsg = this.$t('auth.' + response.data['message']);
                        this.modalOpen = true;
                    }
                }).catch(error => {
                    console.log(error)
                })
        },
        closeModal(){
            this.modalOpen = false;
        }
    }
}
</script>

<style lang="scss">
</style>
