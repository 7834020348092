<template>
    <div class="personal-information">
        <!--h2 class="tab-heading">{{ $t('profile.personal_information_title') }}</h2-->
        <form v-on:submit="updateInformation">
            <div class="row">
                <div class="col-6">
                    <div class="form-group mb-4">
                        <label>{{ $t('profile.first_name') }}</label>
                        <input type="text" name="first_name" class="form-control"
                                required="required" v-model="firstName" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-4">
                        <label>{{ $t('profile.last_name') }}</label>
                        <input type="text" name="last_name" class="form-control"
                                required="required" v-model="lastName" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="submit" class="btn btn-primary">{{ $t('profile.personal_information_update_button') }}</button>
                </div>
            </div>
        </form>

        <OverlayAlert class="files-modal" v-if="modalOpen"
                :content="alertMsg"
                @alertClosed="modalOpen = false">
        </OverlayAlert>
    </div>
</template>

<script>
import OverlayAlert from '@/components/global/OverlayAlert.vue'

const profileEndpoint = process.env.VUE_APP_API_BASE + '/profile';

import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'PersonalInformation',
    props: ['user'],
    emits: ['userInfoUpdated'],
    components: {
        OverlayAlert
    },
    data() {
        return {
            firstName: this.user.first_name,
            lastName: this.user.last_name,
            modalOpen: false,
            alertMsg: ''
        }
    },
    computed: {
        formData() {
            return {
                'first_name': this.firstName,
                'last_name': this.lastName
            }
        },
        ...mapStores(useAuthStore)
    },
    methods: {
        updateInformation: function(e) {
            e.preventDefault()
            this.axios.post(profileEndpoint + '/personal-information', this.formData)
                .then(response => {
                    if (response.data['result'] == 'success') {
                        this.alertMsg = this.$t('profile.personal_information_update_success')
                        this.modalOpen = true
                        //alert(this.$t('profile.personal_information_update_success'))
                        this.$emit('userInfoUpdated', this.formData)
                    }
                    else if (response.data['result'] == 'error') {
                        alert(response.data['message'])
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.form-control {
    max-width: 400px;
}
</style>
